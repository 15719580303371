import PageMainContainer from 'components/page-main-container/PageMainContainer';
import SignUpForm from 'components/AuthForms/SignUpForm';
import blockmateSignupImage from 'assets/images/NFT/blockmate-signup-image.jpg';
import appRoutes from 'routes/routes';
import FormHeader from 'components/form/form-header/FormHeader';

function SignUpPage() {

  const { auth } = appRoutes;

  return (
    <PageMainContainer nftBgImg={blockmateSignupImage}>
      <div className='content'>
        <div className='form-wrapper sign-up'>
          <FormHeader
            title='Create a free account'
            titleText='All form fields are required. Already have an account?'
            linkContent='Log in →'
            linkPath={auth.login}
          />
          <SignUpForm/>
        </div>
      </div>
    </PageMainContainer>
  );
}

export default SignUpPage;

import { ReactComponent as PlusIcon } from 'assets/images/svg/plus-icon.svg';

const Card = ({
  projectName,
  usersCount,
  onClick,
  handleRedirect,
  imageSrc,
  demo,
}) => {
  return (
    <div className='my-projects-card' onClick={handleRedirect}>
      {projectName ? (
        <div className='card-content'>
          <div className='card-image-wrapper'>
            {demo && <div className='sample-data-label-container'><span className='sample-data-text'>sample data</span></div>}
            <img
              src={imageSrc}
              alt='card background'
              className='card-image'/>
          </div>
          <div className='card-info'>
            <p className='card-description'>{projectName}</p>
            <p className='card-users'>{usersCount}</p>
          </div>
        </div>
      ) : (
        <div className='card-content'>
          <div className='add-card-container' onClick={onClick}>
            <PlusIcon/>
            <span className='add-card-text'>
                New project
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;

import { ReactComponent as EarthIcon } from 'assets/images/svg/earth-icon.svg';
import { ReactComponent as NftsIcon } from 'assets/images/svg/widgets/nfts.svg';
import { ReactComponent as GamingIcon } from 'assets/images/svg/widgets/gaming.svg';
import { ReactComponent as GamblingIcon } from 'assets/images/svg/widgets/gambling.svg';
import { ReactComponent as KeyIcon } from 'assets/images/svg/key.svg';
import { ReactComponent as Users } from '../assets/images/svg/users.svg';
import { ReactComponent as Analytics } from '../assets/images/svg/analytics.svg';
import { ReactComponent as Bolt } from '../assets/images/svg/boltEmptyIcon.svg';
import { ReactComponent as Plus } from '../assets/images/svg/plus-icon.svg';
import { ReactComponent as View } from '../assets/images/svg/widgets/view.svg';

export const WIDGETS_ENUM = {
  TOTAL_USERS: 'total_users',
  ACTIVE_USERS: 'active_users',
  TRANSACTION_COUNT: 'transaction_count',
  USER_GROWTH: 'user_growth',
  TOP_PROVIDERS: 'top_providers',
  USER_ACTIVITY: 'user_activity',
  RISK_SCORE: 'risk_score',
  TOP_LOCATIONS: 'top_user_locations',
  TOP_TOKENS: 'top_tokens',
  COUNT_NFT: 'nft_transaction_count',
  COUNT_GAMING: 'gaming_transaction_count',
  COUNT_GAMBLING: 'gambling_transaction_count',
  TOP_USERS: 'top_users',
  RISK_SCORE_GAUSS: 'risk_score_gauss',
};

export const PERIOD_LABELS = {
  '7d': '7 days',
  '30d': '30 days',
  '90d': '90 days',
};

export const widgetsConfig = [
  {
    name: WIDGETS_ENUM.TOTAL_USERS,
    label: 'All of your clients',
    icon: <Users/>,
    tooltip: 'All connected clients'
  },
  {
    name: WIDGETS_ENUM.ACTIVE_USERS,
    label: 'Transacting clients',
    icon: <Analytics/>,
    tooltip: 'Activity based on transactions'
  },
  {
    name: WIDGETS_ENUM.TRANSACTION_COUNT,
    label: 'Your client\'s transaction',
    icon: <Bolt/>,
    tooltip: 'Number of all transactions'
  },
  {
    name: WIDGETS_ENUM.USER_GROWTH,
    label: 'Client Growth',
    icon: <Plus/>,
    tooltip: 'New connected clients'
  },
  {
    name: WIDGETS_ENUM.COUNT_NFT,
    label: 'NFT transactions made by your clients',
    icon: <NftsIcon/>,
    tooltip: 'NFT',
  },
  {
    name: WIDGETS_ENUM.COUNT_GAMING,
    label: 'Web3 Gaming interactions made by your clients',
    icon: <GamingIcon/>,
    tooltip: 'Gaming',
  },
  {
    name: WIDGETS_ENUM.COUNT_GAMBLING,
    label: 'Web3 Gambling interactions made by your clients',
    icon: <GamblingIcon/>,
    tooltip: 'Gambling',
  },
  {
    name: WIDGETS_ENUM.TOP_PROVIDERS,
    label: 'The most used chains in your project',
    icon: <Users/>,
    tooltip: 'Top 10 clients’ providers',
  },
  {
    name: WIDGETS_ENUM.TOP_LOCATIONS,
    label: 'Top locations',
    icon: <EarthIcon/>,
    tooltip: 'Clients’ IP address locations',
  },
  {
    name: WIDGETS_ENUM.USER_ACTIVITY,
    label: 'Client activity',
    icon: <Users/>,
    tooltip: 'Clients transactions',
  },
  {
    name: WIDGETS_ENUM.RISK_SCORE_GAUSS,
    label: 'Users with associated risk',
    icon: <View/>,
    tooltip: 'Risk score',
  },
  {
    name: WIDGETS_ENUM.TOP_USERS,
    label: 'The wealthiest users',
    icon: <Users/>,
    tooltip: 'Top users',
  },
  {
    name: WIDGETS_ENUM.RISK_SCORE,
    label: 'Average risk score of your users',
    icon: <View/>,
    tooltip: 'Users’ average risk score',
  },
  {
    name: WIDGETS_ENUM.TOP_TOKENS,
    label: 'The most active tokens of your users',
    icon: <KeyIcon/>,
    tooltip: 'Top tokens',
  },
];

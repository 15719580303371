import styles from './style.module.scss';
import AssetsBreakdownTableRow from './AssetsBreakdownTableRow';
import { useMemo } from 'react';


const AssetsBreakdownTable = ({ assets, currency = 'USD' }) => {
  const convertedTotal = useMemo(() => assets.reduce((acc, asset) => {
    return acc + (asset?.converted_value ?? 0);
  }, 0), [assets]);

  const sortedAssets = useMemo(() => {
    return [...assets].sort((a, b) => (b?.converted_value ?? 0) - (a?.converted_value ?? 0));
  }, [assets]);

  return <div className={styles.assetsBreakdown}>
    <span className={styles.assetsBreakdownTableHeader}>
      <span>Asset name</span>
      <span>Asset tag</span>
      <span>Balance</span>
      <span>Sum</span>
      <span>% Share</span>
    </span>
    {sortedAssets.map((asset, index) => <div className={styles.assetsBreakdownRowAndDividerContainer} key={`${asset?.currency}-${index}`}>
      <AssetsBreakdownTableRow
        assetInfo={asset}
        totalConvertedSum={convertedTotal}
        currency={currency}
      />
      {index < sortedAssets.length - 1 && <div className={styles.rowDivider}></div>}
    </div>)}
  </div>;
};

export default AssetsBreakdownTable;

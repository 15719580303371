import Header from 'components/header/Header';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as StopwatchIcon } from 'assets/images/svg/stopwatch.svg';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InfoIcon } from 'assets/images/svg/info-icon.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/images/svg/analytics.svg';
import {
  getBillingChartParsedData,
} from 'components/charts/ChartHelper';
import { ComposedChart, Bar, XAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { useMemo, useState, useEffect, useRef } from 'react';
import { ReactComponent as Dash } from 'assets/images/svg/dash.svg';
import { getParsedPlanData } from '../../utils/billing/plan-loader';
import PlanManagementBox from '../../components/billing/PlanManagementBox';
import appRoutes from '../../routes/routes';
import { loadAccountUsageData } from '../../utils/billing/account-usage-loader';
import { TIME_RANGE } from '../../utils/billing/utils';
import { formatNumber } from '../../utils/number-formatter';
import { clsx } from 'clsx';
import { toast } from 'react-hot-toast';
import PlanLimitReachedBox from 'components/billing/PlanLimitReachedBox';
import { useContext } from 'react';
import { UserContext } from 'context/user/UserContext';

const CHART_CONSTANTS = {
  composedChartMargin: 20,
  responsiveContainerHeight: 260,
  xAxisTickWidth: 10,
  barSize: 12,
  defaultBarFill: 'rgba(43, 92, 231, 0.2)'
};

const Billing = () => {
  const navigate = useNavigate();
  const [tooltip, setTooltip] = useState(null);
  const [settingsOpen, setSettingOpen] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [currentTimeRange, setCurrentTimeRange] = useState(TIME_RANGE.current);
  const [focusBar, setFocusBar] = useState(null);
  const [mouseLeave, setMouseLeave] = useState(true);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [billingData, setBillingData] = useState({});
  const [users, setUsers] = useState(null);
  const [projects, setProjects] = useState(null);
  const [apiCredits, setApiCredits] = useState(null);
  const limit = 500000;  // TODO take from BE once available
  const parsed = useMemo(() => getBillingChartParsedData(currentData, limit), [currentData]);
  const { isLimitReached } = useContext(UserContext);

  const billingManageNavigator = () => {
    navigate(appRoutes.home.billingManagePlan);
  };

  const ref = useRef(null);

  useEffect(() => {
    (async () => {
      const planData = await getParsedPlanData();
      const current = planData.find((plan) => plan.type === 'current');
      setCurrentPlan(current);
    })().catch(console.error);
  }, []);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSettingOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const loadBillingData = async () => {
      const data = await loadAccountUsageData();
      setBillingData(data?.data);
      setCurrentData(data?.data?.current ?? []);
      setUsers(data?.usersCount);
      setProjects(data?.projects);
      setApiCredits(data?.totalCredits);
    };
    try {
      loadBillingData();
    } catch (e) {
      toast.error('Failed to load billing data. Please try again later.');
      console.error(e);
    }
  }, []);

  const handlePeriod = async (e) => {
    const time = e.target.dataset.period;
    setCurrentData(billingData[time] ?? []);
    setCurrentTimeRange(TIME_RANGE[time]);
  };

  const handleMove = (state) => {
    if (state.isTooltipActive) {
      setFocusBar(state.activeTooltipIndex);
      setMouseLeave(false);
    } else {
      setFocusBar(null);
      setMouseLeave(true);
    }
  };

  const CustomToolTip = ({ active, payload }) => {
    const index = (tooltip === 'value') || (tooltip === 'limit' && payload.length === 1) ? 0 : 1;
    if (active && payload && payload[index] != null && index !== null) {
      return (
        <div className='custom-tooltip'>
          <p className='title'>{payload[index].payload[payload[index].name]}</p>
          <p className='subtitle'>{tooltip === 'value' ? 'API CREDITS' : 'CREDITS LIMIT'}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className='dashboard-page billing'>
      <Header headerTitle='Billing'/>
      {isLimitReached && <PlanLimitReachedBox handleUpgradeButton={billingManageNavigator}/>}
      <div className='management-boxes-container'>
        <div className='billing-management-box-container'>
          <PlanManagementBox currentPlan={currentPlan} />
        </div>
        {/* TODO uncomment this once add-on endpoints are ready */}
        {/*<div className='billing-management-box-container'>*/}
        {/*  <AddOnManagementBox currentAddOn={null} />*/}
        {/*</div>*/}
      </div>

      <div className='billing-banner-container'>
        <p className='billing-banner-text'>Upgrade your plan and get higher limits!</p>
        <div className='banner-button'>
          <MainButton
            variant='button-green'
            label={<div>get most popular plan</div>}
            icon={<StopwatchIcon/>}
            extraClasses='banner-button'
            onClick={billingManageNavigator}
          />
        </div>
        <div className='banner-link' onClick={billingManageNavigator}>
          Upgrade now →
        </div>
      </div>

      <div className='shadow-box api-usage'>
        <div  className='api-usage-header'>
          <p className='api-usage-title'>account usage</p>
          <div className='api-usage-options'>
            <div ref={ref} onClick={() => setSettingOpen(!settingsOpen)} className='settings-icon'>
              <div className={clsx('api-usage-range', settingsOpen && 'active')}>
                <p className='api-usage-text'>{currentTimeRange}</p>
                <Dash className='menu-dash'/>
              </div>
              {settingsOpen && (
                <div className='settings-modal'>
                  <div className='settings-options'>
                    <>
                      <div className='setting-option' data-period='current' onClick={handlePeriod}>
                        Current month
                      </div>
                      <div className='setting-option' data-period='last' onClick={handlePeriod}>
                        Last month
                      </div>
                      <div className='setting-option' data-period='long' onClick={handlePeriod}>
                        Last 90 days
                      </div>
                    </>
                  </div>
                </div>
              )}
            </div>
            <span className='tooltip' data-tooltip={'Account usage across all projects'}>
              <div className='info-icon'>
                <InfoIcon />
              </div>
            </span>
          </div>
        </div>
        <div className='api-usage-chart horizontal-bar-chart-wrapper'>
          <ResponsiveContainer width='100%' height={CHART_CONSTANTS.responsiveContainerHeight}>
            <ComposedChart
              data={parsed}
              margin={{
                top: CHART_CONSTANTS.composedChartMargin,
                right: CHART_CONSTANTS.composedChartMargin,
                bottom: CHART_CONSTANTS.composedChartMargin,
                left: CHART_CONSTANTS.composedChartMargin
              }}
              onMouseMove={handleMove}
            >
              <XAxis dataKey='date' tick={{ width: CHART_CONSTANTS.xAxisTickWidth }}/>
              <Bar
                fill='#fff'
                dataKey='value'
                barSize={CHART_CONSTANTS.barSize}
                onMouseOver={ () => setTooltip('value')}
              >
                {parsed.map((entry, index) => (
                  <Cell
                    key={entry.date}
                    fill={
                      ((focusBar === index && tooltip === 'value') || mouseLeave)
                        ? `${entry.fill}`
                        : CHART_CONSTANTS.defaultBarFill
                    }
                  />
                ))}
              </Bar>
              {/* TODO uncomment once limit data are available on BE */}
              {/*<Line*/}
              {/*  type='monotone'*/}
              {/*  dataKey='limit'*/}
              {/*  stroke='#EA0E5D'*/}
              {/*  dot={false}*/}
              {/*  activeDot={{*/}
              {/*    strokeWidth: tooltip === 'limit' ? 7 : 0,*/}
              {/*    className: clsx(tooltip === 'limit' && 'line-chart-active-dot')*/}
              {/*    fill: tooltip === 'limit' ? '#EA0E5D' : 'transparent',*/}
              {/*    r: tooltip === 'limit' ? 10 : 5*/}
              {/*  }}*/}
              {/*  strokeWidth={2}*/}
              {/*  onMouseOver={ () => setTooltip('limit') }*/}
              {/*/>*/}
              <Tooltip content={<CustomToolTip/>} cursor={false}/>
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className='divider'/>
        <div className='api-usage-footer'>
          <div className='management-box-table'>
            <div className='management-box-element'>
              <p className='management-box-element-label'>projects</p>
              <p className='management-box-element-value'>{projects === null ? '?' : projects}</p>
            </div>
            <div className='management-box-element'>
              <p className='management-box-element-label'>users</p>
              <p className='management-box-element-value'>{users === null ? '?' : users}</p>
            </div>
            <div className='management-box-element danger'>
              <p className='management-box-element-label'>api credits</p>
              <p className='management-box-element-value'>{apiCredits === null ? '?' : formatNumber(apiCredits)}</p>
            </div>
          </div>
          <div className='billing-button-wrapper'>
            <MainButton
              variant='button-purple-light'
              label='VIEW API USAGE'
              icon={<AnalyticsIcon/>}
              onClick={() => navigate('/billing/api-usage')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;

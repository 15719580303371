import axios from 'axios';
import { getStaticPath } from 'services/api/axios';
import qs from 'qs';

const login = async (email, password, slug) => {
  const paramsWithSlug = {
    grant_type: 'password',
    username: email,
    password
  };
  if (slug) {
    paramsWithSlug.slug = slug;
  }
  const response = await axios.post(
    getStaticPath('CLIENT_URL', 'login'),
    qs.stringify(paramsWithSlug),
  );
  return response?.data?.token_value;
};

const logout = () => {
  return axios.post(getStaticPath('CLIENT_URL', 'logout'));
};

const requestPasswordReset = async (email, slug) => {
  const paramsWithSlug = { email };
  if (slug) {
    paramsWithSlug.slug = slug;
  }
  return axios.get(getStaticPath('CLIENT_URL', 'request_pw_reset'), {
    params: paramsWithSlug
  });
};

const resendEmail = async (email, slug) => {
  const paramsWithSlug = { email };
  if (slug) {
    paramsWithSlug.slug = slug;
  }
  return await axios.post(
    getStaticPath('CLIENT_URL', 'resend_email'),
    paramsWithSlug
  );
};

// ============ Methods that do not need to handle email conflicts ============

const signUp = async (email, password, companyName, phoneNumber) => {
  const response = await axios.post(
    getStaticPath('CLIENT_URL'),
    {
      email,
      password,
      company_name: companyName,
      phone_number: phoneNumber
    }
  );
  return response.data;
};

const loginGoogle = async (user) => {
  const { data } = await axios.post(
    getStaticPath('CLIENT_URL', 'signin-google'), { google_token_id: user }
  );
  return data;
};

const loginGithub = async () => {
  const response = await axios.post(
    getStaticPath('CLIENT_URL', 'signin-github'), { redirect_url: 'https://portal-dev.blockmate.io/' }
  );
  return response.data;
};

const confirmUserEmail = async (token) => {
  const response = await axios.get(getStaticPath('CLIENT_URL', 'confirm_email'), {
    params: { token: token },
  });
  return response.data;
};

const resetPassword = async (token, password) => {
  const response = await axios.post(getStaticPath('CLIENT_URL', 'reset_pw'), {
    token: token,
    password: password,
  });
  return response.data;
};

const getProfile = () => {
  return axios.get('/api/v1/client');
};

const authService = {
  signUp,
  login,
  logout,
  requestPasswordReset,
  resetPassword,
  confirmUserEmail,
  loginGoogle,
  resendEmail,
  loginGithub,
  getProfile
};

export default authService;

import { ReactComponent as RiskSafe } from 'assets/images/svg/widgets/risk-score/risk-safe.svg';
import { ReactComponent as RiskMedium } from 'assets/images/svg/widgets/risk-score/risk-medium.svg';
import { ReactComponent as RiskHigh } from 'assets/images/svg/widgets/risk-score/risk-high.svg';

const RiskScoreCircleChart = ({ riskScore }) => {

  const chartAngle = (riskScore) * 2.7;

  const pointerStyle = {
    transform: `translate(-50%, -100%) rotate(${-135 + chartAngle}deg)`
  };

  return (
    <div className='risk-score-chart-border-wrapper'>
      <div className='risk-score-circle-chart'>
        <div className='risk-score-pointer' style={pointerStyle}></div>
        <div className='center'>
          {riskScore < 55 && <RiskSafe/>}
          {riskScore >= 55 && riskScore < 85 && <RiskMedium/>}
          {riskScore >= 85 && <RiskHigh/>}
        </div>
        <div className='risk-score-scale-container'>
          <span>0</span>
          <span>100</span>
        </div>
      </div>
    </div>

  );
};

export default RiskScoreCircleChart;

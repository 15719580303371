import { useState, createContext } from 'react';

export const ApiContext = createContext();

const ApiContextProvider = ({ children }) => {
  const [apiToken, setApiToken] = useState(null);

  const data = {
    apiToken,
    setApiToken,
  };

  return <ApiContext.Provider value={data}>{children}</ApiContext.Provider>;
};

export default ApiContextProvider;

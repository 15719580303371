import { useContext } from 'react';
import ChangePeriodSelect from 'components/widget/ChangePeriodSelect';
import { WidgetContext } from 'context/widget/WidgetContext';

const AnalyticsBox = ({ children, description, withoutPeriod, percent, name, }) => {
  const formatNumber = (number) => new Intl.NumberFormat('en-US').format(number);
  const { widgets } = useContext(WidgetContext);
  const widget = widgets.find(widget => widget.name === name);

  return (
    <div className='analytics-box'>
      <div className='box-header'>
        <div className='icon-wrapper'>
          {children}
        </div>
      </div>
      <div className='box-number'>{percent ? `${widget?.data.toFixed(2)}%` : formatNumber(widget?.data)}</div>
      <div className='box-footer'>
        <div className='box-description'>{description}</div>
        <ChangePeriodSelect
          name={name} withoutPeriod={withoutPeriod}
        />
      </div>
    </div>
  );
};

export default AnalyticsBox;

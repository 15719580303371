import { useLocation } from 'react-router-dom';
import { clsx } from 'clsx';

const UserButton = ({
  firstIcon,
  secondIcon,
  children,
  onClick
}) => {
  const { pathname } = useLocation();

  return (
    <button className={clsx('menu-user-button', pathname === '/my-account' && 'active')}
      onClick={onClick}>
      <div className='first-icon'>
        {firstIcon}
      </div>
      <span className='user-button-description'>{children}</span>
      <div className='second-icon'>
        {secondIcon}
      </div>
    </button>
  );
};

export default UserButton;

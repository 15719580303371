import axios from 'axios';
import getExchangeRates from '../../../api/exchange-rates';

export const getTronConnectedAccount = async (address, userJwt, apiUrl, description, currency = 'usd') => {
  const projectCurrencyPair = `usd/${currency}`.toLowerCase();
  const exchangeRates = await getExchangeRates([projectCurrencyPair], apiUrl, userJwt);
  const accountBaseForConnectedAccounts = {
    account_id: description,
    description,
    wallet: address,
    type: 'crypto_wallet',
    balance: [],
    name: 'trx',
    url: 'onchain/trx'
  };
  try {
    const response = await axios.get(
      '/v1/quick_risk',
      {
        baseURL: apiUrl,
        headers: {
          'Authorization': `Bearer ${userJwt}`,
        },
        params: {
          chain: 'trx',
          address: address,
          metrics: 'balance',
        },
      }
    );
    const balanceFromQuickRisk = response?.data?.balance ?? [];
    const transformedBalance = balanceFromQuickRisk.map(balanceEntry => ({
      currency: `TRON:${balanceEntry?.contract_ticker_symbol}`,
      currency_name: balanceEntry?.contract_name,
      currency_symbol: balanceEntry.contract_ticker_symbol,
      value: balanceEntry.balance / Math.pow(10, balanceEntry.contract_decimals ?? 0),
      converted_currency: currency,
      converted_value: (balanceEntry?.quote ?? 0) * (exchangeRates[projectCurrencyPair] ?? 1),
    }));
    return {
      ...accountBaseForConnectedAccounts,
      balance: transformedBalance
    };
  } catch (error) {
    return accountBaseForConnectedAccounts;
  }
};

import AppRoutes from 'routes/AppRoutes';
import './styles/index.scss';
import { Toaster } from 'react-hot-toast';
import { useContext } from 'react';
import { UserContext } from './context/user/UserContext';
import Loader from './components/loaders/loader/Loader';
import Notification from 'components/notification/Notification';

function App() {
  const { loading } = useContext(UserContext);

  if (loading) return <Loader/>;

  return (
    <>
      <Toaster toastOptions={{ duration: 4000 }}/>
      <Notification/>
      <AppRoutes/>
    </>
  );
}

export default App;

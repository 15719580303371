import PageHeader from 'components/page-header/PageHeader';
import Footer from 'components/footer/Footer';
import NFTCollection from 'components/NFT-collection/NFTCollection';

function PageMainContainer({ children, nftBgImg }) {
  return (
    <>
      <div className='main-container'>
        <PageHeader />
        {children}
        <Footer />
      </div>
      <NFTCollection bgImg={nftBgImg} />
    </>
  );
}

export default PageMainContainer;

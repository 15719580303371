import { BarLoader } from 'react-spinners';
import { clsx } from 'clsx';

function Loader({ relative = false }) {
  return (
    <div className={clsx('loader', relative && 'relative')}>
      <BarLoader color='#7346f3' />
    </div>
  );
}

export default Loader;

import styles from './style.module.scss';
import { ReactComponent as InfoIcon } from 'assets/images/svg/info-icon.svg';
import CopyToClipboardTooltip from '../../components/copy-to-clipboard-tooltip/CopyToClipboardTooltip';

const UsersManagementTableSubheader = () => {

  const subheaderItems = [
    { label: 'Name', tooltipData: 'User name' },
    { label: 'Email', tooltipData: 'User email' },
    { label: 'Role', tooltipData: 'User role' },
    { label: 'Options', tooltipData: 'Available options' },
  ];

  return (
    <div className={styles.tableSubheader}>
      {subheaderItems.map((item) => (
        <span className={styles.subheaderItem} key={item.label}>
          {item.label}
          {item.tooltipData && (
            <CopyToClipboardTooltip tooltipData={item.tooltipData} openOnHover>
              <InfoIcon className={styles.subheaderIcon} />
            </CopyToClipboardTooltip>
          )}
        </span>
      ))}
      <span></span>
    </div>
  );
};
export default UsersManagementTableSubheader;

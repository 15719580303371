import { useContext, useEffect } from 'react';
import { WidgetContext } from 'context/widget/WidgetContext';

const WidgetBanner = () => {
  const {
    widgetsBannerData,
    getWidgetsBanner
  } = useContext(WidgetContext);

  useEffect(() => {
    getWidgetsBanner();
  }, []);

  return (
    <>
      {!!widgetsBannerData && (
        <div
          className='widget-banner-container'
          style={{
            background: `url(${widgetsBannerData.bannerImage?.url}`, backgroundSize: 'cover'
          }}>
          <div className='banner-tag world-tag'>
            <img src={widgetsBannerData.iconWorld?.url} className='banner-time-icon' alt={'banner-time-icon'}/>
            <p>{widgetsBannerData.widgetsBannerLabelText}</p>
          </div>
          <p className='widget-banner-add-on'>{widgetsBannerData.widgetsBannerHeadlineLabel}</p>
          <p className='widget-banner-main-text'>
            {widgetsBannerData.widgetsBannerHeadline}
          </p>
          <a href={widgetsBannerData.widgetsBannerLinkUrl} target='_blank' rel='noreferrer' className='blog-link'>
            {widgetsBannerData.widgetsBannerLinkText}
          </a>
        </div>
      )}
    </>
  );
};

export default WidgetBanner;

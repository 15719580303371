import axios from 'axios';
import { getStaticPath } from 'services/api/axios';
import qs from 'qs';

const createToken = async (project_id, tokenName) => {
  const response = await axios.post(
    getStaticPath('PROJECT_URL', `${project_id}/token?${qs.stringify({ name: tokenName })}`)
  );
  return response.data;
};

const getToken = async (project_id, token_id) => {
  const response = await axios.get(
    getStaticPath('PROJECT_URL', `${project_id}/token/${token_id}`)
  );
  return response.data;
};

const listTokens = async (project_id) => {
  const response = await axios.get(getStaticPath('PROJECT_URL', `${project_id}/token`));
  return response.data;
};

const updateToken = async (project_id, token_id, tokenName) => {
  const response = await axios.patch(
    getStaticPath('PROJECT_URL', `${project_id}/token/${token_id}?${qs.stringify({ name: tokenName })}`)
  );
  return response.data;
};

const deleteToken = async (project_id, token_id) => {
  const response = await axios.delete(
    getStaticPath('PROJECT_URL', `${project_id}/token/${token_id}`)
  );
  return response.data;
};

const createPublicToken = async (project_id, tokenName) => {
  const response = await axios.post(getStaticPath('PROJECT_URL', `${project_id}/public/token?${qs.stringify({ name: tokenName })}`));
  return response.data;
};

const getPublicTokens = async (project_id) => {
  const response = await axios.get(getStaticPath('PROJECT_URL', `${project_id}/public/token`));
  return response.data;
};

const deletePublicToken = async (project_id, token_id) => {
  const response = await axios.delete(getStaticPath('PROJECT_URL', `${project_id}/public/token/${token_id}`));
  return response.data;
};

const apiTokenService = {
  createToken,
  getToken,
  listTokens,
  updateToken,
  deleteToken,
  createPublicToken,
  getPublicTokens,
  deletePublicToken,
};

export default apiTokenService;

import { formatPlanPrice, getPlanImgSrc } from '../../utils/billing/plan-formatter';
import { ReactComponent as EditIcon } from 'assets/images/svg/edit.svg';
import { useNavigate } from 'react-router-dom';
import ManagementBox from './ManagementBox';

export const PlanManagementBox = ({ currentPlan = null }) => {
  const navigate = useNavigate();

  return (
    <div className='shadow-box management-box'>
      {currentPlan
        ? (
          <ManagementBox iconSrc={getPlanImgSrc()} items={[
            {
              label: 'plan',
              value: currentPlan.name
            },
            {
              label: 'price',
              value: formatPlanPrice(currentPlan)
            }
          ]} />
        )
        : (<div>
          <h3>You are not subscribed to any plan</h3>
        </div>)}
      <div className='billing-link-wrapper' onClick={() => navigate('/billing/manage-plan')}>
        <div className='edit-icon'>
          <EditIcon />
        </div>
        <span>Manage plan</span>
      </div>
    </div>
  );
};

export default PlanManagementBox;

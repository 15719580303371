import appRoutes from 'routes/routes';
import PermissionBox from './PermissionBox';

const AcceptPrivacyPolicy = ({ setCheckedCheckboxes }) => {
  const { external } = appRoutes;

  const handleCheckboxChange = (key) => {
    setCheckedCheckboxes(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <>
      <PermissionBox handleCheckbox={() => handleCheckboxChange('privacyPolicy')}>
        <span>By creating a free account, you agree to Blockmate&apos;s </span>
        <a
          href={external.termsAndCondition}
          target='_blank'
          rel='noopener noreferrer'
          className='accept-permissions-link'
        >
            Terms of service
        </a>
        <span> and consent to Blockmate&apos;s </span>
        <a
          href={external.privacyPolicy}
          target='_blank'
          rel='noopener noreferrer'
          className='accept-permissions-link'
        >
           Privacy policy
        </a>
      </PermissionBox>
      <PermissionBox handleCheckbox={() => handleCheckboxChange('marketing')} optional>
        <span>
          I agree to receive useful information and special offers from Blockmate. Opting out is available anytime
        </span>
      </PermissionBox>
    </>
  );
};

export default AcceptPrivacyPolicy;

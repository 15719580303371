import styles from './login-form.module.scss';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'context/user/UserContext';
import appRoutes from 'routes/routes';
import LoadingSpinner from 'components/loaders/loading-spinner/LoadingSpinner';
import MainButton from 'components/buttons/MainButton';
import useNotification from 'components/notification/UseNotification';
import { ReactComponent as BoltGreen } from 'assets/images/svg/boltGreenIcon.svg';
import handleApiError from '../../services/ErrorHandleUtil';
import Select from '../select/Select';
import { valueExists } from '../../utils/common';
import { toast } from 'react-hot-toast';

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { home } = appRoutes;
  const navigate = useNavigate();
  const { performLogin, updateProfile } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [slug, setSlug] = useState('');
  const [errors, setErrors] = useState({});
  const [currentSlugs, setCurrentSlugs] = useState([]);
  const showNotification = useNotification();

  const handleLoginSubmit = () => {
    const newErrors = {};
    if (!valueExists(email)) {
      newErrors.email = 'Please enter Email';
    }
    if (!valueExists(password)) {
      newErrors.password = 'Please enter Password';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setIsLoading(true);
    performLogin(email, password, slug).then(operationResult => {
      if (operationResult.success) {
        updateProfile().then(data => {
          if (data.authorized) {
            navigate(home.dashboard);
          }
        }).catch(reason => {
          handleApiError('getting client profile', reason);
        });
      } else if (operationResult.containsSlugs) {
        setCurrentSlugs(operationResult.response);
      }
    }).catch(error => {
      if (error?.response?.data?.detail) {
        toast.error(error.response.data.detail);
      } else {
        showNotification('login', 'error');
      }
    });
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner/>
      ) : (
        <div className='main-form'>
          <input
            type='email'
            name='email'
            onChange={e => { setCurrentSlugs([]); setSlug(''); setEmail(e.target.value); }}
            value={email}
            placeholder='Your e-mail'
            className='main-form-input'
          />
          {errors.email && (
            <p className='form-error-message'>{errors.email}</p>
          )}

          <input
            type='password'
            name='password'
            onChange={e => setPassword(e.target.value)}
            value={password}
            placeholder='Password'
            className='main-form-input'
          />
          {errors.password && (
            <p className='form-error-message'>{errors.password}</p>
          )}

          {currentSlugs.length > 0 &&
            <Select
              selectClassName={styles.noMargin}
              options={currentSlugs.map(slug => ({ label: slug }))}
              onOptionClick={option => setSlug(option.label)}
              value={slug}
            />
          }
          {currentSlugs.length > 0 && !valueExists(slug) && (
            <p className='form-error-message'>Please select a company</p>
          )}

          <MainButton
            onClick={handleLoginSubmit}
            variant='button-black'
            label='LOG IN'
            icon={<BoltGreen />}
          />
        </div>
      )}
    </>
  );
};

export default LoginForm;

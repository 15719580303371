import { useContext, useEffect, useState } from 'react';
import apiTokenService from 'services/api-tokens-services';
import { ApiContext } from 'context/api/ApiContext';
import { toast } from 'react-hot-toast';
import ApiKeysListTable from 'components/ApiKeysList/ApiKeysListTable';
import ApiKeyListSubheader from './ApiKeyListSubheader';
import { useParams } from 'react-router-dom';
import useNotification from 'components/notification/UseNotification';

const ApiKeys = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTokenList, setActiveTokenList] = useState(null);
  const [allTokenList, setAllTokenList] = useState();
  const {
    apiToken,
    setApiToken
  } = useContext(ApiContext);
  const {
    createToken,
    listTokens
  } = apiTokenService;
  const { id } = useParams();
  const showNotification = useNotification();

  const generateNewKeys = async () => {
    if (allTokenList.length <= 200) {
      setIsLoading(true);
      try {
        const data = await createToken(
          id,
          `Key ${allTokenList.length + 1}`,
        );
        setApiToken(data);

        setTimeout(() => {
          setApiToken(null);
        }, 180000);

        showNotification('createApiKey', 'alert');
      }
      catch (error) {
        toast.error(error.message ?? 'Something went wrong...');
      } finally {
        setIsLoading(false);
      }
    }
    else {
      toast.error('Something went wrong...');
    }
  };

  useEffect(() => {
    const getListOfAllTokens = async (id) => {
      try {
        const tokens = await listTokens(id);
        setAllTokenList(tokens);
        setActiveTokenList(tokens.filter((token) => !token.disabled));
      }
      catch (error) {
        toast.error('Something went wrong...');
      }
    };
    getListOfAllTokens(id);
  }, [apiToken]);

  return (
    <>
      <ApiKeyListSubheader handleCreate={generateNewKeys}/>
      <div className='api-keys-main-container'>
        <ApiKeysListTable loading={isLoading} setLoading={setIsLoading} list={activeTokenList}
          setList={setActiveTokenList} generateNewApiKey={generateNewKeys}/>
      </div>
    </>
  );
};

export default ApiKeys;

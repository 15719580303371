import styles from './style.module.scss';
import { numberToPrice } from '../../utils/billing/plan-formatter';
import CopyToClipboardTooltip from '../../components/copy-to-clipboard-tooltip/CopyToClipboardTooltip';
import { ellipsis } from '../../utils/common';


const AssetsBreakdownTableRow = ({ assetInfo, totalConvertedSum, currency = 'USD' }) => {
  const percentage = totalConvertedSum === 0 ? 0 : ((assetInfo.converted_value ?? 0) / totalConvertedSum) * 100;

  const shortenedSymbol = ellipsis(assetInfo?.currency_symbol ?? '', 12);
  const shortenedName = ellipsis(assetInfo?.currency_name ?? '', 12);

  return <div className={styles.assetsBreakdownTableContent}>
    <div>
      <div className={styles.hug}>
        <div className={styles.iconWithText}>
          <div className={styles.iconContainer}>
            {assetInfo?.image_url && <img src={assetInfo.image_url} alt='' />}
          </div>
          <CopyToClipboardTooltip tooltipData={assetInfo.currency_name} openOnHover>
            {shortenedName}
          </CopyToClipboardTooltip>
        </div>
      </div>
    </div>
    <div>
      <div className={styles.hug}>
        <CopyToClipboardTooltip tooltipData={assetInfo.currency_symbol} openOnHover>
          {shortenedSymbol}
        </CopyToClipboardTooltip>
      </div>
    </div>
    <div>
      <div className={styles.hug}>
        <CopyToClipboardTooltip tooltipData={<div><div>{assetInfo.value}</div>.<div>{assetInfo.currency_symbol}</div></div>} openOnHover>
          <span className={styles.value}>{Number(assetInfo.value.toFixed(4))}</span> {shortenedSymbol}
        </CopyToClipboardTooltip>
      </div>
    </div>
    <span>{numberToPrice(assetInfo.converted_value ?? 0, currency, 2)}</span>
    <span>{Number(percentage.toFixed(2))}%</span>
  </div>;
};

export default AssetsBreakdownTableRow;

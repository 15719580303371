import { BarLoader } from 'react-spinners';

function LoadingSpinner() {
  return (
    <div className='loading-wrapper'>
      <BarLoader color='#7346f3' />
    </div>
  );
}

export default LoadingSpinner;

import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../../context/user/UserContext';
import { valueExists } from '../../utils/common';

const useDeposits = () => {
  const { getConfig } = useContext(UserContext);
  
  const getPayouts = async (projectId, jwt, since, until) => {
    const response = await axios.get(
      '/v1/exchange/deposit/merchant-payouts',
      {
        baseURL: getConfig('api_url'),
        headers: {
          'Authorization': `Bearer ${jwt}`,
        },
        params: {
          since,
          until,
        },
      }
    );
    return response?.data;
  };

  const getDeposits = async (projectId, jwt, since, until, userId = undefined) => {
    const params = {};
    if (valueExists(since)) {
      params.since = since;
    }
    if (valueExists(until)) {
      params.until = until;
    }
    if (valueExists(userId)) {
      params['user_id'] = userId;
    }
    const response = await axios.get(
      '/v1/exchange/deposit/universal/list',
      {
        baseURL: getConfig('api_url'),
        headers: {
          'Authorization': `Bearer ${jwt}`,
        },
        params,
      }
    );
    return response?.data;
  };

  return {
    getPayouts,
    getDeposits,
  };
};

export default useDeposits;

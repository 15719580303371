import dayjs from 'dayjs';
import { valueExists } from './common';

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  return date.toLocaleString('en-US', options);
};

export const dateObjectToDateString = (date, includeTime = false) => {
  if (includeTime) {
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
  } else {
    return dayjs(date).format('YYYY-MM-DD');
  }
};

export const getDateStringsRange = (fromDate, toDate) => {
  const dateDifference = dayjs(toDate).diff(fromDate, 'day');
  const allWantedDates = [...Array(dateDifference)].map((_, index) =>
    dayjs(fromDate).add(index, 'day').format('YYYY-MM-DD')
  );
  return allWantedDates;
};

export const formatDateStrAsShortMonthNoYear = (dateStr) => {
  return dayjs(dateStr).format('MMM DD');
};

export const dateStrOrTimestampToTimestamp = (dateStrOrTimestamp, minTimestamp = 0) => {
  let convertedToTimestamp = dateStrOrTimestamp;
  if (typeof dateStrOrTimestamp !== 'number') {
    convertedToTimestamp = new Date(dateStrOrTimestamp ?? minTimestamp).getTime() / 1000;
  }
  return Math.max(convertedToTimestamp, minTimestamp);
};

export const dateStrToTimestampSeconds = (dateStr, endOfDay = false) => {
  if (!valueExists(dateStr)) {
    return undefined;
  }
  const hours = endOfDay ? 23 : 0;
  const minutes = endOfDay ? 59 : 0;
  const seconds = endOfDay ? 59 : 0;
  const dateNow = new Date(dateStr);
  dateNow.setUTCHours(hours);
  dateNow.setUTCHours(minutes);
  dateNow.setUTCHours(seconds);
  return Math.floor(dateNow.getTime() / 1000);
};

export const getCurrentDatetimeStringSimple = () => {
  const now = dayjs();
  return now.format('DD-MM-YYYY_HHmmss');
};

import { ReactComponent as Trash } from 'assets/images/svg/trash.svg';
import MainModal from 'components/modals/MainModal';
import { getStaticPath } from 'services/api/axios';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import appRoutes from 'routes/routes';
import MainButton from 'components/buttons/MainButton';
import useNotification from 'components/notification/UseNotification';

const DeleteProjectModal = ({ handleClose, projectName }) => {
  const [inputProjectName, setInputProjectName] = useState('');
  const { id } = useParams();
  const { home } = appRoutes;
  const navigate = useNavigate();
  const showNotification = useNotification();

  const handleDelete = async () => {
    if (inputProjectName.toUpperCase() ===  projectName.toUpperCase() )
    {
      try {
        await axios.delete (
          getStaticPath('PROJECT_URL', `${id}`)
        );
        showNotification('deleteProject', 'success');
        navigate(home.dashboard);
      }
      catch (error) {
        showNotification('deleteProject', 'error');
      }
    }
    else {
      showNotification('deleteProject', 'error');
    }
  };

  return (
    <MainModal handleClose={handleClose}>
      <div className='modal-content'>
        <div className='delete-project-modal-info-section'>
          <div className='trash-icon-container'>
            <Trash/>
          </div>
          <p className='modal-title'>
					Delete project
          </p>
          <p className='modal-description'>
					Before proceeding, please confirm that you want to delete this project. Are you sure you want to continue?
          </p>
        </div>
        <div className='delete-project-modal-actions-section'>
          <input placeholder='Write project’s name to confirm' onChange={(e) => setInputProjectName(e.target.value)} className='delete-modal-input'/>
          <MainButton
            variant='button-error'
            onClick={handleDelete}
            label='DELETE PROJECT'
            icon={<Trash />}
          />
        </div>
      </div>
    </MainModal>
  );
};

export default DeleteProjectModal;

import { useContext, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { UserContext } from '../../context/user/UserContext';
import {
  getMisttrackCategoriesByAddress,
  getRiskFromMisttrackCategories,
  misttrackCategoryToRisk
} from '../../utils/misttrack';
import { valueExists, unique } from '../../utils/common';
import { categoryToRiskScore, isLowRisk } from '../../utils/riskLevels';
import { filterTypes } from '../../components/filters/ConsolidatedTransactionsFilters';

const useLoadNextTxsPage = ({
  userJWT,
  useMisttrackData,
  pageSize = 50,
  setLoadingError,
}) => {
  const LAST_PAGE_CURSOR = '';
  const { getConfig, companyCurrency } = useContext(UserContext);

  const txsPageCursorRef = useRef(null);

  const loadNextPage = async (filters) => {
    return loadNextPageInternal(filters, txsPageCursorRef.current);
  };

  const loadNextPageInternal = async (filters, currentCursor) => {
    if (!hasNextPage() || !userJWT) {
      return [];
    }

    setLoadingError('');
    let response;

    const params = {
      page_size: pageSize,
      currency: companyCurrency ?? 'usd',
      cursor: currentCursor,
      risk2: 1,
    };

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
        txTypesFilterParam,
        txRisksFilterParam,
        minPriceFilterParam,
        maxPriceFilterParam
      } = filters;

      if (valueExists(accountsFilterParam)) {
        params['account-filter'] = accountsFilterParam;
      }
      if (valueExists(digitalAssetsFilterParam)) {
        params.currencies = digitalAssetsFilterParam;
      }
      if (valueExists(txTypesFilterParam)) {
        params.types = txTypesFilterParam;
      }
      if (valueExists(txRisksFilterParam)) {
        params.risks = txRisksFilterParam;
      }
      if (valueExists(minPriceFilterParam)) {
        params['min-price'] = minPriceFilterParam;
      }
      if (valueExists(maxPriceFilterParam)) {
        params['max-price'] = maxPriceFilterParam;
      }
    }

    try {
      response = await axios.get('/v1/aggregate/transactions',
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${userJWT}`,
          },
          params
        }
      );
    } catch (error) {
      handleLoadError(error);
      return [];
    }

    return processResponseData(response?.data);
  };

  const handleLoadError = (error) => {
    if (error?.response?.status === 504) {
      setLoadingError('Request is addressing too much data. Try reducing filters usage.');
      toast.error('Request is addressing too much data. Try reducing filters usage.');
    } else {
      setLoadingError('An error occurred. Please try again.');
      toast.error(`An error occurred: ${error?.message || 'Please try again.'}`);
    }
  };

  const processResponseData = (data) => {
    const { transactions, page_cursor, risk2: misttrackRiskInfo } = data || {};

    if (transactions && page_cursor !== null) {
      txsPageCursorRef.current = page_cursor;

      if (!useMisttrackData) {
        return transactions;
      }

      const mergedMisttrackRiskDetail = Object.values(misttrackRiskInfo ?? {}).reduce((acc, singleAccountInfo) => {
        const thisAccountRiskDetail = singleAccountInfo?.risk_result?.risk_detail ?? [];
        return [...acc, ...thisAccountRiskDetail];
      }, []);

      const misttrackRiskLabelsByAddress = getMisttrackCategoriesByAddress(mergedMisttrackRiskDetail);

      const txsWithCustomRisk = transactions.map(tx => {
        const involvedAddresses = [...tx.inputs, ...tx.outputs]
          .map(movement => movement?.address)
          .filter(address => valueExists(address))
          .map(address => address.toLowerCase());

        const involvedMisttrackCategories = unique(
          involvedAddresses
            .map(address => misttrackRiskLabelsByAddress?.[address])
            .filter(labels => labels)
            .flat()
        );

        const riskFromLabels = getRiskFromMisttrackCategories(involvedMisttrackCategories);

        const transformMovement = (movement) => {
          const address = (movement?.address ?? '').toLowerCase();
          const categories = misttrackRiskLabelsByAddress?.[address] ?? [];
          categories.sort((a, b) => {
            const aRisk = (misttrackCategoryToRisk?.[b] ?? 0);
            const bRisk = (misttrackCategoryToRisk?.[a] ?? 0);
            return aRisk > bRisk ? -1 : 1;
          });

          const movementIsLowRisk = isLowRisk(movement?.risk ?? 100);
          const misttrackRisk = getRiskFromMisttrackCategories(categories);
          const combinedRisk = movementIsLowRisk ? Math.max(movement.risk, misttrackRisk) : misttrackRisk;

          const bmLowRiskCategories = movement.categories.filter(category => isLowRisk(categoryToRiskScore(category)));
          categories.push(...bmLowRiskCategories);

          return {
            ...movement,
            category: categories?.[0] ?? '',
            categories,
            risk: combinedRisk,
          };
        };

        const inputsWithCustomCategories = (tx?.inputs ?? []).map(transformMovement);
        const outputsWithCustomCategories = (tx?.outputs ?? []).map(transformMovement);

        return {
          ...tx,
          inputs: inputsWithCustomCategories,
          outputs: outputsWithCustomCategories,
          risk: riskFromLabels,
        };
      });

      return txsWithCustomRisk;
    }
  };

  const hasNextPage = () => {
    return txsPageCursorRef.current !== LAST_PAGE_CURSOR;
  };

  const reset = () => {
    txsPageCursorRef.current = null;
  };

  const getAllowedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS, filterTypes.PRICE, filterTypes.TYPES];
  };

  return { loadNextPage, hasNextPage, reset, getAllowedFilters };
};

export default useLoadNextTxsPage;

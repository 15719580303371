import FreePlanIcon from '../../assets/images/svg/plan-free.svg';
import EnterprisePlanIcon from '../../assets/images/svg/plan-enterprise.svg';
import GenericPlanIcon from '../../assets/images/svg/plan-generic.svg';

export const numberToPrice = (price, currency = 'usd', fractionDigits = 0, withLeadingPlus = false) => {
  let formattedPrice =  (price ?? 0).toLocaleString('en-US', {
    style: 'currency',
    currency: currency ?? 'usd',
    maximumFractionDigits: fractionDigits
  });
  if (withLeadingPlus && price > 0) {
    formattedPrice = `+${formattedPrice}`;
  }
  return formattedPrice;
};

export const formatPlanPrice = (plan) => {
  const priceInfo = plan.prices.find((price) => price.period === 'month');
  if (!priceInfo) {
    return 'unknown';
  }
  return `${numberToPrice(priceInfo.price, priceInfo.currency)}/${priceInfo.period}`;
};

export const getPlanImgSrc = (plan) => {
  if (!plan) {
    return GenericPlanIcon;
  }
  switch (plan.name.toLowerCase()) {
  case 'free':
    return FreePlanIcon;
  case 'enterprise':
    return EnterprisePlanIcon;
  default:
    return GenericPlanIcon;
  }
};


import { useParams } from 'react-router-dom';
import useDeposits from '../../services/deposits/deposits-service';
import { useEffect, useState } from 'react';
import { getTemporaryJwt } from '../../services/temporary-user/temporary-user-service';
import { dateStrToTimestampSeconds } from '../../utils/date-formatter';
import Loader from '../../components/loaders/loader/Loader';
import globalStyles from '../customer-details/style.module.scss';
import CustomerDetailsBox from '../customer-details/CustomerDetailsBox';
import styles from './deposits.module.scss';
import { clsx } from 'clsx';
import CopyToClipboardWithIcon from '../../components/copy-to-clipboard-with-icon/CopyToClipboardWithIcon';
import { ReactComponent as WalletIcon } from '../../assets/images/svg/wallet.svg';
import DepositFilters from '../../components/deposits/deposit-filters/DepositFilters';
import { sliceWalletAddress } from '../customer-details/sliceWalletAddress';
import { maybePlural } from '../../utils/billing/utils';
import ExportButton from '../../components/export-button/ExportButton';

const Deposits = () => {
  const { id } = useParams();
  const { getDeposits } = useDeposits();

  const [since, setSince] = useState('');
  const [until, setUntil] = useState('');
  const [userId, setUserId] = useState('');

  const [deposits, setDeposits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadData = async () => {
    setIsLoading(true);
    const jwt = await getTemporaryJwt(id);
    const sinceTimestamp = dateStrToTimestampSeconds(since, false);
    const untilTimestamp = dateStrToTimestampSeconds(until, true);
    const obtainedDeposits = await getDeposits(id, jwt, sinceTimestamp, untilTimestamp, userId);
    setDeposits(obtainedDeposits);
    setIsLoading(false);
  };

  const handleApplyFilters = () => {
    setDeposits([]);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return <div>
    <div className={globalStyles.customers}>
      <CustomerDetailsBox
        title='Deposits of your clients'
        icon={<WalletIcon />}
        buttons={<ExportButton data={deposits} disabled={isLoading} filenameBase='deposits-export' />}
      >
        <DepositFilters
          since={since}
          setSince={setSince}
          until={until}
          setUntil={setUntil}
          userId={userId}
          setUserId={setUserId}
          handleApplyFilters={handleApplyFilters}
          disabled={isLoading}
        />
        <div className={styles.depositsTableContainer}>
          <div className={styles.tableHeader}>
            <div>Time</div>
            <div>Deposit ID</div>
            <div>Fiat amount</div>
            <div>Fiat currency</div>
            <div>User ID</div>
            <div>Order ID</div>
            <div>Network</div>
            <div>Transaction ID</div>
          </div>
          {deposits.map(deposit => {
            const [date, time] = deposit.created_at.split('.')[0].split('T');
            return <div className={styles.tableRow} key={deposit.id}>
              <div className={styles.rowDataContainer}>
                <div className={styles.dataCellRow}>
                  {date}
                </div>
                <div className={styles.dataCellRow}>
                  {time}
                </div>
              </div>
              <div className={styles.rowDataContainer}>
                <CopyToClipboardWithIcon
                  displayText={deposit.id}
                  copyValue={deposit.id}
                  tooltipDisplayedData={deposit.id}
                >
                  {sliceWalletAddress(deposit.id, 8)}
                </CopyToClipboardWithIcon>
              </div>
              <div className={styles.rowDataContainer}>
                <span className={clsx(styles.strong, styles.dark)}>
                  {deposit.fiat_amount}
                </span>
              </div>
              <div className={styles.rowDataContainer}>
                <span className={styles.dark}>
                  {deposit.fiat_currency}
                </span>
              </div>
              <div className={styles.rowDataContainer}>{deposit.user_id ?? '-'}</div>
              <div className={styles.rowDataContainer}>{deposit.order_id ?? '-'}</div>
              <div className={styles.rowDataContainer}>{deposit.network}</div>
              <div className={styles.rowDataContainer}>
                {deposit.transaction_id && deposit.transaction_id.includes('-') && <div>Waiting for exchange</div>}
                {deposit.transaction_id && !deposit.transaction_id.includes('-') &&
                  <CopyToClipboardWithIcon
                    displayText={deposit.transaction_id}
                    copyValue={deposit.transaction_id}
                    tooltipDisplayedData={deposit.transaction_id}
                  >
                    {sliceWalletAddress(deposit.transaction_id, 6)}
                  </CopyToClipboardWithIcon>
                }
                {!deposit.transaction_id && <div>Not available</div>}
              </div>
            </div>;
          })}
        </div>
        <div className={styles.total}>
          Found {deposits.length} {maybePlural('record', deposits.length !== 1)}.
        </div>
      </CustomerDetailsBox>
    </div>
  </div>;
};

export default Deposits;

import Card from 'components/card/Card';
import { useEffect, useState, useContext, useMemo } from 'react';
import CreateProjectModal from 'components/modals/create-project-modal/CreateProjectModal';
import Header from 'components/header/Header';
import { useNavigate, useOutletContext, generatePath } from 'react-router-dom';
import Loader from 'components/loaders/loader/Loader';
import { useRoleBasedRedirect } from '../../hooks';
import { UserContext } from '../../context/user/UserContext';
import appRoutes from '../../routes/routes';
import { hiddenProjects } from '../../utils/hidden-projects';

const MyProjects = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amlDemoProcessed, setAmlDemoProcessed] = useState(false);
  const {
    isLoading,
    getProjectList,
    projects
  } = useOutletContext();
  const filteredProjects = useMemo(
    () => projects.filter(project => !hiddenProjects.includes(project.name)),
    [projects]
  );
  const { getConfig } = useContext(UserContext);
  const roleBasedRedirect = useRoleBasedRedirect();

  useEffect(() => {
    if (!getConfig('my_projects') || getConfig('deposits')) {
      roleBasedRedirect();
    } else {
      setAmlDemoProcessed(true);
    }
  }, []);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleRedirect = (project) => {
    navigate(generatePath(appRoutes.home.analytics, { id: project.id }));
  };

  useEffect(() => {
    if (filteredProjects.length === 1 && !getConfig('add_new_project')) {
      handleRedirect(filteredProjects[0]);
    }
  }, [filteredProjects]);

  if (!amlDemoProcessed) {
    return <Loader />;
  }

  return (
    <div className='page-content-container'>
      <Header headerTitle='My Projects' />
      <div className='page-content'>
        {isModalOpen && <CreateProjectModal getProjectList={getProjectList} handleClose={handleClose}/> }
        <div className='my-projects-container'>
          {isLoading ? (<Loader/>) : (
            <>
              {getConfig('add_new_project') && <Card onClick={handleOpen}/>}
              {filteredProjects.map((project) => (
                <Card
                  demo={project.demo}
                  projectName={project.name}
                  key={project.id}
                  handleRedirect={() => handleRedirect(project)}
                  imageSrc={project.imageSrc}
                />
              )
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyProjects;

import { getChartParsedData, getMaxValueFromData, getPercentageValue } from '../ChartHelper.js';
import { useMemo } from 'react';
import { NoData } from 'components/no-data-container/NoData';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as UsersIcon } from 'assets/images/svg/users.svg';
import { useNavigate, useParams } from 'react-router-dom';

export const HorizontalBarChartComponent = ({ widget }) => {
  const maxValue = getMaxValueFromData(widget.data, 'count');
  const parsed = useMemo(() => getChartParsedData(widget.data, 'count'), [widget]);
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className='horizontal-bar-chart-wrapper'>
      {parsed.length === 0 ? (<NoData>
        <MainButton
          variant='button-no-data'
          label='IMPORT CLIENTS'
          icon={<UsersIcon/>}
          onClick={() => navigate(`/my-projects/${id}/users`)}
        />
      </NoData>)
        : (parsed.map((el, index) => (
          <div className={'column-wrapper'} key={index}>
            <div className='bar-wrapper'>
              <div className='bar' style={{
                height: getPercentageValue(el.count, maxValue),
                backgroundColor: el.color
              }}/>
            </div>
            <div>
              <img src={el.icon} alt='icon' className='bar-icon'/>
            </div>
            <div>{el.count}</div>
          </div>
        )))}
    </div>
  );
};

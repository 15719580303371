import styles from './style.module.scss';
import UsersManagementTableRow from './UsersManagementTableRow';
import UsersManagementTableSubheader from './UsersManagementTableSubheader';

const UsersManagementTableBody = ({ usersData, handleUserEdit, handleUserDelete, setEditedUserId }) => {
  return (
    <div className={styles.table}>
      <UsersManagementTableSubheader/>
      {usersData.map((item) => (
        <UsersManagementTableRow
          key={item.email}
          rowData={item}
          handleUserEdit={handleUserEdit}
          handleUserDelete={handleUserDelete}
          setEditedUserId={setEditedUserId}
        />
      ))}
    </div>
  );
};

export default UsersManagementTableBody;

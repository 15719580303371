export const formatNumber = (num) => {
  if (num < 10000) {
    return String(num);
  }
  if (num < 1000000) {
    return `${Math.floor(num / 1000)}k`;
  }
  if (num < 1000000000) {
    return `${Math.floor(num / 1000000)}M`;
  }
  return `${Math.floor(num / 1000000000)}B`;
};

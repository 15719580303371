import { ReactComponent as DiscordIcon } from 'assets/images/svg/socials/discord.svg';
import { ReactComponent as DomainsIcon } from 'assets/images/svg/socials/domains.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/svg/socials/facebook.svg';
import { ReactComponent as GithubIcon } from 'assets/images/svg/socials/github.svg';
import { ReactComponent as InstagramIcon } from 'assets/images/svg/socials/instagram.svg';
import { ReactComponent as LinkedInIcon } from 'assets/images/svg/socials/linkedIn.svg';
import { ReactComponent as RedditIcon } from 'assets/images/svg/socials/reddit.svg';
import { ReactComponent as TelegramIcon } from 'assets/images/svg/socials/telegram.svg';
import { ReactComponent as TwitterIcon } from 'assets/images/svg/socials/twitter.svg';
import { ReactComponent as YouTubeIcon } from 'assets/images/svg/socials/twitter.svg';

export const iconConfig = {
  twitter: <TwitterIcon />,
  facebook: <FacebookIcon />,
  discord: <DiscordIcon />,
  domain: <DomainsIcon />,
  github: <GithubIcon />,
  instagram: <InstagramIcon />,
  linkedin: <LinkedInIcon />,
  reddit: <RedditIcon />,
  telegram: <TelegramIcon />,
  youtube: <YouTubeIcon />
};

export const FIAT = 'Fiat';
export const CRYPTO = 'Crypto';

export const fiatCurrenciesList = [
  'tnd', 'hrk', 'mur', 'mad', 'scr', 'syp', 'pen', 'xof', 'srd',
  'lbp', 'etb', 'inr', 'yer', 'awg', 'tzs', 'nad', 'gel', 'ngn',
  'std', 'mga', 'egp', 'bsd', 'twd', 'rwf', 'czk', 'gtq', 'lvl',
  'pln', 'mnt', 'ssp', 'vef', 'nok', 'gmd', 'pkr', 'byn', 'cve',
  'vuv', 'hkd', 'omr', 'bam', 'idr', 'rsd', 'aoa', 'lsl', 'ltl',
  'cuc', 'xaf', 'lak', 'bob', 'isk', 'irr', 'gyd', 'azn', 'rub',
  'lrd', 'dop', 'gbp', 'uah', 'xpf', 'khr', 'cdf', 'iqd', 'kes',
  'bdt', 'bnd', 'ern', 'mxn', 'ron', 'amd', 'npr', 'pyg', 'cny',
  'zmw', 'svc', 'eur', 'bgn', 'mro', 'szl', 'top', 'qar', 'ils',
  'mdl', 'uzs', 'brl', 'sll', 'dzd', 'try', 'thb', 'mmk', 'mop',
  'clf', 'aed', 'cad', 'mzn', 'vnd', 'xcd', 'kmf', 'fkp', 'afn',
  'bbd', 'crc', 'bov', 'ghs', 'ang', 'sek', 'cup', 'sgd', 'htg',
  'sos', 'nio', 'bif', 'ugx', 'sdg', 'jpy', 'myr', 'aud', 'sar',
  'lyd', 'jod', 'gip', 'uyu', 'nzd', 'krw', 'kyd', 'tmt', 'bwp',
  'mwk', 'huf', 'ttd', 'hnl', 'kpw', 'tjs', 'kwd', 'djf', 'all',
  'sbd', 'btn', 'kzt', 'gnf', 'shp', 'ars', 'lkr', 'cop', 'dkk',
  'bmd', 'mkd', 'jmd', 'chf', 'zar', 'pab', 'php', 'zwl', 'clp',
  'bzd', 'mvr', 'wst', 'pgk', 'fjd', 'kgs', 'bhd', 'usd'
];

export const fiatCurrenciesSet = new Set(fiatCurrenciesList);

export const determineCurrencyType = (ticker) => {
  const lowercaseTicker = ticker.toLowerCase();
  return fiatCurrenciesSet.has(lowercaseTicker) ? FIAT : CRYPTO;
};

import { Link } from 'react-router-dom';
import { sourceUrls } from 'components/webflowURLs/source';
import appRoutes from 'routes/routes';

function PageHeader() {
  const { home } = appRoutes;
  const { hexagonSrc, boltBlackSrc, blockmateBcSrc, futureReadySrc } =
    sourceUrls;

  return (
    <>
      <div className='header-icon-div'>
        <Link to={home.dashboard} className='header-icon-container'>
          <span className='header-icon-logo'>
            <img
              src={hexagonSrc}
              alt='Blockmate-logo'
              className='header-icon-hexagon'
            />
            <img
              src={boltBlackSrc}
              alt='Blockmate-logo-bolt'
              className='header-icon-bolt'
            />
          </span>

          <img
            src={blockmateBcSrc}
            alt='BlockmateBC'
            className='header-icon-text-bc'
          />

          <img
            src={futureReadySrc}
            alt='Future-Ready'
            className='header-icon-future-ready'
          />
        </Link>
      </div>
    </>
  );
}

export default PageHeader;

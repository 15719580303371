import Checkbox from 'components/checkbox/Checkbox';

const PermissionBox = ({ children, optional, handleCheckbox }) => {
  return (
    <div className='permission-box'>
      <Checkbox onChange={handleCheckbox}/>
      <p className='accept-permissions'>
        {children}
        <span className='addon-info'>
          {optional ? ' · Optional ' : ' · Required '}
        </span>
      </p>
    </div>
  );
};

export default PermissionBox;

import React from 'react';
import { toast } from 'react-hot-toast';
import { axiosErrorHandler } from 'services/api/axios';
import authService from 'services/auth-services/auth.service';
import { FaGithub } from 'react-icons/fa';

const GithubButton = () => {
  const { loginGithub } = authService;

  const loginViaGithub = async () => {
    try {
      await loginGithub();
    } catch (error) {
      toast.error(axiosErrorHandler(error));
    }
  };

  return (
    <div className='sign-in-buttons'>
      <button className='google-github-btn secondary-button' onClick={() => loginViaGithub()}>
        <FaGithub className='google-github-icon'/>
        <span>Sign in with Github</span>
      </button>
    </div>
  );
};

export default GithubButton;

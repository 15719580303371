import { useState, createContext } from 'react';

export const NotificationContext = createContext();

const NotificationContextProvider = ({ children }) => {
  const [notificationData, setNotificationData] = useState(null);
  const [notificationType, setNotificationType] = useState(null);

  const data = {
    notificationData,
    setNotificationData,
    notificationType,
    setNotificationType
  };

  return <NotificationContext.Provider value={data}>{children}</NotificationContext.Provider>;
};

export default NotificationContextProvider;

import appRoutes from 'routes/routes';

function CookiesPrivacy() {
  const year = new Date().getFullYear();
  const { external } = appRoutes;

  return (
    <>
      <p>
        <span>&copy; {year} </span>

        <span>
          <a
            href={external.main}
            target='_blank'
            rel='noopener noreferrer'
            className='main-footer-text-link'>
            Blockmate
          </a>
          <a
            href={external.termsAndCondition}
            target='_blank'
            rel='noopener noreferrer'
            className='main-footer-text-link'>
            {' '}
            · Terms of service
          </a>
          <a
            href={external.privacyPolicy}
            target='_blank'
            rel='noopener noreferrer'
            className='main-footer-text-link'>
            {' '}
            · Privacy policy
          </a>
          <a
            href={external.cookiePolicy}
            target='_blank'
            rel='noopener noreferrer'
            className='main-footer-text-link'>
            {' '}
            · Cookies
          </a>
        </span>
      </p>
    </>
  );
}

export default CookiesPrivacy;

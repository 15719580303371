import styles from './style.module.scss';
import clsx from 'clsx';

const TableButton = ({ icon, label, onClick, disabled, variant }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={clsx(
        styles.tableButton,
        variant && styles[variant]
      )}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {label}
    </button>
  );
};

export default TableButton;

import { ReactComponent as Search } from 'assets/images/svg/search.svg';
import { ReactComponent as UsersSecondary } from 'assets/images/svg/users-secondary.svg';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useState } from 'react';

const UsersTableSubheader = ({ handleChange, handleOpen, isContractImported }) => {
  const [searchParams] = useSearchParams();
  const [searchParam, setSearchParam] = useState(searchParams.get('search') ?? '');

  const handleChangeSearch = useCallback((e) => {
    setSearchParam(e.target.value);
    handleChange(e.target.value);
  }, [handleChange]);

  return (
    <div className='user-list-subheader-container'>
      <div className='user-list-subheader-item' onClick={handleOpen}>
        <UsersSecondary/>
        <span>
          {isContractImported ? 'Export clients' : 'Import / Export clients'}
        </span>
      </div>
      <div className='user-list-subheader-item'>
        <div className='icon'>
          <Search/>
        </div>
        <input
          className='search-input'
          placeholder='Search name or adress'
          type='text'
          value={searchParam}
          onChange={handleChangeSearch}
        />
      </div>
    </div>
  );
};

export default UsersTableSubheader;

import { useContext, useState } from 'react';
import { UserContext } from 'context/user/UserContext';
import PageMainContainer from 'components/page-main-container/PageMainContainer';
import FormBottomLink from 'components/form/form-bottom-link/FormBottomLink';
import bluePinkWaves from 'assets/images/NFT/blue-pink-waves.jpg';
import appRoutes from 'routes/routes';
import FormHeader from 'components/form/form-header/FormHeader';
import { ReactComponent as RefreshIcon } from 'assets/images/svg/refresh.svg';
import MainButton from 'components/buttons/MainButton';
import useNotification from 'components/notification/UseNotification';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { axiosErrorHandler } from '../../../services/api/axios';
import Select from '../../../components/select/Select';
import { valueExists } from '../../../utils/common';

function VerifySignUp() {
  const { performResendEmail } = useContext(UserContext);
  const { external } = appRoutes;
  const showNotification = useNotification();
  const [slug, setSlug] = useState('');
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const [currentSlugs, setCurrentSlugs] = useState([]);

  const handleResendEmail = async () => {
    try {
      const operationInfo = await performResendEmail(email, slug);
      if (operationInfo.success) {
        showNotification('resendEmail', 'success');
      } else if (operationInfo.containsSlugs) {
        setCurrentSlugs(operationInfo.response);
      }
    } catch (error) {
      toast.error(axiosErrorHandler(error));
    }
  };

  return (
    <>
      <PageMainContainer nftBgImg={bluePinkWaves}>
        <div className='content'>
          <div className='form-wrapper main-form'>
            <FormHeader
              title='Verify your e-mail'
              titleText={`We've sent you an email to ${email}. Please click on the button 'Verify e-mail’ to create Blockmate account link.`}
            />

            {currentSlugs.length > 0 &&
              <Select
                options={currentSlugs.map(slug => ({ label: slug }))}
                onOptionClick={option => setSlug(option.label)}
                value={slug}
              />
            }
            {currentSlugs.length > 0 && !valueExists(slug) && (
              <p className='form-error-message'>Please select a company</p>
            )}

            <MainButton
              onClick={handleResendEmail}
              variant='button-gray'
              label='RESEND EMAIL'
              icon={<RefreshIcon />}
            />
            <FormBottomLink
              linkContent='Need help? Contact support →'
              linkPath={external.contact}
              externalLink={true}
            />
          </div>
        </div>
      </PageMainContainer>
    </>
  );
}

export default VerifySignUp;

import { ReactComponent as BoltIconGreen } from 'assets/images/svg/boltGreenIcon.svg';
import { ReactComponent as BoltIcon } from 'assets/images/svg/boltIcon.svg';
import appRoutes from '../../routes/routes';
import MainButton from '../buttons/MainButton';

const PlanButton = ({ plan, priceId, onClick }) => {

  const handleClick = () => {
    if (plan.contact_only) {
      window.location.href = appRoutes.external.contact;
    }
    onClick(priceId);
  };

  if (['upgrade', 'select'].includes(plan.type)) {
    return <MainButton
      extraClasses='plan-button'
      variant='button-purple'
      label={<div className='plan-button-text'>{plan.type} plan</div>}
      icon={<BoltIconGreen />}
      onClick={handleClick}
    />;
  }
  if (plan.type === 'downgrade') {
    return <MainButton
      extraClasses='plan-button downgrade-plan-button'
      variant='button-purple-light'
      label={<div className='plan-button-text'>{plan.type} plan</div>}
      icon={<BoltIcon />}
      onClick={handleClick}
    />;
  }
  if (plan.type === 'current') {
    return <MainButton
      extraClasses='plan-button'
      variant='button-purple'
      // Stripe does not know about Free plans, so they cannot be managed through Stripe
      label={<div className='plan-button-text'>{plan.free ? 'current plan' : 'manage plan'}</div>}
      icon={<BoltIconGreen />}
      onClick={handleClick}
      disabled={plan.free}
    />;
  }
  return <MainButton
    extraClasses='plan-button'
    variant='button-black'
    label={<div className='plan-button-text'>contact sales</div>}
    icon={<BoltIconGreen />}
    onClick={handleClick}
  />;
};

export default PlanButton;

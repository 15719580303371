import { createContext, useState } from 'react';
import { axiosErrorHandler, contentful, getStaticPath } from 'services/api/axios';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { PERIOD_LABELS, widgetsConfig } from 'utils/widgets-helper.js';

export const WidgetContext = createContext();

const WidgetContextProvider = ({ children }) => {
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(true);
  const { ACCESS_TOKEN, SPACE_ID, ENTRY_WIDGET, ENTRY_HORIZONTAL } = contentful;
  const [widgetsBannerData, setWidgetsBannerData] = useState(null);
  const [analyticsBannerData, setAnalyticsBannerData] = useState(null);
  const [currentTimeRange, setCurrentTimeRange] = useState(PERIOD_LABELS['7d']);

  const getWidgets = async (id, currency) => {
    try {
      setLoading(true);
      const { data } = await axios.get(getStaticPath('STATISTICS_URL', 'dashboard'),{ params: { project_id: id, currency: currency } });
      setWidgets(data.widgets);
      setLoading(false);
    } catch (error) {
      toast.error(axiosErrorHandler(error));
    }
    setLoading(false);
  };

  const parsedWidgets = widgetsConfig.map(widget => {
    const current = widgets.find(el => el.name === widget.name) || {};
    return {
      ...widget,
      visible: !!current.name,
      timeframe: current.timeframe,
      data: current.data,
    };
  });

  const getWidgetsBanner = async () => {
    try {
      const { data } = await axios.get(
        getStaticPath('CONTENTFUL_URL', `${SPACE_ID}/environments/master/entries/${ENTRY_WIDGET}?access_token=${ACCESS_TOKEN}`),
      );
      const bannerImageId = (data.fields.widgetsBannerBackground.sys.id);
      const iconWorldId = (data.fields.widgetsBannerLabelIcon.sys.id);
      const iconWorld = await axios.get(
        getStaticPath('CONTENTFUL_URL', `${SPACE_ID}/environments/master/assets/${iconWorldId}?access_token=${ACCESS_TOKEN}`),
      );
      const bannerImage = await axios.get(
        getStaticPath('CONTENTFUL_URL', `${SPACE_ID}/environments/master/assets/${bannerImageId}?access_token=${ACCESS_TOKEN}`),
      );
      const ContenfulData = { ...data.fields, ...{ iconWorld: iconWorld.data.fields.file }, ...{ bannerImage: bannerImage.data.fields.file } };
      setWidgetsBannerData(ContenfulData);
    } catch (error) {
      toast.error(axiosErrorHandler(error));
    }
  };

  const getAnalyticsBanner = async () => {
    try {
      const { data } = await axios.get(
        getStaticPath('CONTENTFUL_URL', `${SPACE_ID}/environments/master/entries/${ENTRY_HORIZONTAL}?access_token=${ACCESS_TOKEN}`),
      );
      const bannerImageId = (data.fields.horizontalBannerBackground.sys.id);
      const iconStarId = (data.fields.horizontalBannerLabelIcon.sys.id);
      const iconStar = await axios.get(
        getStaticPath('CONTENTFUL_URL', `${SPACE_ID}/environments/master/assets/${iconStarId}?access_token=${ACCESS_TOKEN}`),
      );
      const bannerImage = await axios.get(
        getStaticPath('CONTENTFUL_URL', `${SPACE_ID}/environments/master/assets/${bannerImageId}?access_token=${ACCESS_TOKEN}`),
      );
      const ContenfulData = { ...data.fields, ...{ iconStar: iconStar.data.fields.file }, ...{ bannerImage: bannerImage.data.fields.file } };
      setAnalyticsBannerData(ContenfulData);
    } catch (error) {
      toast.error(axiosErrorHandler(error));
    }
  };

  const data = {
    widgets,
    setWidgets,
    loading,
    setLoading,
    parsedWidgets,
    widgetsBannerData,
    analyticsBannerData,
    getWidgets,
    getWidgetsBanner,
    getAnalyticsBanner,
    currentTimeRange,
    setCurrentTimeRange
  };

  return (
    <WidgetContext.Provider value={data}>{children}</WidgetContext.Provider>
  );
};

export default WidgetContextProvider;

import { clsx } from 'clsx';

const MenuButton = ({ children, className, onClick, icon, disabled, dataTooltip }) => {
  return (
    <button
      className={clsx('menu-button', className && className, disabled && 'disabled')}
      onClick={onClick}
      data-tooltip={dataTooltip}
    >
      <div className='button-icon-container'>
        {icon}
      </div>
      <span className='menu-button-description'>{children}</span>
    </button>
  );
};

export default MenuButton;

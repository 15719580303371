import styles from './style.module.scss';

const Bullet = () => <div className={styles.bullet} />;

const ConsolidatedTransactionsLegend = ({ concise = false }) => {
  return <div className={styles.legend}>
    <div className={styles.info}>
      <div className={styles.legendEntryContainer}>
        <Bullet/> <span className={styles.legendText}>My address</span>
      </div>
    </div>
    <div className={styles.alert}>
      <div className={styles.legendEntryContainer}>
        <Bullet/> <span className={styles.legendText}>Banned address/High risk</span>
      </div>
    </div>
    {concise && <div className={styles.success}>
      <div className={styles.legendEntryContainer}>
        <Bullet/> <span className={styles.legendText}>Low risk</span>
      </div>
    </div>}
    {concise && <div className={styles.withdrawalDeposit}>
      <div className={styles.legendEntryContainer}>
        <Bullet/> <span className={styles.legendText}>Withdrawal/Deposit</span>
      </div>
    </div>}
  </div>;
};

export default ConsolidatedTransactionsLegend;

import Sidebar from 'components/sidebar/Sidebar';
import { Outlet } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { axiosErrorHandler, contentful, getStaticPath } from 'services/api/axios';
import DashboardSidebar from 'components/sidebar/DashboardSidebar.jsx';
import { NUMBER_OF_HEADER_IMAGES } from 'pages/dashboard/Dashboard';
import { toast } from 'react-hot-toast';
import { UserContext } from '../../context/user/UserContext';
import handleApiError from '../../services/ErrorHandleUtil';

const DashboardLayout = () => {
  const [bannerData, setBannerData] = useState(null);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBannerLoading, setIsBannerLoading] = useState(false);
  const [explorerProject, setExplorerProject] = useState(null);
  const { ACCESS_TOKEN, SPACE_ID, ENTRY_ONBOARDING } = contentful;
  const { getConfig } = useContext(UserContext);

  const getProjectList = useCallback(async () => {
    setIsLoading(true);
    const { data } = await axios.get(
      getStaticPath('PROJECT_URL'),
    );

    const parsed = data
      .sort((a, b) => a.api_id - b.api_id)
      .map((project, index) => {
        return {
          ...project,
          imageSrc: `/card-images/Project-Header-Image-${index % NUMBER_OF_HEADER_IMAGES}.png`
        };
      })
      .reverse();

    if (getConfig('show_demo_project')) {
      setProjects(parsed);
    } else {
      setProjects(parsed.filter(project => !project.demo));
    }

    setIsLoading(false);
  }, []);

  const getContenfulData = async() => {
    setIsBannerLoading(true);
    try {
      const { data } = await axios.get(
        getStaticPath('CONTENTFUL_URL', `${SPACE_ID}/environments/master/entries/${ENTRY_ONBOARDING}?access_token=${ACCESS_TOKEN}`),
      );
      const bannerImageId = (data.fields.bannerImage.sys.id);
      const labelReadingTimeIconId = (data.fields.labelReadingTimeIcon.sys.id);
      const iconTime = await axios.get(
        getStaticPath('CONTENTFUL_URL', `${SPACE_ID}/environments/master/assets/${labelReadingTimeIconId}?access_token=${ACCESS_TOKEN}`),
      );
      const bannerImage = await axios.get(
        getStaticPath('CONTENTFUL_URL', `${SPACE_ID}/environments/master/assets/${bannerImageId}?access_token=${ACCESS_TOKEN}`),
      );
      const ContenfulData = { ...data.fields, ...{ iconTime: iconTime.data.fields.file }, ...{ bannerImage: bannerImage.data.fields.file } };
      setBannerData(ContenfulData);
      if (bannerImage) {
        setIsBannerLoading(false);
      }
    } catch (error) {
      toast.error(axiosErrorHandler(error));
      setIsBannerLoading(false);
    }
  };

  const getExplorerProject = () => {
    axios.get(getStaticPath('PROJECT_URL')).then(data => {
      const foundExplorerProjects = (data?.data ?? []).filter(project => project.name === 'Explorer');
      if (foundExplorerProjects.length > 0) {
        setExplorerProject(foundExplorerProjects[0]);
      }
    }).catch (reason => {
      handleApiError('getting explorer project', reason);
    });
  };

  useEffect(() => {
    getContenfulData();
    getProjectList();
    getExplorerProject();
  }, []);

  return (
    <div className='page-container'>
      <Sidebar>
        <DashboardSidebar/>
      </Sidebar>
      <Outlet context={{
        projects,
        explorerProject,
        setProjects,
        getProjectList,
        isLoading,
        bannerData,
        isBannerLoading
      }}/>
    </div>
  );
};

export default DashboardLayout;

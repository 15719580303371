import styles from './style.module.scss';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as UsersIcon } from 'assets/images/svg/users.svg';
import { ReactComponent as BoltIcon } from 'assets/images/svg/boltGreenIcon.svg';

const InviteUsersWelcome = ({ handleInviteUsers }) => {
  return (
    <div className={styles.inviteNewUsers}>
      <div className={styles.container}>
        <div className={styles.icon}>
          <UsersIcon/>
        </div>
        <p className={styles.title}>Invite new account users</p>
        <p className={styles.description}>Currently, there aren&apos;t any users in your account. Invite them over to collaborate on your projects.</p>
        <div className={styles.buttonContainer}>
          <MainButton
            variant='button-purple'
            type='submit'
            onClick={handleInviteUsers}
            label='INVITE A NEW USER'
            icon={<BoltIcon/>}
          />
        </div>
      </div>
    </div>
  );
};

export default InviteUsersWelcome;

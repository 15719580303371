import BannerWithPlanLimits from './BannerWithPlanLimits';

const BannerContentful = ({ bannerData, withPlanLimits }) => {

  if (!bannerData) {
    return null;
  }

  if (withPlanLimits) {
    return (
      <div className='extended-banner'>
        <div className='content-baner-small'>
          <div className='content-banner-small-text'>
            <p className='small-baner-description-header'>{bannerData.bannerLabel}</p>
            <div className='title-about'>
              <p className='small-baner-description-title'>{bannerData.bannerHeadline}</p>
              <p className='small-baner-description-about'>{bannerData.bannerDescription}</p>
            </div>
            <p className='small-baner-description-link'>{bannerData.bannerLinkText}</p>
          </div>
        </div>
        <BannerWithPlanLimits/>
      </div>
    );
  }

  return (
    <div className='baner-box'>
      <a href={bannerData.bannerLinkUrl} className='baner-container'>
        <div className='baner-img'>
          <div
            style={{ background: `url(${bannerData.bannerImage?.url}`, backgroundSize: 'cover', width: '100%', height: '100%' }}
          />
        </div>
        <div className='baner-description'>
          <div className='banner-tag time-tag'>
            <img src={bannerData.iconTime?.url}  className='banner-time-icon' alt='banner'/>
            <p>{bannerData.labelReadingTimeText}</p>
          </div>
          <p className='baner-description-header'>{bannerData.bannerLabel}</p>
          <p className='baner-description-title'>{bannerData.bannerHeadline}</p>
          <p className='baner-description-about'>{bannerData.bannerDescription}</p>
          <p className='baner-description-link'>{bannerData.bannerLinkText}</p>
        </div>
      </a>
    </div>
  );
};

export default BannerContentful;

import { Link } from 'react-router-dom';
import { FiArrowRight, FiLayers } from 'react-icons/fi';

function NFTCollection({ bgImg }) {
  return (
    <>
      <div
        className='nft-collection-btn-container'
        style={{ backgroundImage: `url(${bgImg})` }}>
        <div className='nft-collection-btn'>
          <div>
            <FiLayers className='nft-collection-icon' />
          </div>

          <div>
            <p className='nft-collection-title'>Subject #707</p>
            <Link to='/' className='nft-collection-link'>
              View our NFT collection
              <FiArrowRight className='nft-collection-link-icon' />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NFTCollection;

import styles from './price-filter.module.scss';
import { ReactComponent as ExpandIcon } from '../../../assets/images/svg/dash.svg';
import { useEffect, useRef, useState } from 'react';
import { SUPPORTED_CURRENCIES } from '../../../utils/project-currencies';
import MainButton from '../../buttons/MainButton';
import { clsx } from 'clsx';
import { valueExists } from '../../../utils/common';

const PriceFilter = ({ text, currency = 'USD', priceRange, setPriceRange }) => {
  const ref = useRef();
  const currencySymbol = SUPPORTED_CURRENCIES.find(el => el.ticker === currency).symbol;
  const [open, setOpen] = useState(false);
  const [min, setMin] = useState(priceRange.min ?? '');
  const [max, setMax] = useState(priceRange.max ?? '');

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const toggleOpen = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleSubmit = () => {
    setPriceRange({ min, max });
    toggleOpen();
  };

  const handleReset = () => {
    setMin('');
    setMax('');
    setPriceRange({ min: '', max: '' });
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return <div className={styles.container} ref={ref}>
    <div
      className={clsx(styles.textWithIcon, (valueExists(priceRange.min) || valueExists(priceRange.max)) && styles.active)}
      onClick={toggleOpen}
    >
      <div className={styles.text}>
        {text}
      </div>
      <div className={styles.icon}>
        <ExpandIcon />
      </div>
    </div>
    {open && <div className={styles.priceSettingsContainer}>
      <div className={styles.inputWithCurrency}>
        <input
          className={styles.rangeInput}
          value={min}
          type='number'
          min={0}
          placeholder='Min value'
          onChange={e => setMin(e.target.value)}
        />
        <div className={styles.currencySymbol}>
          {currencySymbol}
        </div>
      </div>

      <div className={styles.inputWithCurrency}>
        <input
          className={styles.rangeInput}
          value={max}
          type='number'
          min={0}
          placeholder='Max value'
          onChange={e => setMax(e.target.value)}
        />
        <div className={styles.currencySymbol}>
          {currencySymbol}
        </div>
      </div>

      <MainButton
        variant='button-black'
        label='Set range'
        onClick={handleSubmit}
        extraClasses={styles.setRangeButton}
      />

      {(valueExists(min) || valueExists(max)) && <MainButton
        variant='button-purple-light'
        label='Reset'
        onClick={handleReset}
        extraClasses={styles.setRangeButton}
      />}
    </div>}
  </div>;
};

export default PriceFilter;

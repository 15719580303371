import styles from './no-account-message.module.scss';

const NoAccountMessage = ({ icon, title, description }) => {
  return <div className={styles.container}>
    <div className={styles.iconContainer}>
      <div className={styles.iconBg}>
        <div className={styles.icon}>
          {icon}
        </div>
      </div>
    </div>
    <div className={styles.title}>
      {title}
    </div>
    <div className={styles.description}>
      {description}
    </div>
  </div>;
};

export default NoAccountMessage;

import { ClipLoader } from 'react-spinners';

const MainButton = ({ onClick, type, variant, label, icon, loading, disabled, extraClasses='' }) => {

  return (
    <button disabled={disabled} className={`main-button ${variant} ${extraClasses}`} onClick={onClick} type={type} >
      {loading ? <ClipLoader color={'#6dff96'}/> : (
        <>
          {icon && <span className='icon'>{icon}</span>}
          {label}
        </>
      )}
    </button>
  );
};

export default MainButton;

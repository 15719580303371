import styles from './consolidated-transactions-filters.module.scss';
import { SelectWithCheckboxGroup, VARIANTS } from '../selectWithCheckboxGroup/SelectWithCheckboxGroup';
import { ReactComponent as CrossIcon } from '../../assets/images/svg/cross.svg';
import MainButton from '../buttons/MainButton';
import PriceFilter from './price-filter/PriceFilter';
import { useMemo } from 'react';
import { valueExists } from '../../utils/common';
import { clsx } from 'clsx';

export const filterTypes = {
  ACCOUNTS: 'accounts',
  ASSETS: 'assets',
  TYPES: 'types',
  RISKS: 'risks',
  PRICE: 'price',
};

const ConsolidatedTransactionsFilters = (
  { accounts, setAccounts, assets, setAssets, types, setTypes, risks, setRisks, price, setPrice, handleApplyFilters, disabled = false, currency, hiddenFilters = [] }
) => {
  const canReset = useMemo(() => {
    const accountsChecked = accounts.some(account => account.checked);
    const assetsChecked = assets.some(asset => asset.checked);
    const typesChecked = types.some(type => type.checked);
    const risksChecked = risks.some(risk => risk.checked);
    const priceChecked = valueExists(price.min) || valueExists(price.max);
    return accountsChecked || assetsChecked || typesChecked || risksChecked || priceChecked;
  }, [JSON.stringify(accounts), JSON.stringify(assets), JSON.stringify(types), JSON.stringify(risks), JSON.stringify(price)]);

  const handleReset = () => {
    setAccounts(prevAccounts => prevAccounts.map(account => ({ ...account, checked: false })));
    setAssets(prevAssets => prevAssets.map(asset => ({ ...asset, checked: false })));
    setTypes(prevTypes => prevTypes.map(type => ({ ...type, checked: false })));
    setRisks(prevRisks => prevRisks.map(risk => ({ ...risk, checked: false })));
    setPrice({ min: '', max: '' });
  };

  return <div className={styles.container}>
    <span className={styles.header}>Filter data:</span>
    {!hiddenFilters.includes(filterTypes.ACCOUNTS) && <SelectWithCheckboxGroup
      checkboxes={accounts}
      setCheckboxes={setAccounts}
      label='Connected accounts'
      variant={VARIANTS.noOutline}
    />}
    {!hiddenFilters.includes(filterTypes.ASSETS) && <SelectWithCheckboxGroup
      checkboxes={assets}
      setCheckboxes={setAssets}
      label='Digital asset'
      variant={VARIANTS.noOutline}
    />}
    {!hiddenFilters.includes(filterTypes.TYPES) && <SelectWithCheckboxGroup
      checkboxes={types}
      setCheckboxes={setTypes}
      label='Type'
      variant={VARIANTS.noOutline}
    />}
    {!hiddenFilters.includes(filterTypes.RISKS) && <SelectWithCheckboxGroup
      checkboxes={risks}
      setCheckboxes={setRisks}
      label='Risk'
      variant={VARIANTS.noOutline}
    />}
    {!hiddenFilters.includes(filterTypes.PRICE) && <PriceFilter
      text='Value'
      currency={currency}
      priceRange={price}
      setPriceRange={setPrice}
    />}
    <div className={clsx(styles.resetFilters, canReset && styles.active)} title='Reset filters' onClick={handleReset}>
      <CrossIcon />
    </div>
    <MainButton
      onClick={handleApplyFilters}
      variant='button-purple'
      label={disabled ? 'Loading...' : 'Apply filters'}
      extraClasses={styles.applyFiltersButton}
      disabled={disabled}
    />
  </div>;
};

export default ConsolidatedTransactionsFilters;

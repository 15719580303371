import { useState, useContext } from 'react';
import { UserContext } from 'context/user/UserContext';
import LoadingSpinner from 'components/loaders/loading-spinner/LoadingSpinner';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as SendIcon } from 'assets/images/svg/send-icon.svg';
import useNotification from '../notification/UseNotification';
import Select from '../select/Select';
import { valueExists } from '../../utils/common';

function ResetPasswordForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [slug, setSlug] = useState('');
  const [currentSlugs, setCurrentSlugs] = useState([]);
  const [errors, setErrors] = useState({});
  const { setEmailSent, setResendEmail, setResendSlug, performRequestPasswordReset } = useContext(UserContext);
  const showNotification = useNotification();

  const handleResetPasswordSubmit = () => {
    if (!valueExists(email)) {
      setErrors({ email: 'Please enter Email' });
      return;
    }
    setIsLoading(true);
    performRequestPasswordReset(email, slug)
      .then(operationInfo => {
        if (operationInfo.success) {
          setResendEmail(email);
          setResendSlug(slug);
          setEmailSent(true);
        } else if (operationInfo.containsSlugs) {
          setCurrentSlugs(operationInfo.response);
        }
        setIsLoading(false);
      })
      .catch(() => showNotification('resetPassword', 'error'));
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className='main-form'>
          <input
            type='email'
            name='email'
            onChange={e => { setCurrentSlugs([]); setSlug(''); setEmail(e.target.value); }}
            value={email}
            placeholder='Your e-mail'
            className='main-form-input'
          />
          {errors.email && (
            <p className='form-error-message'>{errors.email}</p>
          )}

          {currentSlugs.length > 0 &&
            <Select
              options={currentSlugs.map(slug => ({ label: slug }))}
              onOptionClick={option => setSlug(option.label)}
              value={slug}
            />
          }
          {currentSlugs.length > 0 && !valueExists(slug) && (
            <p className='form-error-message'>Please select a company</p>
          )}

          <MainButton
            onClick={handleResetPasswordSubmit}
            variant='button-gray'
            label='SEND RESET INSTRUCTIONS'
            icon={<SendIcon />}
          />
        </div>
      )}
    </>
  );
}

export default ResetPasswordForm;

export const NOTIFICATIONS_ENUM = {
  ALERT: 'alert',
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
};

export const notificationsConfig = {
  userList: {
    error: {
      title: 'Uh-oh, something went wrong!',
      description: 'Welp, this glitch is on us. Please take a moment and try again',
    }
  },
  copyAdresses: {
    success: {
      title: 'Addresses copied',
      description: 'Addresses were successfully copied into the clipboard. You can use them now anywhere.',
    },
    error: {
      title: 'Copy action unsuccessful!',
      description: 'Uh-oh, something went wrong while you tried to copy addresses. Please try again in a moment.',
    },
  },
  projectUpdate: {
    success: {
      title: 'Project updated',
      description: 'Your project was successfully updated. Looking good!',
    },
    error: {
      title: 'Something went wrong',
      description: 'There\'s nothing wrong with new values. The system just couldn\'t change it. Please try again.',
    },
  },
  companyUpdate: {
    success: {
      title: 'Company updated',
      description: 'Your company settings were successfully updated. Looking good!',
    },
    error: {
      title: 'Company settings update failed',
      description: 'The company settings update didn\'t go as planned. Please try again in a moment.',
    },
  },
  passwordChange: {
    success: {
      title: 'Password changed',
      description: 'Your new password is now stored in the system. Simple as that!',
    },
  },
  deleteApiKey: {
    success: {
      title: 'API key was deleted',
      description: 'The API key was thrown into the abyss and deactivated. Sad but true.',
    },
    error: {
      title: 'API key was not deleted',
      description: 'Whoops, looks like we couldn\'t delete that one. Reload the page and try again.',
    },
  },
  copyApiKey: {
    success: {
      title: 'API key copied',
      description: 'API key was successfully copied into the clipboard. Now you can use it anywhere.',
    },
  },
  copyData: {
    success: {
      title: 'Data copied',
      description: 'Data was successfully copied into the clipboard. Now you can use it anywhere.',
    },
  },
  changeApiKeyName: {
    success: {
      title: 'API key name changed',
      description: 'API key name was successfully changed, and we like it!',
    },
    error: {
      title: 'API key name didn\'t change',
      description: 'Can API key have a name? Yes. But the system just couldn\'t change it. Please try again.',
    },
  },
  createApiKey: {
    alert: {
      title: 'API created successfully',
      description: 'Your new and shiny API key is ready. And our tech cat agrees. Please copy it before refreshing or leaving the page. It will be hidden after 3 minutes.',
    },
  },
  createPublicApiKey: {
    success: {
      title: 'Public API created successfully',
      description: 'Your new and shiny public API key is ready',
    },
  },
  createProject: {
    success: {
      title: 'New project created',
      description: 'The creation process nailed the landing with a perfect 10! Your new project is ready.',
    },
  },
  resendEmail: {
    success: {
      title: 'Email was re-sent',
      description: 'The email took a detour, but it finally found its way back! Please check your inbox.',
    },
    error: {
      title: 'Oopsie-doodle!',
      description: 'The email pulled a disappearing act on the re-send mission. Please try to re-send it again in a moment.',
    },
  },
  resetPassword: {
    error: {
      title: 'Password didn\'t reset',
      description: 'Oh no, the password decided it prefers its old identity! Try to reset it again.',
    },
  },
  login: {
    error: {
      title: 'Login denied',
      description: 'Houston, we have a login-tastrophy! But don\'t worry. Refresh the page and try to log in again.',
    },
  },
  confirmPassword: {
    error: {
      title: 'Oops! This isn\'t right.',
      description: 'The password confirmation is going rogue on us. Please try again.',
    },
  },
  deleteProject: {
    success: {
      title: 'Project deleted',
      description: 'It\'s like Houdini, but with data. Your project was deleted successfully.'
    },
    error: {
      title: 'Project wasn\'t deleted',
      description: 'The project deletion gremlins are on strike! Please try again in a moment.'
    }
  },
  invalidPassword: {
    error: {
      title: 'Empty password!',
      description: 'Please fill in the password field and try again. Like a breeze.',
    },
  },
  deleteAccount: {
    success: {
      title: 'Account deleted',
      description: 'It\'s like a temporary farewell, a brief separation, a dramatic pause before the grand reunion! But hey, we\'ll keep your seat warm for a potential comeback!',
    },
    error: {
      title: 'Account wasn\'t deleted',
      description: 'Account deletion error is currently trending as the latest Internet sensation. Please try again in a moment.',
    },
  },
  uploadCsv: {
    success: {
      title: 'File uploaded successfully',
      description: 'Congratulations! Your file has successfully mastered the art of teleportation.',
    },
    error: {
      title: 'File wasn\'t uploaded',
      description: 'The file upload had a wild encounter with a mischievous tech squirrel. Try uploading it again.',
    },
  },
  exportCsv: {
    error: {
      title: 'Oops-a-daisy! Export failed.',
      description: 'The export file took a wrong turn at the digital crossroads. Please try exporting it again in a moment.',
    },
  },
};

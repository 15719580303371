import { isLowRisk, isMediumRisk, isHighRisk } from '../../utils/riskLevels';

const RiskScore = ({
  riskScore,
  longLabel
}) => {
  return (
    <>
      {isLowRisk(riskScore) &&
        <span className='risk-score'>{longLabel ? ('low risk') : ('low')}</span>
      }
      {isMediumRisk(riskScore) &&
        <span className='risk-score risk-medium'>{longLabel ? ('medium risk') : ('medium')}</span>
      }
      {isHighRisk(riskScore) &&
        <span className='risk-score risk-high'>{longLabel ? ('high risk') : ('high')}</span>
      }
    </>
  );
};

export default RiskScore;

import { useConfig as useReactConfig } from './_ReactConfig';
import useFeatureFlag from './_FeatureFlags';
import useRoleConfig from './_RoleConfig';

const useCombinedConfig = (role) => {
  const { getConfig: getReactConfig } = useReactConfig();
  const { getFeatureFlag } = useFeatureFlag();
  const { getRoleConfig } = useRoleConfig(role);

  const getCombinedConfig = (key) => {
    const byFeatureFlag = getFeatureFlag(key);
    const byRole = getRoleConfig(key);

    if (key === 'deposits') {
      return !!byFeatureFlag;
    }

    if (key === 'explorer' || key === 'aml_quick_check') {
      if (getReactConfig(key) === false) {
        return false;
      }
    }

    if (byFeatureFlag === undefined && byRole === undefined) {
      return getReactConfig(key);
    }
    if (byFeatureFlag === undefined) {
      return byRole;
    }
    if (byRole === undefined) {
      return byFeatureFlag;
    }
    return byRole && byFeatureFlag;
  };

  return { getCombinedConfig };
};

export default useCombinedConfig;

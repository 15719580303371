import Checkbox from '../checkbox/Checkbox';

export const CheckboxGroup = ({ checkboxes, onChange, selectAll, isAllSelected }) => {
  return (
    <div className='checkbox-group'>
      <div>
        <Checkbox label='Select All' onChange={selectAll} checked={isAllSelected} />
      </div>
      {checkboxes.map((checkbox, index) => (
        <div key={index}>
          <Checkbox label={checkbox.label} checked={checkbox.checked} onChange={onChange(index)}/>
        </div>
      ))}
    </div>
  );
};

import { useNavigate } from 'react-router-dom';
import PageMainContainer from 'components/page-main-container/PageMainContainer';
import redLines from 'assets/images/NFT/red-lines.jpg';
import FormBottomLink from 'components/form/form-bottom-link/FormBottomLink';
import MainButton from 'components/buttons/MainButton';
import appRoutes from 'routes/routes';
import FormHeader from 'components/form/form-header/FormHeader';
import { ReactComponent as BoltGreen } from 'assets/images/svg/boltGreenIcon.svg';

function PageNotFound() {
  const navigate = useNavigate();
  const { home, external } = appRoutes;

  return (
    <>
      <PageMainContainer nftBgImg={redLines}>
        <div className='content'>
          <FormHeader
            title='404 Page Not Found'
            titleText="The page you are looking for doesn't exist or has been removed."
          />
          <MainButton
            variant='button-black'
            label='BACK TO HOME'
            onClick={() => navigate(home.dashboard)}
            icon={<BoltGreen />}
          >
          </MainButton>
          <FormBottomLink
            linkContent='Need help? Contact support →'
            linkPath={external.contact}
            externalLink={true}
          />
        </div>
      </PageMainContainer>
    </>
  );
}

export default PageNotFound;

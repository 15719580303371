import { endpoints } from '../../services/api/axios';
import axios from 'axios';
import { getPreferredCurrency } from './utils';

export const loadPlanData = async () => {
  const { BILLING_URL } = endpoints;
  const billingDataResponse = await axios.get(`${BILLING_URL}/products`);
  const planData = billingDataResponse?.data?.products;
  if (!planData) {
    return [];
  }

  // If the user is not subscribed, we consider them to be subscribed to the Free plan, so we modify the response
  let current = planData.find((plan) => plan.current);
  if (!current) {
    current = planData.find((plan) => plan.free);
    if (current) {
      current.prices = ['month', 'year'].map((period) => (
        {
          currency: getPreferredCurrency(planData),
          period,
          price: 0
        }
      ));
      current.current = true;
    }
  }

  return planData;
};

export const getParsedPlanData = async () => {
  const plans = await loadPlanData();
  if (!plans) {
    throw new Error('Failed to load plan data');
  }

  const assignTypeOrContactOnly = (plan, type) => ({
    ...plan,
    type: plan.contact_only ? 'contact_only' : type,
  });

  const currentPlanIdx = plans.findIndex((p) => p.current);
  if (currentPlanIdx === -1) {
    return plans.map((p) => assignTypeOrContactOnly(p, 'select'));
  }
  return plans.map((p, idx) => {
    let planType = 'downgrade';
    if (idx === currentPlanIdx) {
      planType = 'current';
    } else if (idx > currentPlanIdx) {
      planType = 'upgrade';
    }
    return assignTypeOrContactOnly(p, planType);
  });
};

import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { axiosErrorHandler, contentful, getStaticPath } from 'services/api/axios';
import appRoutes from 'routes/routes';
import { useNavigate } from 'react-router-dom';

const OnboardingArticle = () => {
  const { home } = appRoutes;
  const navigate = useNavigate();
  const [articleData, setArticleData] = useState(null);
  const { ACCESS_TOKEN, SPACE_ID, ENTRY_ONBOARDING } = contentful;

  const getContenfulData = async() => {
    try {
      const { data } = await axios.get(
        getStaticPath('CONTENTFUL_URL', `${SPACE_ID}/environments/master/entries/${ENTRY_ONBOARDING}?access_token=${ACCESS_TOKEN}`),
      );
      const articleText = data.fields.articleText.content.map((element) => {
        return {
          nodeType: element.nodeType,
          value: element.content[0].value
        };
      });
      const image = data.fields.articleHeroImage.sys.id;
      const articleImage = await axios.get(
        getStaticPath('CONTENTFUL_URL', `${SPACE_ID}/environments/master/assets/${image}?access_token=${ACCESS_TOKEN}`),
      );
      const ContentfulData = {
        ...{ articleText: articleText },
        ...{ articleImage: articleImage.data.fields.file },
        ...{ headline: data.fields.bannerHeadline },
        ...{ label: data.fields.bannerLabel }
      };
      setArticleData(ContentfulData);
    } catch (error) {
      toast.error(axiosErrorHandler(error));
    }
  };
  const goBack = () => {
    navigate(home.dashboard);
  };
  useEffect(() => {
    getContenfulData();
  }, []);

  return (
    <div className='page-content-container article'>
      <div className='header'>
        <div onClick={goBack} className='header-back-icon'>←</div>
        <div className='header-title'>
          <span>Back</span>
        </div>
      </div>
      <div className='article-card'>
        <div className='article-header'>
          <p className='baner-description-header'>{articleData?.label}</p>
          <p className='baner-description-title'>{articleData?.headline}</p>
        </div>
        <div>
          <img src={articleData?.articleImage?.url} className='article-image' alt='article'/>
        </div>
        <div className='article-text-container'>
          {articleData?.articleText.map((text, index) => (
            <div key={index} className={`${text.nodeType} article-text`}>
              {text.value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OnboardingArticle;

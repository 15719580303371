import { WIDGETS_ENUM } from 'utils/widgets-helper';
import ChartWrapper from 'components/charts/chart-wrapper/ChartWrapper';
import CustomLineChart from 'components/charts/line-chart/LineChart';
import { useContext } from 'react';
import { WidgetContext } from 'context/widget/WidgetContext';

const RiskScoreGaussWidget = () => {
  const { parsedWidgets } = useContext(WidgetContext);
  const riskScoreGaussWidget = parsedWidgets.find(widget => widget.name === WIDGETS_ENUM.RISK_SCORE_GAUSS);

  if (!riskScoreGaussWidget) {
    return null;
  }

  const sortedRiskScoreGaussWidget = riskScoreGaussWidget.data.sort((a, b) => {
    if (a.risk_range < b.risk_range) return -1;
    if (a.risk_range > b.risk_range) return 1;
    return 0;
  });

  const parsedRiskScoreGaussWidget = { ...riskScoreGaussWidget, data: sortedRiskScoreGaussWidget };


  return (
    <ChartWrapper
      title='Clients with associated risk'
      name={WIDGETS_ENUM.RISK_SCORE_GAUSS}
      period={parsedRiskScoreGaussWidget.timeframe}
      tooltipContent='Average risk score of your client base'
      withoutPeriod
    >
      <CustomLineChart
        widget={parsedRiskScoreGaussWidget}
        rangeKey='risk_range'
        countKey='users_count'
      />
    </ChartWrapper>
  );
};

export default RiskScoreGaussWidget;

import { useEffect } from 'react';

export const useOutsideClick = (callback) => {
  useEffect(() => {
    document.addEventListener('click', callback, true);
    return () => {
      document.removeEventListener('click', callback, true);
    };
  }, []);
};

import RiskScore from 'components/risk-score/RiskScore.jsx';
import { getTimeAgo } from 'utils/time-difference-counter';
import UserProvider from 'pages/single-project-users/UserProvider.jsx';
import UserSocials from './UserSocials';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useMemo, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import handleApiError from '../../services/ErrorHandleUtil';
import { endpoints } from '../../services/api/axios';
import { UserContext } from '../../context/user/UserContext';

const UsersTableRow = ({ user, providers }) => {
  const navigate = useNavigate();
  const { getConfig } = useContext(UserContext);
  const { id } = useParams(); // project_id
  const { project } = useOutletContext();
  const [balance, setBalance] = useState(undefined);
  const [userJWT, setUserJWT] = useState(undefined);
  const userCountry = useMemo(() => {
    const splitted = user.country.split(',');
    if (splitted.length > 1) {
      return splitted[1].trim();
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${endpoints.APIAUTH_URL}/user/${id}/${user.id}`);
        setUserJWT(response.data.token);
      } catch (error) {
        setUserJWT(undefined);
        handleApiError('getting user jwt', error);
      }
    })();
  }, [id, user]);

  useEffect(() => {
    const controller = new AbortController();
    const loadBalance = async () => {
      if (!userJWT) {
        return;
      }
      try {
        const response = await axios.get(
          `/v1/aggregate/balance?currency=${project.currency}`,
          {
            signal: controller.signal,
            baseURL: getConfig('api_url'),
            headers: {
              'Authorization': 'Bearer ' + userJWT,
            }
          }
        );
        if (response?.data?.balance_sum) {
          const sum = response.data.balance_sum.reduce((acc, x) => {
            if(x?.converted_value !== undefined) {
              return acc + x.converted_value;
            }
            return acc;
          }, 0);
          setBalance(sum.toFixed(2));
        }
      } catch (error) {
        if (error.message !== 'canceled') {
          handleApiError('getting balance', error.message);
        }
      }
    };
    loadBalance();

    return () => {
      controller.abort();
    };
  }, [userJWT]);

  const handleNavigation = (userId) => {
    navigate(`customer/${userId}`);
  };

  return (
    <div className='users-table-row'>
      <span className='users-table-name' onClick={() => handleNavigation(user.id)}>
        {user.name}
      </span>
      <span className='users-table-providers-container'>
        {Object.entries(user.providers).map(([key, value]) => (
          <span key={key} className='provider'>
            <UserProvider
              providerAddresses={value}
              provider={key}
              userId={user.id}
              providerIcon={Array.isArray(providers) && providers.find(provider => provider.account === key)}
            />
          </span>
        ))}
      </span>
      <span className='users-table-font-secondary users-table-risk-score'>
        <RiskScore riskScore={user.risk} />
        {user.risk}/100
      </span>
      <span className='users-table-font-secondary'>
        {balance === undefined ? 'loading' : `${project.currency} ${balance}`}
      </span>
      <span className='users-table-font-secondary'>
        {new Date(user.last_tx).getFullYear() < 1971 ? 'Never' : getTimeAgo(user.last_tx)}
      </span>
      <span className='users-socials'>
        <UserSocials socials={user.socials}/>
      </span>
      <span className='users-table-font-secondary'>{userCountry}</span>
    </div>
  );
};

export default UsersTableRow;

import CopyToClipboardTooltip from 'components/copy-to-clipboard-tooltip/CopyToClipboardTooltip';
import { iconConfig } from 'utils/socials-icon-config';

const UserSocialsIcon = ({ el }) => {

  return (
    <CopyToClipboardTooltip tooltipData={el.user} copyValue={el.user}>
      {iconConfig[el.social]}
    </CopyToClipboardTooltip>
  );
};

export default UserSocialsIcon;

export const determineChainFromTx = (tx) => {
  const onchainCurrencies = [
    'btc', 'eth', 'flow', 'ada', 'neo', 'matic', 'trx', 'celo', 'near', 'ltc', 'xlm', 'xrp', 'sol'
  ];
  const movements = tx.inputs.concat(tx.outputs).concat(tx.fee);
  const currenciesInTx = movements
    .map(movement => String(movement?.currency).toLowerCase())
    .map(currency => currency.startsWith('cex:') ? currency.split(':')[1] : currency);
  const presentOnchainCurrencies = currenciesInTx
    .filter(txCurrency => onchainCurrencies.includes(txCurrency));
  return presentOnchainCurrencies.length ? presentOnchainCurrencies[0] : undefined;
};

import { valueExists } from './common';

export const misttrackCategoryNames = {
  SANCTIONED_ENTITY: 'Sanctioned Entity',
  MALICIOUS_ADDRESS: 'Malicious Address',
  SUSPECTED_MALICIOUS_ADDRESS: 'Suspected Malicious Address',
  HIGH_RISK_TAG_ADDRESS: 'High-risk Tag Address',
  MEDIUM_RISK_TAG_ADDRESS: 'Medium-risk Tag Address',
  MIXER: 'Mixer',
  RISK_EXCHANGE: 'Risk Exchange',
  GAMBLING: 'Gambling',
  INVOLVED_THEFT_ACTIVITY: 'Involved Theft Activity',
  INVOLVED_RANSOM_ACTIVITY: 'Involved Ransom Activity',
  INVOLVED_PHISHING_ACTIVITY: 'Involved Phishing Activity',
  INTERACT_WITH_MALICIOUS_ADDRESS: 'Interact With Malicious Address',
  INTERACT_WITH_SUSPECTED_MALICIOUS_ADDRESS: 'Interact With Suspected Malicious Address',
  INTERACT_WITH_HIGH_RISK_TAG_ADDRESS: 'Interact With High-risk Tag Address',
  INTERACT_WITH_MEDIUM_RISK_TAG_ADDRESS: 'Interact With Medium-risk Tag Addresses',
};

export const misttrackCategories = Object.values(misttrackCategoryNames);

export const misttrackLabelTypeToCategory = {
  'sanctioned': misttrackCategoryNames.SANCTIONED_ENTITY,
  'malicious': misttrackCategoryNames.INTERACT_WITH_MALICIOUS_ADDRESS,
  'medium_risk': misttrackCategoryNames.INTERACT_WITH_MEDIUM_RISK_TAG_ADDRESS,
  'high_risk': misttrackCategoryNames.INTERACT_WITH_HIGH_RISK_TAG_ADDRESS,
  'suspected_malicious': misttrackCategoryNames.INTERACT_WITH_SUSPECTED_MALICIOUS_ADDRESS,
};

export const misttrackCategoryToRisk = {
  [misttrackCategoryNames.SANCTIONED_ENTITY]: 100,
  [misttrackCategoryNames.MALICIOUS_ADDRESS]: 100,
  [misttrackCategoryNames.SUSPECTED_MALICIOUS_ADDRESS]: 80,
  [misttrackCategoryNames.HIGH_RISK_TAG_ADDRESS]: 100,
  [misttrackCategoryNames.MEDIUM_RISK_TAG_ADDRESS]: 50,
  [misttrackCategoryNames.MIXER]: 50,
  [misttrackCategoryNames.RISK_EXCHANGE]: 60,
  [misttrackCategoryNames.GAMBLING]: 30,
  [misttrackCategoryNames.INVOLVED_THEFT_ACTIVITY]: 100,
  [misttrackCategoryNames.INVOLVED_RANSOM_ACTIVITY]: 100,
  [misttrackCategoryNames.INVOLVED_PHISHING_ACTIVITY]: 100,
  [misttrackCategoryNames.INTERACT_WITH_MALICIOUS_ADDRESS]: 80,
  [misttrackCategoryNames.INTERACT_WITH_SUSPECTED_MALICIOUS_ADDRESS]: 60,
  [misttrackCategoryNames.INTERACT_WITH_HIGH_RISK_TAG_ADDRESS]: 70,
  [misttrackCategoryNames.INTERACT_WITH_MEDIUM_RISK_TAG_ADDRESS]: 30,
};

export const getMisttrackCategoriesByAddress = (riskDetail) => {
  const riskLabelsByAddress = riskDetail.reduce((acc, entry) => {
    const address = (entry?.address ?? '').toLowerCase();
    if (!acc?.[address]) {
      acc[address] = [];
    }
    const category = misttrackLabelTypeToCategory?.[entry?.type];
    if (category && valueExists(address) && !acc[address].includes(category)) {
      acc[address].push(category);
    }
    if (String(entry?.label).includes('OFAC') && !acc[address].includes(misttrackCategoryNames.SANCTIONED_ENTITY)) {
      acc[address].push(misttrackCategoryNames.SANCTIONED_ENTITY);
    }
    return acc;
  }, {});
  return riskLabelsByAddress;
};

export const getRiskFromMisttrackCategories = (categories) => {
  return (categories ?? []).reduce((acc, category) => {
    return Math.max(acc, misttrackCategoryToRisk?.[category] ?? 0);
  }, 0);
};

export const getMisttrackCategories = (riskResult) => {
  const categories = riskResult?.detail_list ?? [];
  const isSanctioned = riskResult?.risk_detail?.map(entry => entry?.label).some(label => label.includes('OFAC'));
  if (isSanctioned && !categories.includes(misttrackCategoryNames.SANCTIONED_ENTITY)) {
    categories.push(misttrackCategoryNames.SANCTIONED_ENTITY);
  }
  return categories;
};

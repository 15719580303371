import CopyToClipboardTooltip from '../copy-to-clipboard-tooltip/CopyToClipboardTooltip';
import { getLabelClassByRisk } from '../../utils/riskLevels';
import { useEffect, useState } from 'react';

export const InfoLabel = ({ text }) => <div className='oneline'>
  <CopyToClipboardTooltip tooltipData={text} copyValue={text} openOnHover>
    <div className='risk-score risk-owned capitalized'>{text}</div>
  </CopyToClipboardTooltip>
</div>;

export const AlertLabel = ({ category, risk = 100 }) => {
  const [classByRisk, setClassByRisk] = useState('risk-low');
  const shortCategory = category.length > 11 ? `${category.slice(0, 11)}...` : category;

  useEffect(() => {
    setClassByRisk(getLabelClassByRisk(risk));
  }, [risk]);

  return <div className='oneline' title={category}>
    <CopyToClipboardTooltip tooltipData={category} copyValue={category} openOnHover>
      <div className={`risk-score ${classByRisk}`}>{shortCategory}</div>
    </CopyToClipboardTooltip>
  </div>;
};

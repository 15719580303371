import styles from '../../pages/customer-details/style.module.scss';
import { ReactComponent as DashIcon } from '../../assets/images/svg/dash.svg';

const Expander = ({ isExpanded, total, handleClick }) => {
  return <div onClick={handleClick} className={styles.expander}>
    <div className={`${styles.expanderDash} ${isExpanded ? styles.dashExpanded : styles.dashNotExpanded}`}>
      <DashIcon />
    </div>
    <span className={styles.expanderText}>
      {isExpanded ? 'Show less' : `Show all (${total})`}
    </span>
  </div>;
};

export default Expander;

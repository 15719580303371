import { ReactComponent as CloseIcon } from 'assets/images/svg/cross.svg';

const MainModal = ({ children, handleClose }) => {
  return (
    <div className='modal-page-container' onClick={handleClose}>
      <div className='modal-container' onClick={e => e.stopPropagation()}>
        <div className='modal-close-button' onClick={handleClose}>
          <div className='close-icon'>
            <CloseIcon />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default MainModal;

import { Link } from 'react-router-dom';

function FormHeader({ title, titleText, linkContent, linkPath }) {
  return (
    <div>
      <h1 className='heading-title'>{title}</h1>
      <p className='heading-title-text'>
        {titleText}
        {linkPath ? (<Link to={linkPath} className='heading-title-text-link'>
          {linkContent}
        </Link>) : null}
      </p>
    </div>
  );
}

export default FormHeader;

import styles from './consolidated-transactions-buttons.module.scss';
import { ReactComponent as ExportIcon } from '../../assets/images/svg/import.svg';
import { valueExists } from '../../utils/common';
import axios from 'axios';
import { getStaticPath } from '../../services/api/axios';
import { toast } from 'react-hot-toast';
import { clsx } from 'clsx';

const ConsolidatedTransactionsButtons = ({ userJWT, userId, projectId, exportAllowed = true }) => {
  const handleExportTxs = async () => {
    if (!valueExists(userJWT) || !exportAllowed) {
      return;
    }
    try {
      await axios.get(getStaticPath('USERLIST_URL', `${projectId}/user/${userId}/export`));
    } catch (error) {
      toast.error('Failed to export transactions. Please try again later.');
      return;
    }
    toast.success('The file should land in your email inbox shortly.');
  };

  return <div className={styles.container}>
    {userId && projectId &&
      <div
        className={clsx(styles.button, !exportAllowed && styles.disabledButton)}
        onClick={() => handleExportTxs()}
        title={exportAllowed ? 'Export transactions' : 'Only BTC and ETH can be exported'}
      >
        <div className={styles.icon}>
          <ExportIcon />
        </div>
        <div className={styles.clickableText}>
          Export transactions
        </div>
      </div>
    }
  </div>;
};

export default ConsolidatedTransactionsButtons;

export const parseNameWithSentiment = (name) => {
  if (name.length && ['+', '-'].includes(name[0])) {
    return {
      name: name.slice(1),
      isPositive: name[0] === '+',
    };
  }
  return {
    name,
    isPositive: true,
  };
};

export const maybePlural = ( baseText, isPlural ) => `${baseText}${isPlural ? 's' : ''}`;

export const frequencyToPeriod = (frequency) => {
  switch (frequency) {
  case 'annually':
    return 'year';
  case 'monthly':
    return 'month';
  case 'weekly':
    return 'week';
  default:
    return 'day';
  }
};

export const getPlanPriceByPeriod = (plan, period) => plan.prices?.find(price => price.period === period)?.price;

export const getPreferredCurrency = (plans) => (['eur'].concat(plans.map((plan) =>
  plan.prices.map((priceInfo) => priceInfo.currency)
).flat()).at(-1));

export const TIME_RANGE = {
  last: 'Last month',
  current: 'Current month',
  long: 'Last 90 days'
};

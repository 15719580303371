import { useContext, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { axiosErrorHandler } from 'services/api/axios';
import appRoutes from 'routes/routes';
import authService from 'services/auth-services/auth.service';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'context/user/UserContext';

function GoogleButton({ label }) {
  const { home } = appRoutes;
  const { loginGoogle } = authService;
  const navigate = useNavigate();
  const { setAuthorized } = useContext(UserContext);

  const handleGoogle = async (response) => {
    try {
      await loginGoogle(response.credential);
      navigate(home.dashboard);
      setAuthorized(true);
    } catch (error) {
      toast.error(axiosErrorHandler(error));
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.onload = () => {
      // eslint-disable-next-line no-undef
      google.accounts.id.initialize({
        client_id: '309156393534-kqb4nfcgeuq26tbkra4h5memcihsb1nt.apps.googleusercontent.com',
        callback: handleGoogle,
      });

      // eslint-disable-next-line no-undef
      google.accounts.id.renderButton(document.getElementById('signUpDiv'), {
        type: 'standard',
        theme: 'outline',
        size: 'large',
        text: label,
        shape: 'rectangular',
        width: '400px',
        logo_alignment: 'center',
        locale: 'en'
      });
    };
    document.body.appendChild(script);
  }, []);

  return <div id='signUpDiv'/>;
}

export default GoogleButton;

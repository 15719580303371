import MainButton from 'components/buttons/MainButton';
import { ReactComponent as BoltGreen } from 'assets/images/svg/boltGreenIcon.svg';
import { ReactComponent as WidgetsIcon } from 'assets/images/svg/widgets.svg';
import { useParams, useNavigate } from 'react-router-dom';

const AnalyticsNoWidgets = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className='page-content-centered'>
      <div className='content-wrapper'>
        <div className='analytics-icon-container'>
          <WidgetsIcon/>
        </div>
        <p className='analytics-title'>It&apos;s a bit awkward, but it looks like the last widget is also missing.</p>
        <p className='analytics-details'>
          Please head over to the widgets settings to make them visible and see your analytics data and insights.
        </p>
      </div>
      <div className='button-wrapper'>
        <MainButton
          variant='button-purple'
          label='WIDGETS SETTINGS'
          icon={<BoltGreen />}
          onClick={() => navigate(`/my-projects/${id}/widgets`)}
        />
      </div>
    </div>
  );
};

export default AnalyticsNoWidgets;

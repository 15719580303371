import styles from './transaction-risk-categories.module.scss';
import CustomerDetailsBox from '../../pages/customer-details/CustomerDetailsBox';
import { ReactComponent as NineDotsIcon } from '../../assets/images/svg/nine-dots.svg';
import CategoryInfo from './CategoryInfo';
import { useMemo } from 'react';
import { misttrackCategories, misttrackCategoryNames, misttrackLabelTypeToCategory } from '../../utils/misttrack';


const TransactionRiskCategories = ({ riskResult }) => {
  const interactionDetails = useMemo(() => {
    const riskDetails = riskResult?.risk_detail ?? [];
    const labelByCategory = riskDetails.toReversed().reduce((acc, entry) => {
      if (entry?.type && entry?.label && entry.type in misttrackLabelTypeToCategory) {
        acc[misttrackLabelTypeToCategory[entry.type]] = entry.label;
      }
      return acc;
    }, {});
    const categories = riskResult?.detail_list ?? [];
    const customLabels = {};
    categories.forEach(category => customLabels[category] = 'Interaction');
    Object.entries(labelByCategory).forEach(([category, label]) => customLabels[category] = label);
    const isOfacSanctioned = riskDetails.map(entry => entry?.label).some(label => label.includes('OFAC'));
    if (isOfacSanctioned && !categories.includes(misttrackCategoryNames.SANCTIONED_ENTITY)) {
      categories.push(misttrackCategoryNames.SANCTIONED_ENTITY);
    }
    if (isOfacSanctioned) {
      customLabels[misttrackCategoryNames.SANCTIONED_ENTITY] = 'Interaction (OFAC)';
      customLabels[misttrackCategoryNames.INTERACT_WITH_MALICIOUS_ADDRESS] = 'Interaction';
    }
    return customLabels;
  }, [riskResult]);

  const interactedWithCategories = useMemo(() => Object.keys(interactionDetails), [riskResult]);

  const determineInteraction = (category) => {
    const customLabel = interactionDetails?.[category];
    if (customLabel) {
      return {
        text: customLabel,
        isSafe: false,
      };
    }
    return {
      text: 'No interaction',
      isSafe: true,
    };
  };
  const interactedWithCategoriesSorted = misttrackCategories.filter(category => interactedWithCategories.includes(category));
  const notInteractedWithCategoriesSorted = misttrackCategories.filter(category => !interactedWithCategories.includes(category));
  // There are 2 columns, which are filled in a row-fashion.
  // We want to have interacted-with items in the first column, hence this complicated ordering.
  const orderedCategories = useMemo(() => {
    const ordered = [];
    interactedWithCategoriesSorted.forEach((category, index) => ordered[index * 2] = category);
    notInteractedWithCategoriesSorted.forEach((category, index) => ordered[index * 2 + 1] = category);
    return ordered.filter(category => category);
  }, [interactedWithCategories]);

  return <CustomerDetailsBox title='Transactions risk categories' icon={<NineDotsIcon />}>
    <div className={styles.categoriesContainer}>
      {orderedCategories.map((category, index) =>
        <CategoryInfo
          title={category}
          valueInfo={determineInteraction(category)}
          withBorder={index < misttrackCategories.length - 2}
          key={category}
        />
      )}
    </div>
  </CustomerDetailsBox>;
};

export default TransactionRiskCategories;

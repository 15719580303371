import { useEffect, useState } from 'react';
import apiTokenService from 'services/api-tokens-services';
import { toast } from 'react-hot-toast';
import ApiKeyListSubheader from 'pages/single-project-api-keys/ApiKeyListSubheader';
import { useParams } from 'react-router-dom';
import useNotification from 'components/notification/UseNotification';
import ApiKeysPublicListTable from './ApiKeysPublicListTable';

const ApiKeysPublic = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [publicTokensList, setActivePublicTokensList] = useState([]);
  const [allTokensList, setAllTokensList] = useState([]);
  const { createPublicToken, getPublicTokens } = apiTokenService;
  const { id } = useParams();
  const showNotification = useNotification();

  const generateNewKey = async () => {
    const defaultName = `Key ${allTokensList?.length + 1}`;
    setIsLoading(true);
    try {
      const response = await createPublicToken(id, defaultName);
      showNotification('createPublicApiKey', 'success');
      setActivePublicTokensList(prevState => [...prevState, response]);
      setAllTokensList(prevState => [...prevState, response]);
    } catch (error) {
      toast.error(error.message ?? 'Something went wrong...');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getTokens = async (id) => {
      try {
        const tokens = await getPublicTokens(id);
        setActivePublicTokensList(tokens.filter(item => !item.disabled));
        setAllTokensList(tokens);
      } catch (error) {
        toast.error('Something went wrong...');
      }
    };

    getTokens(id);
  }, []);

  return (
    <>
      <ApiKeyListSubheader handleCreate={generateNewKey}/>
      <div className='api-keys-main-container'>
        <ApiKeysPublicListTable
          loading={isLoading}
          setLoading={setIsLoading}
          list={publicTokensList}
          setList={setActivePublicTokensList}
          generateNewApiKey={generateNewKey}/>
      </div>
    </>
  );
};

export default ApiKeysPublic;

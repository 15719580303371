export const DEFAULT_CURRENCY = 'USD';

export const SUPPORTED_CURRENCIES = [
  {
    label: '$ USD',
    ticker: 'USD',
    symbol: '$',
  },
  {
    label: '€ EUR',
    ticker: 'EUR',
    symbol: '€',
  },
  {
    label: 'Kč CZK',
    ticker: 'CZK',
    symbol: 'Kč',
  },
  {
    label: '£ GBP',
    ticker: 'GBP',
    symbol: '£',
  }
];

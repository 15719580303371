import styles from './address-input.module.scss';
import Select from '../select/Select';
import MainButton from '../buttons/MainButton';
import { useContext, useEffect, useState } from 'react';
import { getPossibleChainsByAddressPrefix } from '../../utils/addresses';
import { useOutletContext } from 'react-router-dom';
import { getTemporaryJwt } from '../../services/temporary-user/temporary-user-service';
import toast from 'react-hot-toast';
import axios from 'axios';
import { UserContext } from '../../context/user/UserContext';
import { ReactComponent as BoltGreen } from '../../assets/images/svg/boltGreenIcon.svg';

const AddressInput = ({ connectedAddress, setConnectedAddress, currentChain, setCurrentChain, handleConnect, chainFilter, buttonLabel = 'SUBMIT' }) => {
  const { explorerProject } = useOutletContext();
  const { getConfig } = useContext(UserContext);

  const [possibleChains, setPossibleChains] = useState(getPossibleChainsByAddressPrefix('', chainFilter));
  const [userSelectedChain, setUserSelectedChain] = useState();
  const [hints, setHints] = useState([]);
  const [showHint, setShowHint] = useState(false);
  const [hintsLoading, setHintsLoading] = useState(false);

  const handleChainChange = (ticker) => {
    setUserSelectedChain(ticker);
    setCurrentChain(ticker);
  };

  const handleAddressChange = (e) => {
    setShowHint(true);
    const newAddress = e.target.value.trim();
    setConnectedAddress(newAddress);
  };

  const handleAutocompleteSelection = (address) => {
    setConnectedAddress(address);
    setShowHint(false);
  };

  useEffect(
    () => {
      const newPossibleChains = getPossibleChainsByAddressPrefix(connectedAddress, chainFilter)
        .map(chainInfo => chainInfo.ticker);
      setPossibleChains(newPossibleChains);
      if (!newPossibleChains.includes(userSelectedChain)) {
        setUserSelectedChain(undefined);
        setCurrentChain(newPossibleChains?.[0]);
      } else {
        setCurrentChain(userSelectedChain);
      }
    },
    [connectedAddress]
  );

  // Address hint
  useEffect(() => {
    if(!explorerProject) {
      return;
    }

    const controller = new AbortController();
    const loadHints = setTimeout(async () => {
      const userJwt = await getTemporaryJwt(explorerProject.id);

      if(connectedAddress.length > 7) {
        try {
          setHintsLoading(true);
          const { data } = await axios.get('/v1/addressname/hint',
            {
              baseURL: getConfig('api_url'),
              headers: {
                'Authorization': `Bearer ${userJwt}`,
              },
              params:{
                prefix: connectedAddress
              },
              signal: controller.signal
            }
          );
          setHintsLoading(false);
          setHints(data);
        }
        catch (error) {
          if (error.code !== 'ERR_CANCELED') {
            toast('problem getting hint');
          }
        }
      } else {
        setHints([]);
      }

    }, 300);

    return () => {
      controller.abort();
      clearTimeout(loadHints);
    };
  }, [connectedAddress, explorerProject]);

  return <div className={styles.explorerForm}>
    {!getConfig('is_cs') && <Select
      options={possibleChains.map(ticker => ({ label: ticker }))}
      onOptionClick={option => handleChainChange(option.label)}
      value={currentChain?.toUpperCase() ?? 'Invalid chain prefix'}
      disabled={!currentChain}
      uppercaseOptions
    />}
    <div className={styles.addressInputOuter}>
      <input
        name='addressInput'
        type='text'
        className={styles.addressInputInner}
        placeholder='Paste address to explore account'
        value={connectedAddress}
        onChange={handleAddressChange}
        autoComplete='off'
        onBlur={() => setShowHint(false)}
        onFocus={() => setShowHint(true)}
      />
      {showHint && connectedAddress.length > 7 && (
        <div className={styles.autocompleteItems}>
          {hintsLoading && <div>Loading...</div>}
          {!hintsLoading && hints.map(hint =>
            <div
              onMouseDown={e => e.preventDefault()} // Prevents input blur
              onClick={() => handleAutocompleteSelection(hint)}
              key={hint}
            >
              {hint}
            </div>
          )}
        </div>
      )}
    </div>
    <MainButton
      variant='button-purple'
      label={buttonLabel}
      icon={<BoltGreen/>}
      onClick={() => handleConnect()}
      disabled={!connectedAddress}
      extraClasses={styles.connectButton}
    />
  </div>;
};

export default AddressInput;

import { ClipLoader } from 'react-spinners';

const LinkButtonWithIcon = ({ disabled, icon, loading, onClick, label }) => {
  return (
    <button className='link-button' disabled={disabled || loading} onClick={onClick}>
      {loading ? (
        <ClipLoader color='#6dff96' size={20} />
      ) : (
        <div className='link-button-icon'>{icon}</div>
      )}
      {label}
    </button>
  );
};

export default LinkButtonWithIcon;

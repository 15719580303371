import styles from './style.module.scss';
import CustomerDetailsBox from 'pages/customer-details/CustomerDetailsBox';
import { ReactComponent as EarthIcon } from 'assets/images/svg/earth-icon.svg';
import { useEffect, useState, useMemo, useRef } from 'react';
import ConsolidatedTransactionsLegend from './ConsolidatedTransactionsLegend';
import { clsx } from 'clsx';
import ConsolidatedTransactionsTableRow from './ConsolidatedTransactionsTableRow';
import ConsolidatedTransactionsTableRowConcise from './ConsolidatedTransactionsTableRowConcise';
import ConsolidatedTransactionsButtons from '../../components/consolidated-transactions/ConsolidatedTransactionsButtons';
import ConsolidatedTransactionsFilters, { filterTypes } from '../../components/filters/ConsolidatedTransactionsFilters';
import { valueExists } from '../../utils/common';
import useLoadNextTxsPage from '../../services/chain-data-loaders/blockmate-api';


const TX_TYPES = [
  {
    key: 'crypto deposit',
    label: 'Crypto deposit',
  },
  {
    key: 'crypto withdrawal',
    label: 'Crypto withdrawal',
  },
  {
    key: 'fiat deposit',
    label: 'Fiat deposit',
  },
  {
    key: 'fiat withdrawal',
    label: 'Fiat withdrawal',
  },
  {
    key: 'transfer',
    label: 'Transfer',
  },
];

const DIGITAL_ASSETS = [
  {
    key: 'BTC',
    label: 'Bitcoin',
  },
  {
    key: 'ETH',
    label: 'Ethereum',
  },
  {
    key: 'TRX',
    label: 'Tron',
  },
];

const TX_RISKS = [
  {
    key: 'low',
    label: 'Low',
  },
  {
    key: 'medium',
    label: 'Medium',
  },
  {
    key: 'high',
    label: 'High',
  },
];

const ConsolidatedTransactions = ({
  userJWT,
  customerName,
  balanceData,
  concise = false,
  currency = 'USD',
  userId,
  projectId,
  withFilters = false,
  txsLoading,
  setTxsLoading = () => {},
  useMisttrackData = false,
  hiddenFilters = [],
  externalTxsPageLoaders = {},
  pageSize = 50,
}) => {
  const [loadedAll, setLoadedAll] = useState(false);
  const [txsData, setTxsData] = useState([]);
  const [loadingError, setLoadingError] = useState('');
  const [accountIdToInfoMap, setAccountIdToInfoMap] = useState({});
  const [expandedRowIndex, setExpandedRowIndex] = useState(); // Only for concise table
  const [accountsForFilter, setAccountsForFilter] = useState([]);
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  const internalTxsLoader = useLoadNextTxsPage({ userJWT, useMisttrackData, pageSize, setLoadingError });
  const allTxsLoaders = useMemo(() => {
    return {
      'internal': internalTxsLoader,
      ...externalTxsPageLoaders,
    };
  }, [externalTxsPageLoaders]);

  const [txTypesForFilter, setTxTypesForFilter] = useState(
    TX_TYPES.map(txType => ({ ...txType, checked: false }))
  );
  const [digitalAssetsForFilter, setDigitalAssetsForFilter] = useState(
    DIGITAL_ASSETS.map(asset => ({ ...asset, checked: false }))
  );
  const [txRisksForFilter, setTxRisksForFilter] = useState(
    TX_RISKS.map(txRisk => ({ ...txRisk, checked: false }))
  );
  const [priceForFilter, setPriceForFilter] = useState({ min: '', max: '' });
  const supportedFilters = useMemo(
    () => {
      return Object.entries(externalTxsPageLoaders).map(([_, loader]) => loader.getAllowedFilters())
        .reduce(
          (remaining, filters) => {
            return remaining.filter(supportedFilter => filters.includes(supportedFilter));
          },
          internalTxsLoader.getAllowedFilters()
        );
    }, [externalTxsPageLoaders]
  );
  const unsupportedFilters = useMemo(() => {
    const allFilters = Object.entries(filterTypes).map(([_, filterType]) => filterType);
    return allFilters.filter(filterType => !supportedFilters.includes(filterType));
  }, [supportedFilters]);

  const externalSourceExists = useMemo(() => {
    return Object.entries(externalTxsPageLoaders).length > 0;
  }, [externalTxsPageLoaders]);

  const initTxsPageBuffers = () => {
    const buffers = {};
    for (const [key, value] of Object.entries(allTxsLoaders)) {
      buffers[key] = {
        pointer: 0,
        data: [],
      };
    }
    return buffers;
  };

  const txsPageBuffers = useRef(initTxsPageBuffers());

  const buildFilterParam = (filterArray) => {
    return (filterArray ?? []).filter(option => option.checked).map(option => option.key).join(',');
  };

  const accountsFilterParam = useMemo(
    () => buildFilterParam(accountsForFilter),
    [JSON.stringify(accountsForFilter)]
  );
  const txTypesFilterParam = useMemo(
    () => buildFilterParam(txTypesForFilter),
    [JSON.stringify(txTypesForFilter)]
  );
  const digitalAssetsFilterParam = useMemo(
    () => buildFilterParam(digitalAssetsForFilter),
    [JSON.stringify(digitalAssetsForFilter)]
  );
  const txRisksFilterParam = useMemo(
    () => buildFilterParam(txRisksForFilter),
    [JSON.stringify(txRisksForFilter)]
  );
  const minPriceFilterParam = valueExists(priceForFilter.min) ? priceForFilter.min : undefined;
  const maxPriceFilterParam = valueExists(priceForFilter.max) ? priceForFilter.max : undefined;
  const filters = useMemo(() => ({
    accountsFilterParam,
    txTypesFilterParam,
    digitalAssetsFilterParam,
    txRisksFilterParam,
    minPriceFilterParam,
    maxPriceFilterParam,
  }), [accountsFilterParam, txTypesFilterParam, digitalAssetsFilterParam, txRisksFilterParam, minPriceFilterParam, maxPriceFilterParam]);

  useEffect(() => {
    const load = async () => {
      if (userJWT !== undefined && !initialLoadDone) {
        await loadNextTxsPage(filters);
        setInitialLoadDone(true);
      }
    };
    load().catch(console.error);
  }, [userJWT]);

  useEffect(() => {
    if (balanceData !== undefined) {
      const idToInfo = {};
      if (balanceData.accounts) {
        const initialAccountsCheckboxes = balanceData.accounts.map(account => ({
          key: account.account_id,
          label: account.description,
          checked: false
        }));
        setAccountsForFilter(initialAccountsCheckboxes);
        balanceData.accounts.forEach(account => {
          idToInfo[account.account_id] = {
            address: account.wallet,
            description: account.description,
            provider:  account.url.split('/')[1],
            providerType: account.url.split('/')[0]
          };
        });
      }
      setAccountIdToInfoMap(idToInfo);
    } else {
      setAccountIdToInfoMap({});
    }
  }, [balanceData]);

  const handleApplyFilters = () => {
    setTxsData([]);
    setLoadedAll(false);
    txsPageBuffers.current = initTxsPageBuffers();
    for (const [_, loader] of Object.entries(allTxsLoaders)) {
      loader.reset();
    }
    loadNextTxsPage(true);
  };

  const loadNextTxsPage = async (ignoreLoadedAll = false) => {
    if (!ignoreLoadedAll && loadedAll) {
      return;
    }

    setTxsLoading(true);

    // We have n data-sources, each providing us with a possibly infinitely long stream of chronologically sorted txs.
    // These n data-sources need to be merged into one to construct the next page, but we can only load 1 page from each.
    // This is a generalization of an algorithm for merging 2 sorted sequences used e.g. in mergesort.
    const page = [];
    while (page.length < pageSize) {
      let newestFoundTx;
      for (const [loaderId, loader] of Object.entries(allTxsLoaders)) {
        let thisLoaderNextTx;
        // const { pointer, data } = txsPageBuffers[loaderId];
        if (txsPageBuffers.current[loaderId].pointer >= txsPageBuffers.current[loaderId].data.length) {
          if (loader.hasNextPage()) {
            const nextPage = await loader.loadNextPage(filters);
            txsPageBuffers.current[loaderId] = {
              data: nextPage,
              pointer: 0,
            };
          }
          else {
            continue;
          }
        }
        if (txsPageBuffers.current[loaderId].pointer < txsPageBuffers.current[loaderId].data.length) {
          thisLoaderNextTx = txsPageBuffers.current[loaderId].data[txsPageBuffers.current[loaderId].pointer];
        }
        if (thisLoaderNextTx) {
          if (!newestFoundTx || (new Date(thisLoaderNextTx.created_at) > new Date(newestFoundTx.data.created_at))) {
            newestFoundTx = {
              data: thisLoaderNextTx,
              loaderId,
            };
          }
        }
      }
      if (newestFoundTx) {
        page.push(newestFoundTx.data);
        txsPageBuffers.current[newestFoundTx.loaderId].pointer += 1;
      } else {
        setLoadedAll(true);
        break;
      }
    }

    setTxsData(prevTxsData => [...prevTxsData, ...page]);
    setTimeout(() => {
      setTxsLoading(false);
    }, 0);
  };

  return (
    <CustomerDetailsBox
      title='Consolidated transactions'
      icon={<EarthIcon/>}
      legend={!concise && <ConsolidatedTransactionsLegend />}
      buttons={concise && <ConsolidatedTransactionsButtons
        userId={userId}
        projectId={projectId}
        userJWT={userJWT}
        exportAllowed={!externalSourceExists}
      />}
      concise={concise}
    >
      {withFilters && <ConsolidatedTransactionsFilters
        accounts={accountsForFilter}
        setAccounts={setAccountsForFilter}
        types={txTypesForFilter}
        setTypes={setTxTypesForFilter}
        assets={digitalAssetsForFilter}
        setAssets={setDigitalAssetsForFilter}
        risks={txRisksForFilter}
        setRisks={setTxRisksForFilter}
        price={priceForFilter}
        setPrice={setPriceForFilter}
        handleApplyFilters={handleApplyFilters}
        disabled={txsLoading}
        currency={currency}
        hiddenFilters={[...hiddenFilters, ...unsupportedFilters]}
      />}
      {concise
        ? <div className={styles.consolidatedTransactions}>
          <div className={clsx(styles.tableHeader, styles.tableHeaderConcise)}>
            <span></span>
            <span>Date and Time</span>
            <span>Account</span>
            <span>Type</span>
            <span>Transfer</span>
            <span>Sum</span>
            <span>Risk score</span>
          </div>
          <div className={styles.tableBody}>
            {txsData.map((transaction, n) => (
              <div key={n}>
                <ConsolidatedTransactionsTableRowConcise
                  data={transaction}
                  accountInfo={accountIdToInfoMap[transaction.account_id]}
                  ownMovementsLabel={customerName}
                  otherRowIsExpanded={expandedRowIndex !== n}
                  onExpand={() => setExpandedRowIndex(n)}
                  currency={currency}
                  riskLabelsOnly={useMisttrackData}
                />
                <div className={styles.rowDivider}></div>
              </div>
            ))}
            {loadingError && <div className={styles.infoMessageContainer}>
              <div className={styles.infoMessage}>
                {loadingError}
              </div>
            </div>}
            {(txsData ?? []).length === 0 && initialLoadDone && !txsLoading && !loadingError && <div className={styles.infoMessageContainer}>
              <div className={styles.infoMessage}>
                There are no transactions to display.
              </div>
            </div>}
          </div>
        </div>
        : <div className={styles.consolidatedTransactions}>
          <div className={styles.tableHeader}>
            <span>Date and Time</span>
            <span>Tx hash</span>
            <span>Type</span>
            <span>From</span>
            <span>To</span>
            <span>Risk Score</span>
            <span>Tx Category</span>
          </div>
          <div className={styles.tableBody}>
            {txsData.map((transaction, n) => (
              <ConsolidatedTransactionsTableRow
                key={n}
                data={transaction}
                ownMovementsLabel={customerName}
                accountInfo={accountIdToInfoMap[transaction.account_id]}
              />
            ))}
          </div>
        </div>
      }
      {!loadedAll && <div className={styles.loadMoreContainer} >
        {(!initialLoadDone || txsLoading) && <span className={styles.loadingText}>Loading...</span>}
        {!txsLoading && initialLoadDone && !loadedAll &&
          <span className={styles.loadMoreText} onClick={() => loadNextTxsPage()}>
            Load more
          </span>
        }
      </div>}
    </CustomerDetailsBox>
  );
};

export default ConsolidatedTransactions;

import * as Yup from 'yup';

export const signUpSchema = Yup.object().shape({
  email: Yup.string().email().required('Please enter Email'),
  password: Yup.string()
    .min(8, 'Password must be min 8 characters long')
    .max(64, 'Password must be max 64 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol')
    .required('Please enter Password'),
  firstName: Yup.string()
    .required('Please enter first name'),
  lastName: Yup.string()
    .required('Please enter last name'),
  companyName: Yup.string()
    .required('Please enter company name'),
  phoneNumber: Yup.string()
    .matches(/^\+\d{1,3}[0-9]{7,11}$/, 'Phone number must start with a country code (+) and be correct length')
    .required('Please enter phone number with country code'),
  country: Yup.string()
    .required('Please enter your country'),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('Please enter Email'),
  password: Yup.string().required('Please enter Password'),
  slug: Yup.string(),
});

export const resetPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required('Please enter Email'),
  slug: Yup.string(),
});

export const confirmNewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be min 8 characters long')
    .max(64, 'Password must be max 64 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol')
    .required('Please enter new Password'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Please confirm new Password'),
});

export const confirmChangeCurrentPasswordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required('Please enter Password'),
  newPassword: Yup.string()
    .min(8, 'Password must be min 8 characters long')
    .max(64, 'Password must be max 64 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol')
    .required('Please enter new Password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Please confirm new Password'),
});

export const changeProjectNameSchema = Yup.object().shape({
  newName: Yup.string()
    .max(16, 'Project name longer than 16 characters')
    .min(3, 'New project name must be min 3 characters long')
    .required('Please enter new Project Name'),
});

export const deleteProjectSchema = Yup.object().shape({
  projectName: Yup.string()
    .required('Please confirm project name'),
});

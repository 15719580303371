import styles from './category-info.module.scss';
import { clsx } from 'clsx';


const CategoryInfo = ({ title, valueInfo, withBorder }) => {
  return <div className={styles.container}>
    <div className={clsx(styles.innerContainer, withBorder && styles.entryBorder)}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={valueInfo.isSafe ? styles.safe : styles.unsafe}>
        {valueInfo.text}
      </div>
    </div>
  </div>;
};

export default CategoryInfo;

export const endpoints = {
  CLIENT_URL: '/api/v1/client',
  ADMIN_URL: '/api/v1/admin',
  PROJECT_URL: '/api/v1/project',
  STATISTICS_URL: '/api/v1/statistics',
  USERLIST_URL: '/api/v1/userlist',
  APIAUTH_URL: '/api/v1/api_auth',
  ICONS_URL: '/api/v1/providers',
  BILLING_URL: '/api/v1/billing',
  ACCOUNT_URL: '/api/v1/account',
  CONTENTFUL_URL: 'https://cdn.contentful.com/spaces',
};

export const contentful = {
  ACCESS_TOKEN: 'UkjqBK-L3EQNPBRVYPtt97UmbCr37iYnUqhs7kNVIJY',
  SPACE_ID: 'd9phh1why0cv',
  ENTRY_ONBOARDING: '4tHO0ZMonbAxrivTh1JXk2',
  ENTRY_WIDGET: '31kkSnc5GQOrmd2QvoaZgT',
  ENTRY_HORIZONTAL: '3brWZ2TbYp9RTUdw7lG1ss'
};

export const axiosErrorHandler = (error) => {
  if (error?.response.status === 401) {
    return 'Incorrect username or password';
  } else if (error?.response.status === 404) {
    const responseMsg = 'Client\'s email address doesn\'t exists';
    if (error.response.data.detail === responseMsg) {
      return 'This email address hasn\'t been registered';
    }
    return '404 Not Found';
  } else if (error?.response.status === 409) {
    return 'This email address already exists';
  } else if (error?.response.status === 403 && error?.response.data.detail === 'Email resend too soon') {
    return 'Email resend too soon. Wait and try again.';
  } else {
    return error?.message;
  }
};

export const getStaticPath = (url, path) => `${endpoints[url]}${path ? `/${path}` : ''}`;

import styles from './style.module.scss';
import TableButton from 'components/buttons/table-button/TableButton';
import { ReactComponent as EditIcon } from 'assets/images/svg/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/svg/trash.svg';

const UsersManagementTableRow = ({ rowData, handleUserEdit, handleUserDelete, setEditedUserId }) => {
  const handleEdit = () => {
    setEditedUserId(rowData.id);
    handleUserEdit(rowData.name, rowData.email, rowData.role ?? 'TODO');
  };

  const handleDelete = () => {
    setEditedUserId(rowData.id);
    handleUserDelete(rowData.name);
  };

  return (
    <div className={styles.tableRow}>
      <span className={styles.item}>{rowData.name}</span>
      <span className={styles.item}>{rowData.email}</span>
      <span className={styles.item}>{rowData.role ?? 'TODO'}</span>
      <TableButton
        label='Edit user'
        icon={<EditIcon/>}
        onClick={handleEdit}
      />
      <TableButton
        label='Delete user'
        icon={<DeleteIcon/>}
        variant={'redButton'}
        onClick={handleDelete}
      />
    </div>
  );
};

export default UsersManagementTableRow;

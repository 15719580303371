import { ReactComponent as KeyIcon } from 'assets/images/svg/key.svg';
import appRoutes from 'routes/routes';

const ApiKeyListSubheader = ({ handleCreate }) => {
  const { external } = appRoutes;

  return (
    <div className='api-key-list-subheader-container'>
      <a href={external.quickStart} target='_blank' className='api-key-list-subheader-learn' rel='noreferrer'>Learn more about API authentication →</a>
      <div className='api-key-list-subheader-create-new-key-container'>
        <div className='key-icon'>
          <KeyIcon/>
        </div>
        <span onClick={handleCreate} className='api-key-list-subheader-create-new-key'>Create new API key</span>
      </div>
    </div>
  );
};

export default ApiKeyListSubheader;

import { useEffect, useContext } from 'react';
import PageMainContainer from 'components/page-main-container/PageMainContainer';
import yellowWaves from 'assets/images/NFT/yellow-waves.jpg';
import LoginForm from 'components/AuthForms/LoginForm';
import FormBottomLink from 'components/form/form-bottom-link/FormBottomLink';
import appRoutes from 'routes/routes';
import FormHeader from 'components/form/form-header/FormHeader';
import { UserContext } from 'context/user/UserContext';
import GoogleButton from 'components/buttons/sign-in-buttons/GoogleButton';
import GithubButton from 'components/buttons/sign-in-buttons/GithubButton';

function LoginPage() {
  const { auth } = appRoutes;
  const { setEmailSent, getConfig } = useContext(UserContext);

  useEffect(() => {
    setEmailSent(false);
  }, []);

  return (
    <>
      <PageMainContainer nftBgImg={yellowWaves}>
        <div className='content'>
          <div className='form-wrapper'>
            {getConfig('is_aml_demo')
              ? <h1 className='heading-title'>Log in</h1>
              : <FormHeader
                title='Log In'
                titleText="Don't have an account?"
                linkContent='Sign up for free →'
                linkPath={auth.signUp}
              />
            }
            <LoginForm/>
            <FormBottomLink
              linkContent='Forgot password? →'
              linkPath={auth.resetPassword}
              externalLink={false}
            />
            <div className='sign-in-buttons'>
              {getConfig('show_alternative_login') && <GoogleButton label='signin_with'/>}
              {getConfig('show_alternative_login') &&  <GithubButton />}
            </div>
          </div>
        </div>
      </PageMainContainer>
    </>
  );
}

export default LoginPage;

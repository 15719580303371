import styles from './export-button.module.scss';
import MainButton from '../buttons/MainButton';
import { ReactComponent as ImportIcon } from '../../assets/images/svg/import.svg';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { getCurrentDatetimeStringSimple } from '../../utils/date-formatter';

const ExportButton = ({ disabled, data, filenameBase = 'export', useTimestamp = true }) => {

  const handleExport = () => {
    let filename = filenameBase;
    if (useTimestamp) {
      const timestamp = getCurrentDatetimeStringSimple();
      filename = `${filename}-${timestamp}`.split(' ').join('_');
    }
    const csvConfig = mkConfig({
      useKeysAsHeaders: true,
      filename,
    });

    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  return <MainButton
    extraClasses={styles.exportButton}
    variant='button-purple-light'
    onClick={() => handleExport()}
    label='Export to CSV'
    icon={<ImportIcon />}
    disabled={disabled}
  />;
};

export default ExportButton;

import Card from 'components/card/Card';
import { useState, useContext, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Link } from 'react-router-dom';
import CreateProjectModal from 'components/modals/create-project-modal/CreateProjectModal';
import Header from 'components/header/Header';
import appRoutes from 'routes/routes.js';
import Loader from 'components/loaders/loader/Loader';
import DashboardBanner from 'pages/dashboard/dashboard-banner/DashboardBanner';
import { UserContext } from 'context/user/UserContext';
import { useRoleBasedRedirect } from '../../hooks';
import ModuleCard from '../../components/card/ModuleCard';
import { ReactComponent as ListIcon } from 'assets/images/svg/list-icon.svg';
import { ReactComponent as SearchIcon } from 'assets/images/svg/search.svg';
import { ReactComponent as SearchQuickIcon } from 'assets/images/svg/search-quick.svg';
import { ReactComponent as UsersIcon } from 'assets/images/svg/users.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/svg/settings.svg';

export const NUMBER_OF_HEADER_IMAGES = 15;

const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amlDemoProcessed, setAmlDemoProcessed] = useState(false);
  const { isLimitReached, getConfig } = useContext(UserContext);
  const roleBasedRedirect = useRoleBasedRedirect();

  const { getProjectList, projects, isLoading, isBannerLoading } = useOutletContext();
  const navigate = useNavigate();
  const {
    home,
  } = appRoutes;

  const modules = [
    {
      label: 'AML Explorer',
      icon: <SearchIcon />,
      route: home.explorer,
      allowed: getConfig('explorer')
    },
    {
      label: 'AML Quick Check',
      icon: <SearchQuickIcon />,
      route: home.amlQuickCheck,
      allowed: getConfig('aml_quick_check')
    },
    {
      label: 'My projects',
      icon: <ListIcon />,
      route: home.myProjects,
      allowed: getConfig('my_projects')
    },
    {
      label: 'Users',
      icon: <UsersIcon />,
      route: home.usersManagement,
      allowed: getConfig('users_management')
    },
    {
      label: 'Settings',
      icon: <SettingsIcon />,
      route: home.companySettings,
      allowed: getConfig('company_settings')
    },
  ];

  useEffect(() => {
    if (!getConfig('dashboard')) {
      roleBasedRedirect();
    } else {
      setAmlDemoProcessed(true);
    }
  }, []);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleRedirect = (project) => {
    navigate(`/my-projects/${project.id}/analytics`);
  };

  if (!amlDemoProcessed) {
    return <Loader />;
  }

  return (
    <div className='dashboard-page'>
      {isModalOpen && <CreateProjectModal getProjectList={getProjectList} handleClose={handleClose}/>}
      <Header headerTitle='Dashboard' isLimitReached={isLimitReached}/>
      {isLoading || isBannerLoading ? (
        <Loader/>
      ) : (
        <>
          {<DashboardBanner isLimitReached={isLimitReached}/>}
          {getConfig('is_aml_demo') ?
            <div className='dashboard-section'>
              <div className='dashboard-section-list-navigation'>
                <p className='dashboard-section-title'>
                  Modules
                </p>
              </div>
              <div className='module-cards'>
                {modules.filter(module => module.allowed).map(module =>
                  <ModuleCard
                    icon={module.icon}
                    text={module.label}
                    handleClick={() => navigate(module.route)}
                    key={`module-${module.label}`}
                  />
                )}
              </div>
            </div>
            :
            <div className='dashboard-section'>
              <div className='dashboard-section-list-navigation'>
                <p className='dashboard-section-title'>
                  My projects
                </p>
                <Link to={home.myProjects} className='see-all-button'>
                  <p>See all projects →</p>
                </Link>
              </div>
              <div className='dashboard-cards'>
                <Card onClick={handleOpen}/>
                {projects.slice(0, 10).map((project) => (
                  <Card
                    demo={project.demo}
                    projectName={project.name}
                    key={project.id}
                    handleRedirect={() => handleRedirect(project)}
                    imageSrc={project.imageSrc}
                  />
                ))}
              </div>
            </div>
          }
        </>
      )}
    </div>
  );
};

export default Dashboard;

import CustomerDetailsBox from 'pages/customer-details/CustomerDetailsBox';
import { ReactComponent as ConnectIcon } from 'assets/images/svg/connect.svg';
import styles from './style.module.scss';
import ConnectedAccountsTableRow from './ConnectedAccountsTableRow';
import ExternalConnectedAccountsTableRow from './ExternalConnectedAccountsTableRow';
import { useEffect, useState } from 'react';
import { clsx } from 'clsx';


// If customerDetails are not provided, then reducedInfo has to be set to true
const ConnectedAccounts = ({
  balanceData,
  externalAccounts = [],
  customerDetails,
  reducedInfo = false,
  button,
  expandable = false,
  currency = 'USD',
  canDisconnect = false,
  handleDisconnect,
  handleExternalAccountDisconnect,
  singleAccountMode = false,
}) => {
  const [accounts, setAccounts] = useState([]);
  const [accountsRiskData, setAccountsRiskData] = useState({});
  const [expandedRowIndex, setExpandedRowIndex] = useState();

  useEffect(() => {
    if (balanceData !== undefined) {
      setAccounts(balanceData?.accounts ?? []);
    }
  }, [balanceData]);

  useEffect(() => {
    if (customerDetails !== undefined && customerDetails?.accounts !== undefined) {
      setAccountsRiskData(customerDetails.accounts);
    } else {
      setAccountsRiskData({});
    }
  }, [customerDetails]);

  const getHeaderClasses = () => {
    const classes = [];
    if (reducedInfo) {
      classes.push(styles.tableHeaderAmlDemo);
      if (expandable) {
        classes.push(styles.tableHeaderAmlDemoExpandable);
      }
    } else {
      classes.push(styles.tableHeader);
      if (expandable) {
        classes.push(styles.tableHeaderExpandable);
      }
    }
    return clsx(...classes);
  };

  return (
    <CustomerDetailsBox title={singleAccountMode ? 'Connected account' : 'Connected accounts'} icon={<ConnectIcon/>} legend={button}>
      <div className={styles.connectedAccounts}>
        <span className={getHeaderClasses()}>
          {expandable && <span></span>}
          <span>Balance</span>
          <span>Type</span>
          <span>Provider</span>
          <span>Description</span>
          {!reducedInfo && <span>Verified</span>}
          {!reducedInfo && <span>Risk Score</span>}
          <span></span> {/*For possible disconnect icon*/}
        </span>
        {accounts.map((account, index) => <div key={account.account_id} className={styles.rowAndDividerContainer}>
          <ConnectedAccountsTableRow
            accountData={account}
            riskData={accountsRiskData[account.account_id] ? accountsRiskData[account.account_id] : undefined}
            reducedInfo={reducedInfo}
            expandable={expandable}
            otherRowIsExpanded={expandedRowIndex !== index}
            onExpand={() => setExpandedRowIndex(index)}
            currency={currency}
            canDisconnect={canDisconnect}
            handleDisconnect={handleDisconnect}
          />
          {index < (accounts.length + externalAccounts.length) - 1 && <div className={styles.rowDivider}></div>}
        </div>)}
        {externalAccounts.map((account, index) => <div key={`external-account-${index}`} className={styles.rowAndDividerContainer}>
          <ExternalConnectedAccountsTableRow
            accountData={account}
            tableIsExpandable={expandable}
            handleDisconnect={() => handleExternalAccountDisconnect(account.description)}
          />
          {index < externalAccounts.length - 1 && <div className={styles.rowDivider}></div>}
        </div>)}
      </div>
    </CustomerDetailsBox>
  );
};

export default ConnectedAccounts;

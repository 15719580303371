import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import ApiContextProvider from 'context/api/ApiContext';
import UserContextProvider from 'context/user/UserContext';
import TagManager from 'react-gtm-module';
import WidgetContextProvider from './context/widget/WidgetContext.jsx';
import NotificationContextProvider from 'context/notification/NotificationContext';
import { sentryRelease } from './build';

const tagManagerArgs = {
  gtmId: 'GTM-56H8KNR',
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  enabled: window?.MY_APP_CONFIG?.sentry_dsn !== undefined,
  dsn: window?.MY_APP_CONFIG?.sentry_dsn,
  environment: window?.MY_APP_CONFIG?.sentry_env,
  tunnel: '/api/v1/sentry/tunnel',
  release: sentryRelease,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/.*\.blockmate\.io/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserContextProvider>
      <ApiContextProvider>
        <NotificationContextProvider>
          <WidgetContextProvider>
            <App />
          </WidgetContextProvider>
        </NotificationContextProvider>
      </ApiContextProvider>
    </UserContextProvider>
  </React.StrictMode>
);

reportWebVitals();

import { useContext } from 'react';
import { ApiContext } from 'context/api/ApiContext';
import { clsx } from 'clsx';

function ApiKeysListHeading({ publicKeysList }) {
  const { apiToken } = useContext(ApiContext);

  return (
    <div className={clsx('api-keys-heading-titles', publicKeysList && 'api-keys-public-heading')}>
      <span className='key-name'>Key Name</span>
      <span className='value'>Your API Key</span>
      <span className='copy'>{apiToken && 'Copy'}</span>
      <span className='last-used'>Last used</span>
      <span className='created'>Created</span>
      <span className='expiration'>Expiration</span>
      <span className='delete'/>
    </div>
  );
}

export default ApiKeysListHeading;

import { featureFlagsByRole } from '../../utils/roles';

const useRoleConfig = (role) => {
  const getRoleConfig = (key) => {
    if (role && featureFlagsByRole[role] && featureFlagsByRole[role][key] !== undefined) {
      return featureFlagsByRole[role][key];
    }
    return undefined;
  };

  return {
    getRoleConfig
  };
};

export default useRoleConfig;

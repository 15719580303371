import { CheckboxGroup } from '../checkbox-group/CheckboxGroup';
import { ReactComponent as DashIcon } from 'assets/images/svg/dash.svg';
import { ReactComponent as CloseIcon } from 'assets/images/svg/cross.svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import { clsx } from 'clsx';

export const VARIANTS = {
  withOutline: 'withOutline',
  noOutline: 'noOutline',
};

export const SelectWithCheckboxGroup = ({ checkboxes, setCheckboxes, label, variant = VARIANTS.withOutline }) => {
  const selectedCount = useMemo(() => checkboxes.filter(el => el.checked).length, [checkboxes]);

  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  const handleCheckboxChange = (index) => (event) =>
    setCheckboxes(prevCheckboxes =>
      prevCheckboxes.map((checkbox, i) =>
        i === index ? { ...checkbox, checked: event.target.checked } : checkbox
      )
    );

  const isAllSelected = checkboxes.filter(el => el.checked).length === checkboxes.length;

  const handleSelectAll = () => {
    handleClearAllCheckboxes();
    if (!isAllSelected) {
      setCheckboxes(prev => (prev.map(el => {
        return { ...el, checked: true };
      })));
    }
  };

  const handleClearAllCheckboxes = () => {
    setCheckboxes(prev => (prev.map(el => {
      return { ...el, checked: false };
    })));
  };

  return (
    <div className='select-with-checkbox-group' ref={ref}>
      {variant === VARIANTS.withOutline &&
        <button className='button' onClick={handleOpen}>
          {!isAllSelected ? (
            <div className='selected'>
                Filter: {label}
              <button
                className='close-icon-wrapper'
                onClick={handleSelectAll}
              >
                <div className='close-icon'>
                  <CloseIcon />
                </div>
              </button>
            </div>
          ) : (
            <div className='placeholder'>
              All: {label}
            </div>
          )}
          <DashIcon className='dash-icon' stroke={'black'}/>
        </button>
      }
      {variant === VARIANTS.noOutline &&
        <div onClick={handleOpen} className='no-outline'>
          {selectedCount > 0 && <div className='counter'>{selectedCount}</div>}
          <div className={clsx('label', selectedCount > 0 && 'label-active')}>{label}</div>
          <DashIcon className={clsx('dash-icon', selectedCount > 0 && 'dash-active')}/>
        </div>
      }
      {open && (
        <div className='options-wrapper'>
          <CheckboxGroup
            isAllSelected={isAllSelected}
            checkboxes={checkboxes}
            onChange={handleCheckboxChange}
            selectAll={handleSelectAll}
          />
        </div>
      )}
    </div>
  );
};

import PageMainContainer from 'components/page-main-container/PageMainContainer';
import FormBottomLink from 'components/form/form-bottom-link/FormBottomLink';
import ConfirmNewPasswordForm from 'components/AuthForms/ConfirmNewPasswordForm';
import dynamicImg from 'assets/images/NFT/dynamic.jpg';
import appRoutes from 'routes/routes';
import FormHeader from 'components/form/form-header/FormHeader';

function ConfirmNewPassword() {
  const { external } = appRoutes;

  return (
    <>
      <PageMainContainer nftBgImg={dynamicImg}>
        <div className='content'>
          <FormHeader
            title='Confirm New Password'
            titleText='Please enter and confirm your new password.'
          />

          <ConfirmNewPasswordForm />

          <FormBottomLink
            linkContent='Need help? Contact support →'
            linkPath={external.contact}
            externalLink={true}
          />
        </div>
      </PageMainContainer>
    </>
  );
}

export default ConfirmNewPassword;

import { useContext, useEffect, useState } from 'react';
import InviteUsersWelcome from './InviteUsersWelcome';
import Header from 'components/header/Header';
import UsersModalForm from './UsersModalForm';
import UsersManagementTable from './UsersManagementTable';
import { MODAL_TYPES } from './UsersModalForm';
import { axiosErrorHandler, getStaticPath } from '../../services/api/axios';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import PaginationContainer from '../../components/pagination-container/PaginationContainer';
import { usePrevious } from '../../hooks/previous';
import { useSearchParams } from 'react-router-dom';
import { useRoleBasedRedirect } from '../../hooks';
import Loader from '../../components/loaders/loader/Loader';
import { UserContext } from '../../context/user/UserContext';

const UsersManagement = () => {
  const PAGE_SIZE = 25;

  const [searchParams] = useSearchParams();
  const { getConfig } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModalType, setCurrentModalType] = useState(MODAL_TYPES.inviteUser);
  const [currentUserName, setCurrentUserName] = useState(undefined);
  const [currentUserEmail, setCurrentUserEmail] = useState(undefined);
  const [currentUserRole, setCurrentUserRole] = useState(undefined);
  const [page, setPage] = useState(Number(searchParams.get('page') ?? 1));
  const [nextPageExists, setNextPageExists] = useState(false);
  const prevPage = usePrevious(page);
  const [users, setUsers] = useState([]);
  const [editedUserId, setEditedUserId] = useState();
  const [amlDemoProcessed, setAmlDemoProcessed] = useState(false);
  const roleBasedRedirect = useRoleBasedRedirect();

  const _getPage = async (targetPage) => {
    try {
      const response = await axios.get(getStaticPath('ADMIN_URL', 'list'), {
        params: {
          skip: PAGE_SIZE * (targetPage - 1), // Because targetPage is 1-based
          limit: PAGE_SIZE
        }
      });
      return response?.data ?? [];
    } catch (error) {
      toast.error(axiosErrorHandler(error));
      return [];
    }
  };

  const moveToPage = async (targetPage) => {
    const targetPageData = await _getPage(targetPage);
    if (targetPageData.length > 0) {
      setPage(targetPage);
      setUsers(targetPageData);
    } else {
      setPage(prevPage);
    }
    const nextPageData = await _getPage(targetPage + 1);
    setNextPageExists(nextPageData.length > 0);
  };

  useEffect(() => {
    if (getConfig('is_aml_demo') && !getConfig('users_management')) {
      roleBasedRedirect();
    } else {
      setAmlDemoProcessed(true);
    }
  });

  useEffect(() => {
    moveToPage(1, 1);
  }, []);

  useEffect(() => {
    moveToPage(page);
  }, [page]);

  const handleInviteUsers = () => {
    setIsModalOpen(true);
  };

  const handleModalOpen = (openModalType, userName, email, role) => {
    setCurrentModalType(openModalType);
    setCurrentUserName(userName);
    setCurrentUserEmail(email);
    setCurrentUserRole(role);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setCurrentModalType(MODAL_TYPES.inviteUser);
    setIsModalOpen(false);
    moveToPage(page);
  };

  const handlePageSet = (p) => {
    setPage(p);
    searchParams.set('page', p.toString());
    window.history.replaceState(window.history.state, '', '?' + searchParams.toString());
  };

  if (!amlDemoProcessed) {
    return <Loader />;
  }

  if (users.length === 0) {
    return (
      <div className='dashboard-page'>
        <Header headerTitle='Users'/>
        <InviteUsersWelcome
          handleInviteUsers={handleInviteUsers}
        />
        {isModalOpen &&
          <UsersModalForm
            handleModalClose={handleModalClose}
            modalType={MODAL_TYPES.inviteUser}
          />
        }
      </div>
    );
  }

  return (
    <div className='dashboard-page'>
      <Header headerTitle='Users'/>
      <UsersManagementTable
        usersData={users}
        handleInviteClick={() => handleModalOpen(MODAL_TYPES.inviteUser)}
        handleUserEdit={(userName, email, role) => handleModalOpen(MODAL_TYPES.editUser, userName, email, role)}
        handleUserDelete={(userName) => handleModalOpen(MODAL_TYPES.deleteUser, userName)}
        setEditedUserId={setEditedUserId}
      />
      {isModalOpen &&
        <UsersModalForm
          handleModalClose={handleModalClose}
          modalType={currentModalType}
          userName={currentUserName}
          userEmail={currentUserEmail}
          userRole={currentUserRole}
          userId={editedUserId}
        />
      }
      <PaginationContainer
        usersLength={users.length}
        usersCount={nextPageExists ? -1 : PAGE_SIZE}  // PaginationContainer disablex next page button if usersCount = PAGE_SIZE
        page={page}
        setPage={handlePageSet}
        perPage={PAGE_SIZE}
      />
    </div>
  );
};

export default UsersManagement;

import UsersTableHeader from './UsersTableHeader';
import UsersTableSubheader from './UsersTableSubheader';
import { useEffect, useState } from 'react';
import UsersModal from 'components/modals/users-modal/UsersModal';
import { getStaticPath } from 'services/api/axios';
import axios from 'axios';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import PaginationContainer from 'components/pagination-container/PaginationContainer';
import SmallDeviceNotification from 'components/mobile/screen-too-small/SmallDeviceNotification';
import UsersTableContent from './UsersTableContent';
import useNotification from 'components/notification/UseNotification';
import { allowedSorts, allowedSortDirections } from './allowedSorts';

const Users = () => {
  const { id } = useParams();
  const { project } = useOutletContext();
  const [isContractImported, setIsContractImported] = useState(project.imported_contract);
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams] = useSearchParams();
  const [sortParam, setSortParam] = useState(searchParams.get('sort') ?? allowedSorts.NAME);
  const [directionParam, setDirectionParam] = useState(searchParams.get('direction') ?? allowedSortDirections.ASC);
  const [pageParam, setPageParam] = useState(Number(searchParams.get('page') ?? 1));
  const [searchParam, setSearchParam] = useState(searchParams.get('search') ?? '');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [allUsersCount, setAllUsersCount] = useState('');
  const [usersCount, setUsersCount] = useState('');
  const showNotification = useNotification();

  useEffect(() => {
    const controller = new AbortController();
    const loadUsersData = setTimeout(async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(getStaticPath('USERLIST_URL', `${id}`), {
          params:{
            page: pageParam,
            search: searchParam,
            sort_by: sortParam,
            sort_direction: directionParam,
            currency: project.currency
          },
          signal: controller.signal
        });

        const parsed = Object.entries(data.users ?? {}).map(([id, user]) =>  ({
          id,
          name: user.name,
          providers: user.providers,
          balance: user.balance,
          last_tx: user.last_tx,
          country: user.country,
          risk: Math.round(user.risk),
          socials: user.socials,
        }));
        // const usersLength = parsed.length;
        setUsersData(parsed);
        setUsersCount(parsed.length);
        setAllUsersCount(data.total_count);
      }
      catch (error) {
        if (error.code !== 'ERR_CANCELED') {
          showNotification('userList', 'error');
        }
      }
      setIsLoading(false);
    }, 300);

    return () => {
      controller.abort();
      clearTimeout(loadUsersData);
    };
  }, [id, pageParam, searchParam, sortParam, directionParam, project]);

  const handleChange = (e) => {
    setSearchParam(e);
    searchParams.set('search', e);
    window.history.replaceState(window.history.state, '', '?' + searchParams.toString());
    handlePageSet(1);
  };

  const handlePageSet = (p) => {
    setPageParam(p);
    searchParams.set('page', p.toString());
    window.history.replaceState(window.history.state, '', '?' + searchParams.toString());
  };

  const handleSetSortParam = (p) => {
    setSortParam(p);
    searchParams.set('sort', p);
    window.history.replaceState(window.history.state, '', '?' + searchParams.toString());
  };

  const handleSetDirectionParam = (p) => {
    setDirectionParam(p);
    searchParams.set('direction', p);
    window.history.replaceState(window.history.state, '', '?' + searchParams.toString());
  };

  return (
    <>
      {isModalOpen && (
        <UsersModal
          handleClose={() => setIsModalOpen(false)}
          setIsContractImported={setIsContractImported}
          isContractImported={isContractImported}
          usersLength={usersCount}
          isLoading={isLoading}
        />
      )}
      <SmallDeviceNotification/>
      <div className='users-list'>
        <UsersTableSubheader
          handleChange={handleChange}
          handleOpen={() => setIsModalOpen(true)}
          isContractImported={isContractImported}
        />
        <div className='users-main-container'>
          <UsersTableHeader
            sort={sortParam}
            direction={directionParam}
            setSort={handleSetSortParam}
            setDirection={handleSetDirectionParam}
          />
          <UsersTableContent
            isLoading={isLoading}
            parsed={usersData}
            isContractImported={isContractImported}
            searchParams={searchParam}
            buttonNoDataOnClick={() => setIsModalOpen(true)}
          />
        </div>
        <PaginationContainer
          usersLength={usersCount}
          usersCount={allUsersCount}
          page={pageParam}
          setPage={handlePageSet}
        />
      </div>
    </>
  );
};

export default Users;

import styles from './style.module.scss';
import RiskScore from 'components/risk-score/RiskScore';
import { dateObjectToDateString } from 'utils/date-formatter';
import CopyToClipboardTooltip from 'components/copy-to-clipboard-tooltip/CopyToClipboardTooltip';
import { useMemo, useState, useContext } from 'react';
import { ellipsis, unique, valueExists } from '../../utils/common';
import { ReactComponent as WarningIcon } from '../../assets/images/svg/warning-icon.svg';
import Expander from '../../components/customer-details/Expander';
import TxMovementInfo from '../../components/customer-details/TxMovementInfo';
import { clsx } from 'clsx';
import CopyToClipboardWithIcon from '../../components/copy-to-clipboard-with-icon/CopyToClipboardWithIcon';
import { UserContext } from '../../context/user/UserContext';
import {
  determineTxType,
  getTxTypeDescription,
  determineInstitutionInfo,
  determineProviders,
  movementSortFn
} from '../../utils/consolidated-transactions/consolidated-transactions';

const ConsolidatedTransactionsTableRow = ({ data, ownMovementsLabel, accountInfo, reduced = false, categoriesOverride = undefined }) => {
  const { getConfig, providersInfo } = useContext(UserContext);
  const isAmlDemo = getConfig('is_aml_demo');

  const MAX_COLLAPSED_MOVEMENTS = 1;

  const filteredInputs = useMemo(() => [...data.inputs].sort(movementSortFn), [data]);
  const filteredOutputs = useMemo(() => {
    const filtered = data.outputs.filter(output => !output.fee && output.value !== 0);
    return [...filtered].sort(movementSortFn);
  }, [data]);
  const feeOnlyTx = useMemo(() => data.outputs.every(output => output.fee), [data]);
  const parsedCategories = useMemo(() => {
    if (categoriesOverride) {
      return categoriesOverride;
    }
    const categoriesWithDuplicates = [...filteredInputs, ...filteredOutputs]
      .flatMap(movement => [movement.category, ...(movement?.categories ?? [])])
      .filter(valueExists);
    return unique(categoriesWithDuplicates);
  }, [data]);
  const inputsAreExpandable = filteredInputs.length > MAX_COLLAPSED_MOVEMENTS;
  const outputsAreExpandable = filteredOutputs.length > MAX_COLLAPSED_MOVEMENTS;
  const [inputsExpanded, setInputsExpanded] = useState(false);
  const [outputsExpanded, setOutputsExpanded] = useState(false);
  const [displayedInputs, setDisplayedInputs] = useState(filteredInputs.slice(0, MAX_COLLAPSED_MOVEMENTS));
  const [displayedOutputs, setDisplayedOutputs] = useState(filteredOutputs.slice(0, MAX_COLLAPSED_MOVEMENTS));
  const txType = useMemo(() => determineTxType(data, false), [data]);
  const institutionInfo = useMemo(() => determineInstitutionInfo(data, accountInfo, providersInfo), [data, accountInfo]);
  const ownProvider = useMemo(() => {
    if (accountInfo?.provider) {
      return `My ${accountInfo.provider}`;
    }
    return 'Owned';
  }, [data, accountInfo]);

  return <div className={clsx(
    styles.tableRow,
    reduced && styles.tableRowReduced,
    (!reduced && isAmlDemo && txType.isWithdrawal) && styles.withdrawalRow,
    (!reduced && isAmlDemo && txType.isDeposit) && styles.depositRow
  )}>
    {!reduced && <span>{dateObjectToDateString(data.created_at, true)}</span>}
    <span className={styles.txHash}>
      <CopyToClipboardWithIcon copyValue={data.transaction_id} tooltipDisplayedData={data.transaction_id}>
        <span>{ellipsis(data.transaction_id, reduced ? 12 : 6)}</span>
      </CopyToClipboardWithIcon>
    </span>
    <div className={styles.txTypeContainer}>
      <CopyToClipboardTooltip tooltipData={getTxTypeDescription(txType.type)} openOnHover={true}>
        {txType.type}
      </CopyToClipboardTooltip>
      {feeOnlyTx && <CopyToClipboardTooltip tooltipData='Internal operation' openOnHover={true}>
        <div className={styles.warningIcon}>
          <WarningIcon className={styles.warningIconSmall} />
        </div>
      </CopyToClipboardTooltip>}
    </div>
    <span className={styles.transactionDetails}>
      {displayedInputs.map((txInput, index) => <TxMovementInfo
        movement={txInput}
        ownedLabel={ownMovementsLabel}
        accountInfo={accountInfo}
        txOwnProvider={ownProvider}
        key={`${data.transaction_id}:in:${index}`}
      />)}
      {inputsAreExpandable && <span>
        <Expander
          isExpanded={inputsExpanded}
          total={filteredInputs.length}
          handleClick={() => {
            setDisplayedInputs(inputsExpanded ? filteredInputs.slice(0, MAX_COLLAPSED_MOVEMENTS) : filteredInputs);
            setInputsExpanded(prevInputsExpanded => !prevInputsExpanded);
          }}
        />
      </span>}
    </span>
    <span className={styles.transactionDetails}>
      {displayedOutputs.filter(txOutput => !txOutput.fee && txOutput.value !== 0).map((txOutput, index) =>
        <TxMovementInfo
          movement={txOutput}
          ownedLabel={ownMovementsLabel}
          accountInfo={accountInfo}
          txOwnProvider={ownProvider}
          key={`${data.transaction_id}:out:${index}`}
        />)}
      {outputsAreExpandable && <span>
        <Expander
          isExpanded={outputsExpanded}
          total={filteredOutputs.length}
          handleClick={() => {
            setDisplayedOutputs(outputsExpanded ? filteredOutputs.slice(0, MAX_COLLAPSED_MOVEMENTS) : filteredOutputs);
            setOutputsExpanded(prevOutputsExpanded => !prevOutputsExpanded);
          }}
        />
      </span>}
    </span>
    {!reduced && <span className={styles.tableRisk}>
      <RiskScore riskScore={data.risk ?? 0}/>
      <span>{data.risk ?? 0}/100</span>
    </span>}

    <span className={styles.capitalized}>
      {txType.type}<br />
      {determineProviders(data).map(
        (provider, idx) => {
          return <div key={`${data.transaction_id}-provider-${idx}`}>{provider}<br/></div>;
        }
      )}
      {parsedCategories.map((category) => {
        return <div key={`${data.transaction_id}:${category}`}>
          {category}<br/>
        </div>;
      })}
      {institutionInfo && institutionInfo.length > 21
        ? <CopyToClipboardTooltip tooltipData={String(institutionInfo)} openOnHover allowUnderline={false}>
          {ellipsis(institutionInfo, 21)}
        </CopyToClipboardTooltip>
        : institutionInfo
      }
    </span>
  </div>;
};

export default ConsolidatedTransactionsTableRow;

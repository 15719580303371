import MainButton from 'components/buttons/MainButton';
import FormBottomLink from 'components/form/form-bottom-link/FormBottomLink';
import { ReactComponent as MenuButton } from 'assets/images/svg/menu-button.svg';
import appRoutes from 'routes/routes';
import { getStaticPath } from 'services/api/axios';
import axios from 'axios';
import MainModal from 'components/modals/MainModal';
import { ReactComponent as BoltGreen } from 'assets/images/svg/boltGreenIcon.svg';
import { Formik } from 'formik';
import { changeProjectNameSchema } from 'components/AuthForms/validationSchema';
import useNotification from 'components/notification/UseNotification';

const CreateProjectModal = ({ handleClose, getProjectList }) => {
  const { external } = appRoutes;
  const showNotification = useNotification();

  const addProject = async (values) => {
    await axios({
      method: 'post',
      url: getStaticPath('PROJECT_URL'),
      params: {
        name: values.newName.trimStart()
      }
    }
    );
    await getProjectList();
    handleClose();
    showNotification('createProject', 'success');
  };

  return (
    <MainModal handleClose={handleClose}>
      <div className='modal-content'>
        <div className='modal-form'>
          <div className='modal-text-container'>
            <MenuButton/>
            <p className='modal-title'>
              Create new project
            </p>
            <p className='modal-description'>
              Fill in the project&apos;s name below. Don&apos;t worry, you&apos;ll be able to change it later
            </p>
          </div>
          <Formik
            initialValues={{ newName: '', }}
            validationSchema={changeProjectNameSchema}
            onSubmit={addProject}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form className='main-form create-new-project' onSubmit={handleSubmit}>
                <input
                  type='name'
                  name='newName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newName}
                  placeholder='New project name'
                  className='main-form-input'
                />
                {errors.newName && touched.newName && (
                  <p className='input-alert'>{errors.newName}</p>
                )}
                <MainButton
                  type='submit'
                  variant='button-purple'
                  label='CREATE PROJECT'
                  icon={<BoltGreen/>}
                />
              </form>
            )}
          </Formik>
        </div>
        <FormBottomLink
          linkContent='Need help? Contact support →'
          linkPath={external.contact}
          externalLink={true}
          className='modal-footer'/>
      </div>
    </MainModal>
  );
};

export default CreateProjectModal;

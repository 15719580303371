import styles from './deposit-filters.module.scss';
import { ReactComponent as CrossIcon } from '../../../assets/images/svg/cross.svg';
import MainButton from '../../buttons/MainButton';
import { useMemo } from 'react';
import { clsx } from 'clsx';

const DepositFilters = ({ since, setSince, until, setUntil, userId, setUserId, handleApplyFilters, disabled = false }) => {
  const canReset = useMemo(() => {
    const sinceTouched = since !== '';
    const untilTouched = until !== '';
    const userIdTouched = userId !== '';
    return sinceTouched || untilTouched || userIdTouched;
  }, [since, until]);

  const handleReset = () => {
    setSince('');
    setUntil('');
    setUserId('');
  };

  return <div className={styles.container}>
    <span className={styles.header}>Filter data:</span>
    <input className={styles.datePicker} type='date' value={since} onChange={e => setSince(e.target.value)} />
    <input className={styles.datePicker} type='date' value={until} onChange={e => setUntil(e.target.value)} />
    <input className={styles.textInput} type='text' placeholder='User ID' value={userId} onChange={e => setUserId(e.target.value)} />
    <div className={clsx(styles.resetFilters, canReset && styles.active)} title='Reset filters' onClick={handleReset}>
      <CrossIcon />
    </div>
    <MainButton
      onClick={handleApplyFilters}
      variant='button-purple'
      label={disabled ? 'Loading...' : 'Apply filters'}
      extraClasses={styles.applyFiltersButton}
      disabled={disabled}
    />
  </div>;
};

export default DepositFilters;

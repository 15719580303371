import axios from 'axios';
import { endpoints } from '../../services/api/axios';
import { dateObjectToDateString, getDateStringsRange, formatDateStrAsShortMonthNoYear } from '../date-formatter';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const accountUsageToOutput = (data) => (
  Object.entries(data).map(([dateStr, value]) => ({
    date: formatDateStrAsShortMonthNoYear(dateStr),
    value
  }))
);

const getImportantDateBoundaries = () => {
  const currentDate = dayjs();
  const lastMonthStartDate = currentDate.subtract(1, 'month').startOf('month');

  return {
    currentMonthStartDate: currentDate.startOf('month').toDate(),
    currentDate: currentDate.toDate(),
    lastMonthStartDate: lastMonthStartDate.toDate(),
    lastMonthEndDate: lastMonthStartDate.endOf('month').toDate(),
    date90DaysAgo: currentDate.subtract(89, 'day').toDate()
  };
};

export const loadAccountUsageData = async () => {
  const importandDates = getImportantDateBoundaries();

  const last90DaysDataResponse = await axios.get(
    `${endpoints.BILLING_URL}/?from_date=${dateObjectToDateString(importandDates.date90DaysAgo)}`
  );
  const last90DaysData = last90DaysDataResponse?.data;
  const last90DaysEntries = last90DaysData?.day_entries ?? [];
  // Fill in missing dates
  const allWantedDateStrings = getDateStringsRange(importandDates.date90DaysAgo, importandDates.currentDate);
  allWantedDateStrings.forEach(dateString => {
    if (!last90DaysEntries[dateString]) {
      last90DaysEntries[dateString] = 0;
    }
  });

  const getDataRangeSlice = (fromDate, toDate) => {
    return Object.fromEntries(Object.entries(last90DaysEntries).filter(([dateStr, _]) => {
      return dayjs(dateStr).isBetween(fromDate, toDate, 'day', '[]');
    }));
  };
  const currentMonthData = getDataRangeSlice(importandDates.currentMonthStartDate, importandDates.currentDate);
  const lastMonthData = getDataRangeSlice(importandDates.lastMonthStartDate, importandDates.lastMonthEndDate);

  return {
    data: {
      current: accountUsageToOutput(currentMonthData),
      last: accountUsageToOutput(lastMonthData),
      long: accountUsageToOutput(last90DaysEntries),
    },
    projects: last90DaysData?.projects_count ?? 0,
    totalCredits: last90DaysData?.total_credits ?? 0,
    usersCount: last90DaysData?.users_count ?? 0
  };
};

const fillMissingDates = (dayEntries, fromDate, toDate) => {
  const allWantedDateStrings = getDateStringsRange(fromDate, toDate);
  return dayEntries.map(entry => {
    allWantedDateStrings.forEach(dateString => {
      if (!entry.days[dateString]) {
        entry[dateString] = 0;
      }
    });
    return entry;
  });
};

export const loadAccountUsageDetailsData = async () => {
  const importandDates = getImportantDateBoundaries();

  const periodResponses = await Promise.all([
    {
      from: importandDates.currentMonthStartDate,
      to: importandDates.currentDate
    },
    {
      from: importandDates.lastMonthStartDate,
      to: importandDates.lastMonthEndDate
    },
    {
      from: importandDates.date90DaysAgo,
      to: importandDates.currentDate
    }
  ].map(async ({ from, to }) => {
    const response = await axios.get(
      `${endpoints.BILLING_URL}/details?from_date=${dateObjectToDateString(from)}&to_date=${dateObjectToDateString(to)}`
    );
    return {
      chartData: fillMissingDates(response.data?.day_entries, from, to),
      tableData: response.data?.entries
    };
  }));

  const maxCredits = new Array(3).fill(null).map((_, periodIdx) =>
    periodResponses[periodIdx].chartData.reduce((acc, entry) => {
      const credits = Math.max(...Object.values(entry.days));
      return Math.max(acc, credits);
    }, 0)
  );

  return {
    chartData: {
      current: periodResponses[0].chartData,
      last: periodResponses[1].chartData,
      long: periodResponses[2].chartData
    },
    tableData: {
      current: periodResponses[0].tableData,
      last: periodResponses[1].tableData,
      long: periodResponses[2].tableData
    },
    domainUpperRange: {
      current: maxCredits[0],
      last: maxCredits[1],
      long: maxCredits[2]
    }
  };
};

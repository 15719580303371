import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import appRoutes from 'routes/routes';
import { UserContext } from 'context/user/UserContext';


const ProtectedRoutes = ({ children }) => {
  const { authorized } = useContext(UserContext);

  return authorized ? children : <Navigate to={appRoutes.auth.login}/>;
};

export default ProtectedRoutes;

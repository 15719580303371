export const SMALLEST_MEDIUM_RISK = 40;
export const SMALLEST_HIGH_RISK = 70;

export const isLowRisk = (riskScore) => riskScore < SMALLEST_MEDIUM_RISK;

export const isMediumRisk = (riskScore) => riskScore < SMALLEST_HIGH_RISK && riskScore >= SMALLEST_MEDIUM_RISK;

export const isHighRisk = (riskScore) => riskScore >= SMALLEST_HIGH_RISK;

export const getLabelClassByRisk = (riskScore) => {
  if (isLowRisk(riskScore)) {
    return 'risk-low';
  } else if (isMediumRisk(riskScore)) {
    return 'risk-medium';
  }
  return 'risk-high';
};

export const getTextClassByRisk = (riskScore) => {
  if (isLowRisk(riskScore)) {
    return 'text-risk-low';
  } else if (isMediumRisk(riskScore)) {
    return 'text-risk-medium';
  }
  return 'text-risk-high';
};

const CATEGORY_TO_RISK = {
  'Bank': 5,
  'Betting / Sports betting': 20,
  'Bribery and corruption': 100,
  'cc': 0,
  'Charity': 0,
  'Cloud mining service': 0,
  'Credit card buyer': 0,
  'Credit card seller': 0,
  'Crime actor': 100,
  'Crime victim': 5,
  'Crypto ATM': 5,
  'Crypto marketer or influencer': 5,
  'Crypto related law firm': 5,
  'Cybercrime actor hacker': 100,
  'Cybercrime victim': 5,
  'Crypto related marketing agency': 5,
  'dApp': 5,
  'DDoS Service': 100,
  'DDW (Deep Dark Web) marketplace': 100,
  'DDW (Deep Dark Web) merchant': 40,
  'Donation': 0,
  'Drugs trade': 90,
  'Exchange': 5,
  'Exploit hack services buyer': 100,
  'Exploit hack services seller': 100,
  'Faucet': 30,
  'Gambling': 40,
  'Gaming': 10,
  'Hack': 100,
  'Hacker': 100,
  'ICO 3rd party contributor': 5,
  'ICO airdrop beneficier': 5,
  'ICO beneficier': 20,
  'ICO beneficier - advisor': 15,
  'ICO interested or active investor (contributor)': 5,
  'ICO project': 30,
  'ID buyer': 70,
  'ID seller': 100,
  'Illegal content provider': 100,
  'Insurance': 5,
  'Investment or hedge fund': 0,
  'Law enforcement': 0,
  'Lending / P2P lending': 10,
  'P2P exchange tied acount': 20,
  'Merchant': 0,
  'Miner': 0,
  'Miner by crime': 100,
  'Mining pool': 0,
  'Multilevel marketing': 40,
  'Non-crypto services': 0,
  'OTC Trading': 30,
  'Other financial services': 0,
  'Payment processor': 10,
  'Phishing': 70,
  'Political exposed person (PEP)': 70,
  'Politics and activism': 70,
  'Ponzi schemes': 100,
  'Potentially sanctioned entity': 95,
  'Ransom & Extortion': 100,
  'Ransomware': 100,
  'Religion & Faith': 10,
  'Scams & Investment Frauds': 100,
  'Sex industry - Callgirls Escort': 30,
  'Sex industry - Potentionally illegal': 30,
  'Sex industry - Seems legal': 30,
  'Shutdown or inactive service': 20,
  'SPAM provider': 70,
  'SWATing': 50,
  'Tax evasion': 30,
  'Terrorism / War related': 100,
  'Tied to criminal activity': 100,
  'Trading platform': 10,
  'Under investigation or ligitation': 80,
  'Other crypto project or service': 20,
  'Wallet provider': 10,
  'Weapon trade or trafficking': 100,
  'Staking': 10,
  'Banned': 100,
  'Decentralized exchange': 40,
};

export const categoryToRiskScore = (category) => {
  return CATEGORY_TO_RISK?.[category] ?? 0;
};

import CookiesPrivacy from 'components/cookies/CookiesPrivacy';

function Footer() {
  return (
    <footer className='main-footer'>
      <CookiesPrivacy />
    </footer>
  );
}

export default Footer;

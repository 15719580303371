import { useEffect, useState } from 'react';

export const DEBUG_KEY = 'debug';
export const OLD_TABLES_KEY = 'oldTables';
export const DEPOSITS_ADMIN_KEY = 'depositsAdminPage';

export default function Debug() {
  const [debug, setDebug] = useState(undefined);
  const [oldTables, setOldTables] = useState(undefined);
  const [depositsAdmin, setDepositsAdmin] = useState(undefined);

  useEffect(() => {
    setDebug(localStorage.getItem(DEBUG_KEY) ?? 'OFF');
  }, []);

  useEffect(() => {
    setOldTables(localStorage.getItem(OLD_TABLES_KEY) ?? 'OFF');
  }, []);

  const toggleSetting = (key, setMethod) => {
    let old = localStorage.getItem(key) ?? 'OFF';
    if (old === 'OFF') {
      localStorage.setItem(key, 'ON');
      setMethod('ON');
    } else {
      localStorage.setItem(key, 'OFF');
      setMethod('OFF');
    }
  };

  return <div>
    <p>
      Debug: {debug ?? '?'}
      <button onClick={() => toggleSetting(DEBUG_KEY, setDebug)}>TOGGLE</button>
    </p>
    <p>
      Old tables: {oldTables ?? '?'}
      <button onClick={() => toggleSetting(OLD_TABLES_KEY, setOldTables)}>TOGGLE</button>
    </p>
    <p>
      Deposits admin: {depositsAdmin ?? '?'}
      <button onClick={() => toggleSetting(DEPOSITS_ADMIN_KEY, setDepositsAdmin)}>TOGGLE</button>
    </p>
  </div>;
}

import PlanSelector from '../../components/billing/PlanSelector';
import Header from '../../components/header/Header';
import appRoutes from 'routes/routes.js';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getParsedPlanData } from '../../utils/billing/plan-loader';
import { toast } from 'react-hot-toast';

const BillingManagePlan = () => {

  const [plans, setPlans] = useState([]);
  const [searchParams] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState(false);

  const { home } = appRoutes;

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const paymentState = searchParams.get('payment-state');
      const toastOptions = {
        duration: 6000
      };
      if (paymentState === 'success') {
        toast.success('Payment successful. Changes will take effect shortly.', toastOptions);
      } else if (paymentState === 'error') {
        toast.error('Payment failed', toastOptions);
      }
    }
  }, [isLoaded]);

  useEffect(() => {
    (async () => {
      const plansData = await getParsedPlanData();
      setPlans(plansData);
    })().catch(console.error);
  }, []);

  // TODO fetch addon data

  return (
    <div className='billing-manage-plan-container'>

      <Header withBackButton={true} backRoute={home.billing} headerTitle='Billing/ Manage plan' />

      {/* TODO uncomment this once addon data are ready */}
      {/*{addon*/}
      {/*  ? (<div className='manage-plan-add-on-container'>*/}
      {/*    <AddOnManagementBox currentAddOn={addon} />*/}
      {/*  </div>)*/}
      {/*  : (<div className='billing-banner-container'>*/}
      {/*    <p className='billing-banner-product-name'>blockmate analytics</p>*/}
      {/*    <p className='billing-banner-text'>Better Web3 insights. Faster.</p>*/}
      {/*    <div className='banner-button'>*/}
      {/*      <MainButton*/}
      {/*        variant='button-green'*/}
      {/*        label={<div className='billing-banner-manage-plan-button-text'>new add-on</div>}*/}
      {/*        icon={<StarIcon />}*/}
      {/*        extraClasses='banner-button'*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className='banner-link auto-width'>*/}
      {/*      Start 30 days trial →*/}
      {/*    </div>*/}
      {/*  </div>)*/}
      {/*}*/}

      <PlanSelector plans={plans} />
    </div>
  );
};

export default BillingManagePlan;

import { useState } from 'react';
import { Formik } from 'formik';
import { confirmNewPasswordSchema } from 'components/AuthForms/validationSchema';
import authService from 'services/auth-services/auth.service';
import { useSearchParams, useNavigate } from 'react-router-dom';
import appRoutes from 'routes/routes';
import LoadingSpinner from 'components/loaders/loading-spinner/LoadingSpinner';
import MainButton from 'components/buttons/MainButton';
import useNotification from 'components/notification/UseNotification';
import { ReactComponent as SendIcon } from 'assets/images/svg/refresh.svg';

function ConfirmNewPasswordForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { resetPassword } = authService;
  const { auth } = appRoutes;
  const navigate = useNavigate();
  const tokenValue = searchParams.get('token');
  const showNotification = useNotification();

  const handleNewPasswordSubmit = async (values) => {
    try {
      setIsLoading(true);
      await resetPassword(tokenValue, values.password);
      navigate(auth.login);
    } catch (error) {
      showNotification('resetPassword', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Formik
          initialValues={{ password: '', passwordConfirmation: '' }}
          validationSchema={confirmNewPasswordSchema}
          onSubmit={handleNewPasswordSubmit}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} className='main-form'>
              <input
                type='password'
                name='password'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                placeholder='Your new password'
                className='main-form-input'
              />
              {errors.password && touched.password && (
                <p className='form-error-message'>{errors.password}</p>
              )}
              <input
                type='password'
                name='passwordConfirmation'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.passwordConfirmation}
                placeholder='Confirm your new password'
                className='main-form-input'
              />
              {errors.passwordConfirmation && touched.passwordConfirmation && (
                <p className='form-error-message'>
                  {errors.passwordConfirmation}
                </p>
              )}
              <MainButton
                type='submit'
                variant='button-gray'
                label='CONFIRM NEW PASSWORD'
                icon={<SendIcon />}
              />
            </form>
          )}
        </Formik>
      )}
    </>
  );
}

export default ConfirmNewPasswordForm;

import axios from 'axios';
import getExchangeRates from '../../../api/exchange-rates';
import { unique, valueExists } from '../../../../utils/common';
import { filterTypes } from '../../../../components/filters/ConsolidatedTransactionsFilters';

const getTronTxsLoader = ({
  address,
  accountId,
  currency = 'usd',
  pageSize = 50,
  apiUrl,
  userJwt,
}) => {
  let offset = 0;
  let nextPageExists = true;
  let trxExchangeRate = undefined;
  const trxCurrencyPair = `trx/${currency.toLowerCase()}`;

  const loadNextPage = async (filters) => {
    if (trxExchangeRate === undefined) {
      const rates = await getExchangeRates([trxCurrencyPair], apiUrl, userJwt);
      trxExchangeRate = rates?.[trxCurrencyPair] ?? 0;
    }

    const params = {
      sort: '-timestamp',
      count: true,
      start: offset,
      limit: pageSize,
      address,
    };
    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
      } = filters;

      if (valueExists(accountsFilterParam)) {
        if (!accountsFilterParam.split(',').includes(accountId)) {
          nextPageExists = false;
          return [];
        }
      }
      if (valueExists(digitalAssetsFilterParam)) {
        if (!digitalAssetsFilterParam.split(',').includes('TRX')) {
          nextPageExists = false;
          return [];
        }
      }
    }

    const url = 'https://apilist.tronscanapi.com/api/transaction';
    const response = await axios.get(
      url,
      { params }
    );
    const data = response?.data?.data ?? [];
    if (data.length < pageSize) {
      nextPageExists = false;
    }
    offset += data.length;
    return data.map(tronscanTx => transformToAccountTx(tronscanTx, address, accountId, trxExchangeRate, currency));
  };

  const hasNextPage = () => {
    return nextPageExists;
  };

  const reset = () => {
    offset = 0;
    nextPageExists = true;
  };

  const getAllowedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS];
  };

  return { loadNextPage, hasNextPage, reset, getAllowedFilters };
};

const transformToAccountTx = (tronscanTx, originatorAddress, accountId, trxExchangeRate, fiatCurrency) => {
  const decimals = tronscanTx?.tokenInfo?.tokenDecimal ?? 6;
  const value = tronscanTx?.contractData?.amount ?? 0;
  const valueDecimalized = value / Math.pow(10, decimals);
  const convertedValue = valueDecimalized * trxExchangeRate;
  const tokenId = tronscanTx?.tokenInfo?.tokenId ?? 'TRON:';
  const currency = (tokenId === '_' ? 'trx' : `TRON:${String(tokenId)}`).toUpperCase();
  const currencyName = tronscanTx?.tokenInfo?.tokenName ?? '?';
  const currencySymbol = (tronscanTx?.tokenInfo?.tokenAbbr ?? '?').toUpperCase();
  const ownerTag = tronscanTx?.ownerAddressTag;
  const toTag = tronscanTx?.toAddressTag;

  const formatCurrencyValue = (value, decimals, currency, name, symbol) => {
    return {
      value: value / Math.pow(10, decimals),
      currency: currency,
      currency_name: name,
      currency_symbol: symbol
    };
  };

  const input = {
    address: tronscanTx.ownerAddress,
    currency: currency,
    currency_name: currencyName,
    currency_symbol: currencySymbol,
    value: valueDecimalized,
    owned: tronscanTx.ownerAddress === originatorAddress,
    fee: false,
  };
  if (ownerTag) {
    input.category = ownerTag;
  }

  const output = {
    address: tronscanTx.toAddress,
    currency: currency,
    currency_name: currencyName,
    currency_symbol: currencySymbol,
    value: valueDecimalized,
    owned: tronscanTx.toAddress === originatorAddress,
    fee: false,
  };
  if (toTag) {
    output.category = toTag;
  }

  const feeInfo = formatCurrencyValue(
    tronscanTx?.cost?.net_fee ?? 0,
    6,
    'trx',
    'trx',
    'trx',
  );

  if (currency.toLowerCase() === 'trx') {
    input['converted_value'] = convertedValue;
    input['converted_currency'] = fiatCurrency;
    output['converted_value'] = convertedValue;
    output['converted_currency'] = fiatCurrency;
    feeInfo['converted_value'] = feeInfo.value * trxExchangeRate;
    feeInfo['converted_currency'] = fiatCurrency;
  }

  let type = 'transfer';
  if (value > 0) {
    if (originatorAddress === tronscanTx.ownerAddress && originatorAddress !== tronscanTx.toAddress) {
      type = 'crypto withdrawal';
    } else if (originatorAddress !== tronscanTx.ownerAddress && originatorAddress === tronscanTx.toAddress) {
      type = 'crypto deposit';
    }
  }
  const amount = formatCurrencyValue(
    value,
    decimals,
    currency,
    currencyName,
    currencySymbol,
  );
  const transformedTx = {
    transaction_id: tronscanTx.hash,
    account_id: accountId,
    type: type,
    amount: [amount],
    total: [amount],
    fee: feeInfo,
    created_at: new Date(tronscanTx.timestamp).toISOString(),
    inputs: [input].filter(input => input.value > 0),
    outputs: [output].filter(output => output.value > 0),
    categories: unique([ownerTag, toTag].filter(tag => tag)),
  };
  return transformedTx;
};

export default getTronTxsLoader;

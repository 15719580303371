import AnalyticsBox from 'components/analytics-box/AnalyticsBox';
import { ReactComponent as UsersIcon } from 'assets/images/svg/users.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/images/svg/analytics.svg';
import { ReactComponent as LightningIcon } from 'assets/images/svg/boltEmptyIcon.svg';
import { ReactComponent as PlusIcon } from 'assets/images/svg/plus-icon.svg';
import { ReactComponent as NftsIcon } from 'assets/images/svg/widgets/nfts.svg';
import { ReactComponent as GamingIcon } from 'assets/images/svg/widgets/gaming.svg';
import { ReactComponent as GamblingIcon } from 'assets/images/svg/widgets/gambling.svg';
import { WIDGETS_ENUM } from 'utils/widgets-helper.js';
import { useContext } from 'react';
import { WidgetContext } from 'context/widget/WidgetContext';

const AnalyticsTopSection = () => {
  const { widgets } = useContext(WidgetContext);

  const totalUsersWidget = widgets.find(widget => widget.name === WIDGETS_ENUM.TOTAL_USERS);
  const activeUsersWidget = widgets.find(widget => widget.name === WIDGETS_ENUM.ACTIVE_USERS);
  const transactionUsersWidget = widgets.find(widget => widget.name === WIDGETS_ENUM.TRANSACTION_COUNT);
  const userGrowthWidget = widgets.find(widget => widget.name === WIDGETS_ENUM.USER_GROWTH);
  const countNftWidget = widgets.find(widget => widget.name === WIDGETS_ENUM.COUNT_NFT);
  const countGamingWidget = widgets.find(widget => widget.name === WIDGETS_ENUM.COUNT_GAMING);
  const countGamblingWidget = widgets.find(widget => widget.name === WIDGETS_ENUM.COUNT_GAMBLING);

  return (
    <div className='analytics-section'>
      {totalUsersWidget && (
        <AnalyticsBox
          description='All of your clients'
          name={WIDGETS_ENUM.TOTAL_USERS}
          withoutPeriod={true}
        >
          <UsersIcon stroke='#7346f3'/>
        </AnalyticsBox>
      )}
      {activeUsersWidget && (
        <AnalyticsBox
          description='Transacting clients'
          withPeriod
          name={WIDGETS_ENUM.ACTIVE_USERS}
        >
          <AnalyticsIcon stroke='#7346f3'/>
        </AnalyticsBox>
      )}
      {transactionUsersWidget && (
        <AnalyticsBox
          description="Your client's transaction"
          withPeriod
          name={WIDGETS_ENUM.TRANSACTION_COUNT}
        >
          <div className='top-section-icon'>
            <LightningIcon />
          </div>
        </AnalyticsBox>
      )}
      {userGrowthWidget && (
        <AnalyticsBox
          percent
          description='Client growth'
          withPeriod
          name={WIDGETS_ENUM.USER_GROWTH}
        >
          <PlusIcon/>
        </AnalyticsBox>
      )}
      {countNftWidget && (
        <AnalyticsBox
          description='NFT transactions made by your clients'
          name={WIDGETS_ENUM.COUNT_NFT}
          withPeriod
        >
          <NftsIcon/>
        </AnalyticsBox>
      )}
      {countGamingWidget && (
        <AnalyticsBox
          description='Web3 Gaming interactions made by your clients'
          name={WIDGETS_ENUM.COUNT_GAMING}
          withPeriod
        >
          <GamingIcon/>
        </AnalyticsBox>
      )}
      {countGamblingWidget && (
        <AnalyticsBox
          description='Web3 Gambling interactions made by your clients'
          name={WIDGETS_ENUM.COUNT_GAMBLING}
          withPeriod
        >
          <GamblingIcon/>
        </AnalyticsBox>
      )}
    </div>
  );
};


export default AnalyticsTopSection;

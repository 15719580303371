import axios from 'axios';

const getExchangeRates = async (pairs, apiUrl, userJwt) => {
  try {
    const response = await axios.get(
      '/v1/exchangerate/current',
      {
        baseURL: apiUrl,
        headers: {
          'Authorization': `Bearer ${userJwt}`,
        },
        params: {
          pairs: pairs.join(','),
        },
      }
    );
    const data = response?.data ?? [];
    const rates = {};
    data.forEach(rateInfo => rates[rateInfo.currency_pair] = rateInfo?.rate);
    return rates;
  } catch (error) {
    console.error(`Problem getting exchange rates: ${JSON.stringify(error.message, null, 2)}`);
    return {};
  }
};

export default getExchangeRates;

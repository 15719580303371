import { clsx } from 'clsx';

const HamburgerMenu = ({ handleHamburgerClick, isMobileMenuOpen }) => {

  return (
    <div className={clsx('mobile-menu-icon', isMobileMenuOpen && 'mobile-menu-icon-open')} onClick={handleHamburgerClick}>
      <button className={clsx('hamburger', isMobileMenuOpen && 'open')}>
        <span className='hamburger-line'></span>
        <span className='hamburger-line'></span>
        <span className='hamburger-line'></span>
      </button>
    </div>
  );
};

export default HamburgerMenu;

import { useFormik } from 'formik';
import { signUpSchema } from 'components/AuthForms/validationSchema';
import authService from 'services/auth-services/auth.service';
import LoadingSpinner from 'components/loaders/loading-spinner/LoadingSpinner';
import MainButton from 'components/buttons/MainButton';
import AcceptPrivacyPolicy from 'components/form/form-bottom-link/AcceptPrivacyPolicy';
import { toast } from 'react-hot-toast';
import { axiosErrorHandler } from 'services/api/axios';
import { useContext, useState } from 'react';
import { UserContext } from 'context/user/UserContext';
import { useNavigate } from 'react-router-dom';
import appRoutes from 'routes/routes';
import { ReactComponent as BoltGreen } from 'assets/images/svg/boltGreenIcon.svg';

const SignUpForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState({
    privacyPolicy : false,
    marketing: false,
  });
  const { setUserEmail } = useContext(UserContext);
  const navigate = useNavigate();
  const { signUp } = authService;
  const { auth } = appRoutes;

  //TODO add new AuthForms values when BE ready to handle them
  const handleSignUpSubmit = async (values) => {
    try {
      setIsLoading(true);
      await signUp(values.email, values.password, values.companyName, values.phoneNumber);
      setUserEmail(values.email);
      navigate(`${auth.verifyEmail}?email=${values.email}`);
    } catch (error) {
      toast.error(axiosErrorHandler(error));
    } finally {
      setIsLoading(false);
    }
  };

  const { values, touched, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      companyName: '',
      phoneNumber: '',
      country: '',
    },
    validationSchema: signUpSchema,
    onSubmit: handleSignUpSubmit,
  });

  const isTouched = Object.keys(touched).some(field => touched[field]);

  const hasFormErrors = Object.keys(errors).length > 0;

  const hasErrors = isTouched && hasFormErrors;

  const isButtonDisabled = !checkedCheckboxes.privacyPolicy || Object.values(values).some(value => value === '');

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleSubmit} className='main-form sign-up-form'>
          <div className='double-input-container'>
            <input
              type='name'
              name='firstName'
              onChange={handleChange}
              value={values.firstName}
              placeholder='First name'
              className='main-form-input'
            />
            <input
              type='name'
              name='lastName'
              onChange={handleChange}
              value={values.lastName}
              placeholder='Last name'
              className='main-form-input'
            />
          </div>
          <div className='double-input-container'>
            <input
              type='name'
              name='companyName'
              onChange={handleChange}
              value={values.companyName}
              placeholder='Company name'
              className='main-form-input'
            />
            <input
              type='tel'
              name='phoneNumber'
              onChange={handleChange}
              value={values.phoneNumber}
              placeholder='Phone number'
              className='main-form-input'
            />
          </div>
          <input
            type='name'
            name='country'
            onChange={handleChange}
            value={values.country}
            placeholder='Country'
            className='main-form-input'
          />
          <input
            type='email'
            name='email'
            onChange={handleChange}
            value={values.email}
            placeholder='Business email'
            className='main-form-input'
          />
          <input
            type='password'
            name='password'
            onChange={handleChange}
            value={values.password}
            placeholder='Password'
            className='main-form-input'
          />
          <MainButton
            type='submit'
            variant='button-black'
            label='SIGN UP'
            icon={<BoltGreen />}
            disabled={isButtonDisabled}
          />
          {hasErrors && (
            <p className='form-error-message'>
              {Object.values(errors)[0]}
            </p>
          )}
        </form>
      )}
      <AcceptPrivacyPolicy setCheckedCheckboxes={setCheckedCheckboxes}/>
    </>
  );
};

export default SignUpForm;

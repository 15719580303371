export const allowedSorts = {
  NAME: 'name',
  PROVIDERS: 'providers',
  RISK: 'risk',
  BALANCE: 'balance',
  ACTIVITY: 'last_tx',
  SOCIALS: 'socials',
  LOCATION: 'country'
};

export const allowedSortDirections = {
  ASC: 'ASC',
  DESC: 'DESC'
};

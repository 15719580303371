import Header from 'components/header/Header';
import { Line, LineChart, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { ReactComponent as ArrowRightIcon } from 'assets/images/svg/right-arrow-with-point.svg';
import { useEffect, useState } from 'react';
import { ReactComponent as Dash } from 'assets/images/svg/dash.svg';
import Select from 'components/select/Select';
import { SelectWithCheckboxGroup } from 'components/selectWithCheckboxGroup/SelectWithCheckboxGroup';
import { loadAccountUsageDetailsData } from '../../utils/billing/account-usage-loader';
import { TIME_RANGE } from '../../utils/billing/utils';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { unique } from '../../utils/common';
import appRoutes from '../../routes/routes';
import { formatDateStrAsShortMonthNoYear } from '../../utils/date-formatter';
import { clsx } from 'clsx';


const DATA_COLORS = [
  '#7346F3',
  '#6DFF96',
  '#F3468F',
  '#FFC542',
  '#FF6C40',
  '#33AAFF',
  '#1E1EEF',
  '#FF33AA',
  '#FF1E1E',
];

const StatusDot = ({ color }) => {
  return (
    <div className='status-dot' style={{ backgroundColor: color }}/>
  );
};

const BillingApiUsage = () => {

  const TABLE_PAGE_SIZE = 7;
  const GROUP_BY_OPTIONS = [
    {
      name: 'project',
      label: 'Group by: Project'
    },
    {
      name: 'service',
      label: 'Group by: Service'
    }
  ];

  const { home } = appRoutes;
  const navigate = useNavigate();

  const [index, setIndex] = useState(1);
  const [groupBy, setGroupBy] = useState(GROUP_BY_OPTIONS[0].name);
  const [time, setTime] = useState('current');
  const [filterProject, setFilterProject] = useState([]);
  const [filterService, setFilterService] = useState([]);
  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState({});
  const [projectIds, setProjectIds] = useState([]);
  const [groupingColorMap, setGroupingColorMap] = useState({});
  const [services, setServices] = useState([]);
  const [tablePage, setTablePage] = useState(1);
  const [domain, setDomain] = useState({});
  const { projects } = useOutletContext();

  useEffect(() => {
    const loadChartData = async () => {
      const {
        chartData: loadedChartData,
        tableData: loadedTableData,
        domainUpperRange
      } = await loadAccountUsageDetailsData();
      setChartData(loadedChartData);
      setTableData(loadedTableData);

      const allProjectIds = extractValuesByKeyFromData(loadedChartData['long'], 'project_id');
      setProjectIds(allProjectIds);
      setGroupingColorMap(buildColorMapFromKeys(allProjectIds));

      const allServices = extractValuesByKeyFromData(loadedChartData['long'], 'service');
      setServices(allServices);
      setFilterService(makeServiceCheckboxes(allServices));

      setDomain(domainUpperRange);
    };
    loadChartData().catch(console.error);
  }, []);

  useEffect(() => {
    const projectsCheckboxes = projects
      .filter(project => !project.demo)
      .map(project => {
        return {
          label: project.name,
          api_id: project.api_id,
          checked: true
        };
      });
    setFilterProject(projectsCheckboxes);
  }, [projects]);

  const handleBackButton = () => {
    navigate(home.billing);
  };

  const buildColorMapFromKeys = (keys) => {
    return unique(keys).reduce((acc, key, idx) => {
      acc[key] = DATA_COLORS[idx % DATA_COLORS.length];
      return acc;
    }, {});
  };

  const getFilterDisabledProjectIds = () => {
    return new Set(filterProject
      .filter(project => !project.checked)
      .map(project => String(project.api_id))
    );
  };

  const getFilterDisabledServices = () => {
    return new Set(filterService
      .filter(service => !service.checked)
      .map(service => service.label)
    );
  };

  const getFilteredChartData = (allData) => {
    const disabledProjects = getFilterDisabledProjectIds();
    const filteredByProject = allData.filter(entry => !disabledProjects.has(String(entry.project_id)));

    const disabledServices = getFilterDisabledServices();
    const filteredByProjectAndService = filteredByProject.filter(entry => !disabledServices.has(entry.service));

    const aggregatedDataByDate = filteredByProjectAndService.reduce((acc, entry) => {
      Object.entries(entry.days).forEach(([dateStr, credits]) => {
        if (!acc[dateStr]) {
          acc[dateStr] = {};
        }
        let key = entry.project_id;
        if (groupBy === 'service') {
          key = entry.service;
        }
        if (!acc[dateStr][key]) {
          acc[dateStr][key] = 0;
        }
        acc[dateStr][key] += credits;
      });
      return acc;
    }, {});

    const dataInChartFormat = Object.entries(aggregatedDataByDate).map(([dateStr, creditsByEntity]) => {
      return {
        date: formatDateStrAsShortMonthNoYear(dateStr),
        ...creditsByEntity
      };
    });
    return dataInChartFormat;
  };

  const extractValuesByKeyFromData = (relevantData, key) => {
    const projectIdsWithDuplicates = relevantData.map(entry => entry[key]);
    return unique(projectIdsWithDuplicates);
  };

  const makeServiceCheckboxes = (services) => {
    return services.map(service => {
      return {
        label: service,
        checked: true
      };
    });
  };

  const handleGroupBy = (option) => {
    setGroupBy(option.name);
    setGroupingColorMap(buildColorMapFromKeys(option.name === 'project' ? projectIds : services));
    setTablePage(1);
  };
  const handleTimeRange = (option) => {
    setTime(Object.entries(TIME_RANGE).find(([_, value]) => value === option.label)[0] ?? option.label);
    setTablePage(1);
  };

  const getProjectById = (id) => {
    return (projects ?? []).find(p => String(p.api_id) === id);
  };

  const getFilteredTableData = (allData) => {
    const disabledProjects = getFilterDisabledProjectIds();
    const disabledServices = getFilterDisabledServices();
    const filteredEntries = allData.filter(entry => {
      return !disabledProjects.has(String(entry.project_id)) && !disabledServices.has(entry.service);
    });

    filteredEntries.sort((a, b) => {
      if (groupBy === 'project') {
        return a.project_id - b.project_id;
      }
      return a.service.localeCompare(b.service);
    });
    return filteredEntries;
  };

  const getLastChartPageNum = () => {
    if (!tableData[time]) {
      return 1;
    }
    return Math.max(1, Math.ceil(getFilteredTableData(tableData[time]).length / TABLE_PAGE_SIZE));
  };

  const moveTablePage = (delta) => {
    const newPageNumber = Math.min(Math.max(1, tablePage + delta), getLastChartPageNum());
    setTablePage(newPageNumber);
  };

  const getTablePage = () => {
    if (!tableData[time]) {
      return [];
    }
    const pageStart = (tablePage - 1) * TABLE_PAGE_SIZE;
    const pageEnd = pageStart + TABLE_PAGE_SIZE;
    return getFilteredTableData(tableData[time]).slice(pageStart, pageEnd);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      if (active && payload != null && payload[index] != null && index !== null) {
        return (
          <div className='custom-tooltip'>
            <p className='title'>{payload[index].payload[payload[index].name]}</p>
            <p className='subtitle'>{payload[index].payload.date}</p>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className='api-usage api-usage-page'>
      <div className='clickable' onClick={handleBackButton}>
        <Header headerTitle='← Billing/ API usage' />
      </div>
      <div className='api-usage-content'>
        <div className='left-side'>
          <div className='shadow-box billing-api-usage'>
            <p className='api-usage-title'>usage vs cost overview</p>
            <div className='api-usage-chart horizontal-bar-chart-wrapper'>
              <ResponsiveContainer width='100%' height={270}>
                <LineChart
                  data={getFilteredChartData(chartData[time] ?? [])}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <YAxis
                    domain={[0, domain[time] ?? 100000]}
                    tickMargin='20'
                    axisLine={false}
                    tickLine={false}
                  />
                  <XAxis
                    dataKey='date'
                    tick={{ width: 10 }}
                    tickMargin='9'
                    axisLine={false}
                    tickLine={false}
                  />
                  <Tooltip content={<CustomTooltip/>} cursor={false}/>
                  {((groupBy === 'project' ? projectIds : services) ?? []).map((key, keyIdx) => {
                    const color = groupingColorMap[key] ?? '#000000';
                    return <Line
                      key={key}
                      type='monotone'
                      dataKey={key}
                      stroke={color}
                      dot={false}
                      strokeWidth={4}
                      activeDot={{
                        strokeWidth: index === keyIdx ? 7 : 0,
                        className: clsx(index === keyIdx && 'line-chart-active-dot'),
                        fill: index === keyIdx ? color : 'transparent',
                        onMouseEnter: () => setIndex(keyIdx),
                        r: index === keyIdx ? 10 : 5
                      }}
                    />;
                  })}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className='shadow-box api-usage-table'>
            <div className='api-usage-table-header'>
              <p className='api-usage-title'>project ↓</p>
              <p className='api-usage-title'>service</p>
              <p className='api-usage-title'>endpoint</p>
              <p className='api-usage-title'>credits</p>
              <p className='api-usage-title'>api calls</p>
            </div>

            {!tableData || !tableData[time] || tableData[time].length === 0
              ? <div className='api-usage-no-data-row'>
                  No usage for the selected period
              </div>
              : getTablePage().map((row, idx) => {
                return (
                  <div className='api-usage-table-row' key={idx}>
                    <div className='api-usage-table-cell'>
                      <StatusDot color={groupingColorMap[groupBy === 'project' ? row.project_id : row.service] ?? '#000000'}/>
                      <p className='api-usage-table-value'>{getProjectById(row.project_id)?.name ?? 'Unknown project'}</p>
                    </div>
                    <div className='api-usage-table-cell'>
                      <ArrowRightIcon/>
                      <p className='api-usage-table-value'>{row.service}</p>
                    </div>
                    <p className='api-usage-table-value'>{row.method}</p>
                    <p className='api-usage-table-value'>{row.credits}</p>
                    <p className='api-usage-table-value'>{row.count}</p>
                  </div>
                );
              })
            }
          </div>
          <div className='pagination-container'>
            <div className={clsx('pagination-page-container', tablePage !== 1 && 'pagination-active')}>
              <button className='dash-button-container' onClick={() => moveTablePage(-1)}>
                <Dash/>
              </button>
              <span className='pagination-text'>
                Previous page
              </span>
            </div>
            <div className='pagination-divider'>
            </div>
            <div className={clsx('pagination-page-container', tablePage !== getLastChartPageNum() && 'pagination-active')}>
              <span className='pagination-text'>
                Next page
              </span>
              <button className='dash-button-container' onClick={() => moveTablePage(1)}>
                <Dash className='dash-right'/>
              </button>
            </div>
          </div>
        </div>
        <div>
          <p className='api-usage-filter-header'>Filter data:</p>
          <Select
            options={GROUP_BY_OPTIONS}
            onOptionClick={handleGroupBy}
            value={GROUP_BY_OPTIONS.find(option => option.name === groupBy).label}
          />
          <Select
            options={[{ label: 'Current month' }, { label: 'Last month' }, { label: 'Last 90 days' }]}
            onOptionClick={handleTimeRange}
            value={TIME_RANGE[time]}
          />
          <SelectWithCheckboxGroup
            checkboxes={filterProject}
            setCheckboxes={setFilterProject}
            label='projects'
          />
          <SelectWithCheckboxGroup
            checkboxes={filterService}
            setCheckboxes={setFilterService}
            label='services'
          />
        </div>
      </div>
    </div>
  );
};

export default BillingApiUsage;

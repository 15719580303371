import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import appRoutes from './routes';
import LoginPage from 'pages/auth/login/LoginPage';
import PageNotFound from 'pages/errors/404/PageNotFound';
import SignUpPage from 'pages/auth/sign-up/SignUpPage';
import ConfirmEmail from 'pages/auth/confirm-email/ConfirmEmail';
import VerifySignUp from 'pages/auth/verify-sign-up/VerifySignUp';
import ResetPasswordPage from 'pages/auth/reset-password/ResetPasswordPage';
import ConfirmNewPassword from 'pages/auth/confirm-new-password/ConfirmNewPassword';
import ProtectedRoutes from './ProtectedRoutes';
import MyProjects from 'pages/my-projects/MyProjects';
import Dashboard from 'pages/dashboard/Dashboard';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import MyAccount from 'pages/my-account/MyAccount';
import MyProjectsLayout from 'layouts/my-projects-layout/SingleProjectsLayout.jsx';
import Analytics from 'pages/single-project-analytics/Analytics.jsx';
import Users from 'pages/single-project-users/Users.jsx';
import Widgets from 'pages/single-project-widgets/Widgets.jsx';
import ApiKeys from 'pages/single-project-api-keys/ApiKeys.jsx';
import ApiKeysPublic from 'pages/single-project-api-keys-public/ApiKeysPublic';
import ProjectSettings from 'pages/project-settings/ProjectSettings';
import OnboardingArticle from 'pages/onboarding-article/OnboardingArticle';
import Billing from 'pages/billing/Billing';
import BillingManagePlan from 'pages/billing/BillingManagePlan';
import BillingApiUsage from 'pages/billing/BillingApiUsage';
import CustomerDetails from 'pages/customer-details/CustomerDetails';
import UsersManagement from 'pages/users-management/UsersManagement';
import Explorer from 'pages/single-project-explorer/Explorer';
import Debug from '../pages/debug/debug';
import { UserContext } from '../context/user/UserContext';
import { useContext } from 'react';
import CompanySettings from '../pages/company-settings/CompanySettings';
import AMLQuickCheck from '../pages/single-project-aml-quick-check/AMLQuickCheck';
import Deposits from '../pages/single-project-deposits/Deposits';
import Payouts from '../pages/single-project-payouts/Payouts';
import DepositsAdmin from '../pages/deposits-admin/DepositsAdmin';

function AppRoutes() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  const { getConfig } = useContext(UserContext);

  return (
    <BrowserRouter>
      <SentryRoutes>

        <Route
          element={
            <ProtectedRoutes>
              <DashboardLayout/>
            </ProtectedRoutes>
          }>
          <Route path={appRoutes.home.dashboard} element={<Dashboard/>}/>
          <Route path={appRoutes.home.myProjects} element={<MyProjects/>}/>
          <Route path={appRoutes.home.myAccount} element={<MyAccount/>}/>
          <Route path={appRoutes.home.onboarding} element={<OnboardingArticle/>}/>
          <Route path={appRoutes.home.billing} element={<Billing/>}/>
          <Route path={appRoutes.home.billingManagePlan} element={<BillingManagePlan/>}/>
          <Route path={appRoutes.home.billingApiUsage} element={<BillingApiUsage/>}/>
          <Route path={appRoutes.home.usersManagement} element={<UsersManagement/>}/>
          {getConfig('explorer') && <Route path={appRoutes.home.explorer} element={<Explorer/>}/>}
          <Route path={appRoutes.home.amlQuickCheck} element={<AMLQuickCheck/>}/>
          <Route path={appRoutes.home.companySettings} element={<CompanySettings/>}/>
        </Route>

        <Route
          element={
            <ProtectedRoutes>
              <MyProjectsLayout/>
            </ProtectedRoutes>
          }>
          <Route path={appRoutes.home.analytics} element={<Analytics/>}/>
          <Route path={appRoutes.home.clients} element={<Users/>}/>
          <Route path={appRoutes.home.customerDetails} element={<CustomerDetails/>}/>
          <Route path={appRoutes.home.widgets} element={<Widgets/>}/>
          <Route path={appRoutes.home.deposits} element={<Deposits />} />
          <Route path={appRoutes.home.payouts} element={<Payouts />} />
          <Route path={appRoutes.home.apiKeys} element={<ApiKeys/>}/>
          <Route path={appRoutes.home.apiKeysPublic} element={<ApiKeysPublic/>}/>
          <Route path={appRoutes.home.settings} element={<ProjectSettings/>}/>
          <Route path={appRoutes.home.depositsAdmin} element={<DepositsAdmin />} />
        </Route>

        <Route path={appRoutes.auth.signUp} element={<SignUpPage/>}/>
        <Route path={appRoutes.auth.verifyEmail} element={<VerifySignUp/>}/>
        <Route path={appRoutes.auth.confirmEmail} element={<ConfirmEmail/>}/>
        <Route path={appRoutes.auth.login} element={<LoginPage/>}/>
        <Route path={appRoutes.auth.resetPassword} element={<ResetPasswordPage/>}/>
        <Route
          path={appRoutes.auth.confirmNewPassword}
          element={<ConfirmNewPassword/>}
        />

        <Route path={appRoutes.debug} element={<Debug/>}/>
        <Route path='*' element={<PageNotFound/>}/>
      </SentryRoutes>
    </BrowserRouter>
  );
}

export default AppRoutes;

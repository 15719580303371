import styles from './payouts.module.scss';
import globalStyles from '../customer-details/style.module.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useDeposits from '../../services/deposits/deposits-service';
import CustomerDetailsBox from '../customer-details/CustomerDetailsBox';
import { ReactComponent as WalletIcon } from '../../assets/images/svg/wallet.svg';
import CopyToClipboardWithIcon from '../../components/copy-to-clipboard-with-icon/CopyToClipboardWithIcon';
import Loader from '../../components/loaders/loader/Loader';
import PayoutFilters from '../../components/payouts/payout-filters/PayoutFilters';
import { dateStrToTimestampSeconds } from '../../utils/date-formatter';
import { clsx } from 'clsx';
import { getTemporaryJwt } from '../../services/temporary-user/temporary-user-service';
import { maybePlural } from '../../utils/billing/utils';
import ExportButton from '../../components/export-button/ExportButton';

const Payouts = () => {
  const { id } = useParams();
  const { getPayouts } = useDeposits();

  const [since, setSince] = useState('');
  const [until, setUntil] = useState('');

  const [payouts, setPayouts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadData = async () => {
    setIsLoading(true);
    const jwt = await getTemporaryJwt(id);
    const sinceTimestamp = dateStrToTimestampSeconds(since, false);
    const untilTimestamp = dateStrToTimestampSeconds(until, true);
    const obtainedPayouts = await getPayouts(id, jwt, sinceTimestamp, untilTimestamp);
    setPayouts(obtainedPayouts);
    setIsLoading(false);
  };

  const handleApplyFilters = () => {
    setPayouts([]);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return <div>
    <div className={globalStyles.customers}>
      <CustomerDetailsBox
        title='Your Binance Pay Payouts'
        icon={<WalletIcon/>}
        buttons={<ExportButton data={payouts} disabled={isLoading} filenameBase='binance-pay-payouts-export' />}
      >
        <PayoutFilters
          since={since}
          setSince={setSince}
          until={until}
          setUntil={setUntil}
          handleApplyFilters={handleApplyFilters}
          disabled={isLoading}
        />
        <div className={styles.payoutsTableContainer}>
          <div className={styles.tableHeader}>
            <div>Time</div>
            <div>Payout ID</div>
            <div>Amount</div>
            <div>Currency</div>
            <div>Network</div>
            <div>Transaction ID</div>
          </div>
          {payouts.map(payout => {
            const [date, time] = payout.created_at.split('.')[0].split('T');
            return <div className={styles.tableRow} key={payout.id}>
              <div className={styles.rowDataContainer}>
                <div className={styles.dataCellRow}>
                  {date}
                </div>
                <div className={styles.dataCellRow}>
                  {time}
                </div>
              </div>
              <div className={styles.rowDataContainer}>{payout.id}</div>
              <div className={styles.rowDataContainer}>
                <span className={clsx(styles.strong, styles.dark)}>
                  {payout.amount}
                </span>
              </div>
              <div className={styles.rowDataContainer}>
                <span className={styles.dark}>
                  {payout.currency}
                </span>
              </div>
              <div className={styles.rowDataContainer}>{payout.network}</div>
              <div className={styles.rowDataContainer}>
                <CopyToClipboardWithIcon
                  displayText={payout.transaction_id}
                  copyValue={payout.transaction_id}
                  tooltipDisplayedData={payout.transaction_id}
                >
                  {payout.transaction_id}
                </CopyToClipboardWithIcon>
              </div>
            </div>;
          })}
        </div>
        <div className={styles.total}>
          Found {payouts.length} {maybePlural('record', payouts.length !== 1)}.
        </div>
      </CustomerDetailsBox>
    </div>
  </div>;
};

export default Payouts;

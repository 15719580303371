import PlanLimitsBanerWidgets from './PlanLimitsBanerWidgets';
import { ReactComponent as UserIcon } from 'assets/images/svg/users.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/images/svg/analytics.svg';
import { ReactComponent as PlusIcon } from 'assets/images/svg/plus-icon.svg';

const BannerWithPlanLimits = () => {

  return (
    <div className='banner-upgrade-limits'>
      <div className='upgrade-limits-container start-trial'>
        <div className='start-trial-text'>
          <p>ANALYTICS ADD-ON</p>
          <p className='better-text'>Better insights, faster.</p>
          <p className='trial-text'>Start 30 days trial &gt;</p>
        </div>
        <div className='widgets-container'>
          <PlanLimitsBanerWidgets widgetName='Total Clients' icon={<UserIcon/>}/>
          <PlanLimitsBanerWidgets widgetName='Active Clients' icon={<AnalyticsIcon/>}/>
          <PlanLimitsBanerWidgets widgetName='Client Growth' icon={<PlusIcon/>}/>
        </div>
      </div>
      <div className='upgrade-limits-container upgrade-now'>
        <p className='upgrade-now-title'>Upgrade your plan, get higher limits <br/> and more features</p>
        <button className='upgrade-now-button'>Upgrade now &gt;</button>
      </div>
    </div>
  );
};

export default BannerWithPlanLimits;

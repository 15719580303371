import { useState } from 'react';
import styles from '../style.module.scss';
import { clsx } from 'clsx';
import MainButton from '../../../components/buttons/MainButton';
import { ReactComponent as DeleteIcon } from 'assets/images/svg/trash.svg';

const DeleteUserModalContent = ({ deletedUserName, handleSubmit }) => {
  const [name, setName] = useState('');

  return <div className={styles.inviteModal}>
    <div className={clsx(styles.modalTitle, styles.modalTitleIconAbove)}>
      <div className={clsx(styles.icon, styles.alertIcon)}>
        <DeleteIcon />
      </div>
      <span className={styles.title}>
        Delete user
      </span>
    </div>
    <div className={styles.modalDescription}>
      Before proceeding, please confirm that you want to delete this user:<br />
      <strong>{deletedUserName}</strong><br />
      Are you sure you want to continue?
    </div>
    <form className={styles.form}>
      <input
        type='name'
        name='name'
        onChange={(e) => setName(e.target.value)}
        value={name}
        placeholder="Write user's name to confirm"
        className={styles.formInput}
      />
      <MainButton
        type='submit'
        variant='button-error'
        label='DELETE USER'
        icon={<DeleteIcon />}
        onClick={handleSubmit}
        disabled={name !== deletedUserName}
      />
    </form>
  </div>;
};

export default DeleteUserModalContent;

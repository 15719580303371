import { Link } from 'react-router-dom';

function FormBottomLink(props) {
  const { externalLink, linkContent, linkPath } = props;

  return (
    <div className='accept-privacy-policy bottom-link'>
      {externalLink ? (
        <a
          rel='noopener noreferrer'
          href={linkPath}
          target='_blank'
          className='form-helper-bottom-link'>
          {linkContent}
        </a>
      ) : (
        <Link to={linkPath} className='form-helper-bottom-link'>
          {linkContent}
        </Link>
      )}
    </div>
  );
}

export default FormBottomLink;

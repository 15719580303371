import WidgetBox from './WidgetBox';
import WidgetBanner from 'pages/single-project-widgets/WidgetBanner';
import { useContext, useEffect } from 'react';
import { WidgetContext } from 'context/widget/WidgetContext';
import Loader from 'components/loaders/loader/Loader.jsx';
import { useOutletContext, useParams } from 'react-router-dom';

const Widgets = () => {
  const {
    getWidgets,
    loading,
    parsedWidgets,
  } = useContext(WidgetContext);
  const { id } = useParams();
  const { project } = useOutletContext();

  useEffect(() => {
    getWidgets(id, project.currency);
  }, [id, project]);

  if (loading) return <Loader/>;

  return (
    <>
      <p className='widgets-subtitle'>
        Choose from available widgets
      </p>
      <div className='widgets-page-container'>
        <div className='widgets-container'>
          {parsedWidgets.map(widget => <WidgetBox
            key={widget.label}
            cardTitle={widget.label}
            name={widget.name}
            icon={widget.icon}
            isToggle={widget.visible}
            tooltipContent={widget.tooltip}
          />
          )}
        </div>
        <WidgetBanner/>
      </div>
    </>
  );
};


export default Widgets;

export const getTimeAgo = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const timeDiff = now.getTime() - date.getTime();

  if (timeDiff < 60000) {
    return `${Math.round(timeDiff / 1000)}  seconds ago`;
  } else if (timeDiff < 3600000) {
    return `${Math.round(timeDiff / 60000)}  minutes ago`;
  } else if (timeDiff < 86400000) {
    return `${Math.round(timeDiff / 3600000)}  hours ago`;
  } else {
    return `${Math.round(timeDiff / 86400000)}  days ago`;
  }
};

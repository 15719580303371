import styles from './style.module.scss';
import CustomerDetailsBox from 'pages/customer-details/CustomerDetailsBox';
import { ReactComponent as WalletIcon } from 'assets/images/svg/wallet.svg';
import { dateObjectToDateString } from 'utils/date-formatter';
import { useContext, useEffect, useState } from 'react';
import { numberToPrice } from '../../utils/billing/plan-formatter';
import * as Sentry from '@sentry/react';
import { UserContext } from '../../context/user/UserContext';

const AccountOverview = ({ balanceData, customerDetails }) => {
  const [currency, setCurrency] = useState('USD');
  const [balance2, setBalance2] = useState('-');
  const [totalReceived, setTotalReceived] = useState('-');
  const [totalReceived2, setTotalReceived2] = useState('-');
  const [totalSent, setTotalSent] = useState('-');
  const [totalSent2, setTotalSent2] = useState('-');
  const [lastSeen, setLastSeen] = useState('-');
  const [firstSeen, setFirstSeen] = useState('-');
  const [txCount, setTXCount] = useState('-');
  const { getConfig } = useContext(UserContext);
  const isAmlDemo = getConfig('is_aml_demo');

  useEffect(() => {
    if (customerDetails !== undefined) {
      setCurrency(customerDetails.currency);
      //setBalance(data.data.balance); // we ignore this balance
      setTotalReceived(customerDetails.total_received);
      setTotalSent(customerDetails.total_sent);
      setLastSeen(customerDetails.last_tx);
      setFirstSeen(customerDetails.first_tx);
      setTXCount(customerDetails.tx_count);
      if (customerDetails.low_risk_tx_count + customerDetails.medium_risk_tx_count + customerDetails.high_risk_tx_count !== customerDetails.tx_count) {
        Sentry.captureException(Error('TX count != low+medium+high TX count'));
      }
    } else {
      setCurrency('USD');
      //setBalance('-'); // we ignore this balance
      setTotalReceived('-');
      setTotalSent('-');
      setLastSeen('-');
      setFirstSeen('-');
      setTXCount('-');
    }
  }, [customerDetails]);

  useEffect(() => {
    if (balanceData !== undefined) {
      let sum = 0.0;
      balanceData.balance_sum.forEach((x) => {
        if (x?.converted_value !== undefined) {
          sum += x.converted_value;
        }
      });
      setBalance2(sum);
    } else {
      setBalance2('-');
    }
  }, [balanceData]);

  // FIX balance
  useEffect(() => {
    if (balance2 !== '-' && totalSent !== '-' && totalReceived !== '-') {
      let balanceCalculated = totalReceived - totalSent;
      if (balanceCalculated === balance2) {
        Sentry.captureException(Error('total_received - total_sent != balance'));
      } else {
        if (balanceCalculated > balance2) {
          // we miss sent probably
          setTotalSent2(totalReceived - balance2);
          setTotalReceived2(totalReceived);
        } else {
          // we miss received probably
          setTotalSent2(totalSent);
          setTotalReceived2(balance2 + totalSent);
        }
      }
    }
  }, [balance2, totalSent, totalReceived]);
  // end of FIX balance

  return (
    <CustomerDetailsBox title='Account overview' icon={<WalletIcon/>}>
      <div className={styles.accountOverview}>
        <span className={isAmlDemo ? styles.tableHeaderAmlDemo : styles.tableHeader}>
          <span>Total balance</span>
          {!isAmlDemo && <span>Total received</span>}
          {!isAmlDemo && <span>Total sent</span>}
          <span>Last seen</span>
          <span>First seen</span>
          <span># of transactions</span>
        </span>
        <span className={isAmlDemo ? styles.tableContentAmlDemo : styles.tableContent}>
          <span>{numberToPrice(balance2, currency, 2)}</span>
          {!isAmlDemo && <span>{numberToPrice(totalReceived2, currency, 2)}</span>}
          {!isAmlDemo && <span>{numberToPrice(totalSent2, currency, 2)}</span>}
          <span>
            {(lastSeen === '-' || lastSeen === 0) ? '-' : dateObjectToDateString(new Date(lastSeen * 1000))}
          </span>
          <span>
            {(firstSeen === '-' || firstSeen === 0) ? '-' : dateObjectToDateString(new Date(firstSeen * 1000))}
          </span>
          <span>{txCount}</span>
        </span>
      </div>
    </CustomerDetailsBox>
  );
};

export default AccountOverview;

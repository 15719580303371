export const createHeaderFromPathname = (pathname, name, customerName) => {
  const parsed = pathname.replace('-', ' ')
    .substring(1)
    .split('/');
  const title = parsed[0];
  const section = parsed[2];

  const customerPart = customerName ? ` / ${customerName}` : '';

  return section === 'details' ? `${name} / Detail view` : `${title} / ${name} / ${section} ${customerPart}`;
};

import { ReactComponent as ResendIcon } from 'assets/images/svg/refresh.svg';
import { BarLoader } from 'react-spinners';

const AnalyticsUpload = ({ importTitle }) => {

  return (
    <div className='page-content-centered'>
      <div className='content-wrapper'>
        <div className='analytics-icon-container'>
          <ResendIcon/>
        </div>
        <p className='analytics-title'>{importTitle}</p>
        <p className='analytics-details'>
          Please sit tight and wait for your analytics dashboard to be generated. We&apos;ll notify you by email when it&apos;s ready, which should take up to 24 hours.
        </p>
        <BarLoader color='#7346f3' width='405px' className='analytics-loader'/>
      </div>
    </div>
  );
};

export default AnalyticsUpload;

const useFeatureFlag = () => {
  const getFeatureFlag = (key) => {
    const value = localStorage.getItem(key);
    if (!value) {
      return undefined;
    }
    return value === 'true';
  };

  return {
    getFeatureFlag
  };
};

export default useFeatureFlag;

import styles from './style.module.scss';
import RiskScore from 'components/risk-score/RiskScore';
import { valueExists } from '../../utils/common';
import { clsx } from 'clsx';

const CustomerDetailsBox = ({ children, icon, title, riskScore, legend, buttons }) => {
  return (
    <div className={styles.customerBox}>
      <div className={clsx(styles.title, styles.wide)}>
        <div className={styles.titleSection}>
          <span className={styles.icon}>{icon}</span>
          <span className={styles.titleText}>{title}</span>
          {valueExists(riskScore) && (
            <span className={styles.risk}>
              <RiskScore riskScore={riskScore}/>
              <span>{`${riskScore}/100`} Risk score</span>
            </span>
          )}
        </div>
        {legend && <div className={styles.titleSection}>
          {legend}
        </div>}
        {buttons && <div className={styles.titleSection}>
          {buttons}
        </div>}
      </div>
      {children}
    </div>
  );
};

export default CustomerDetailsBox;

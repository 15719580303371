import { ReactComponent as AnalyticsIcon } from 'assets/images/svg/analytics.svg';
import { ReactComponent as UsersIcon } from 'assets/images/svg/users.svg';
import { ReactComponent as WidgetsIcon } from 'assets/images/svg/widgets.svg';
import { ReactComponent as ApiKeyPrivate } from 'assets/images/svg/api-keys-private.svg';
import { ReactComponent as ApiKeyPublic } from 'assets/images/svg/api-keys-public.svg';
import { ReactComponent as SettingIcon } from 'assets/images/svg/settings.svg';
import { ReactComponent as HomeIcon } from 'assets/images/svg/home-button.svg';
import { ReactComponent as WalletIcon } from 'assets/images/svg/wallet.svg';
import { ReactComponent as ImportIcon } from 'assets/images/svg/import.svg';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import MenuButton from 'components/buttons/menu-button/MenuButton.jsx';
import clsx from 'clsx';
import { UserContext } from '../../context/user/UserContext';
import { useContext } from 'react';
import appRoutes from '../../routes/routes';

const SingleProjectSidebar = ({ isDemoProject, hideProject, canGoHome }) => {
  const { id } = useParams();
  const disabledKeysTooltipString = 'This function is not enabled on demo project. If you would like to interact with API, please create your own project and generate key for it.';
  const { getConfig } = useContext(UserContext);
  const pathParams = { id };
  const isForDeposits = getConfig('deposits');
  const menuConfig = [
    {
      label: 'Home',
      icon: <HomeIcon />,
      path: appRoutes.home.dashboard,
      tooltip: 'Home',
      allowed: canGoHome,
    },
    {
      label: 'Analytics',
      icon: <AnalyticsIcon />,
      path: generatePath(appRoutes.home.analytics, pathParams),
      tooltip: 'Analytics',
      allowed: getConfig('analytics') && !isForDeposits,
    },
    {
      label: 'Clients',
      icon: <UsersIcon />,
      path: generatePath(appRoutes.home.clients, pathParams),
      tooltip: 'Clients',
      allowed: getConfig('project_users') && !isForDeposits,
    },
    {
      label: 'Widgets',
      icon: <WidgetsIcon />,
      path: generatePath(appRoutes.home.widgets, pathParams),
      tooltip: 'Widgets',
      allowed: !getConfig('is_aml_demo') && !isForDeposits,
    },
    {
      label: 'Deposits',
      icon: <WalletIcon />,
      path: generatePath(appRoutes.home.deposits, pathParams),
      tooltip: 'Payouts',
      allowed: isForDeposits,
    },
    {
      label: 'Binance Pay',
      icon: <ImportIcon />,
      path: generatePath(appRoutes.home.payouts, pathParams),
      tooltip: 'Binace Pay',
      allowed: isForDeposits && getConfig('deposit_payouts'),
    },
    {
      label: 'API keys',
      icon: <ApiKeyPrivate />,
      path: generatePath(appRoutes.home.apiKeys, pathParams),
      tooltip: 'API keys',
      allowed: isForDeposits || !getConfig('is_aml_demo'),
      disabledForDemo: true,
    },
    {
      label: 'Public keys',
      icon: <ApiKeyPublic />,
      path: generatePath(appRoutes.home.apiKeysPublic, pathParams),
      tooltip: 'API keys public',
      allowed: !getConfig('is_aml_demo') && !isForDeposits,
      disabledForDemo: true,
    },
    {
      label: 'Settings',
      icon: <SettingIcon />,
      path: generatePath(appRoutes.home.settings, pathParams),
      tooltip: 'Settings',
      allowed: getConfig('project_settings') && !isForDeposits,
    },
  ];

  const handleNavClick = (disabledForDemo) => (e) => {
    if (disabledForDemo && isDemoProject) {
      e.preventDefault();
    }
  };

  return (
    <>
      {menuConfig
        .filter(menuItem => menuItem.allowed)
        .filter(menuItem => !hideProject)
        .filter(menuItem => !menuItem.featureFlag || getConfig(menuItem.featureFlag))
        .map((item, index) => (
          <NavLink
            onClick={handleNavClick(item.disabledForDemo)}
            key={index}
            to={item.path}
            className={clsx(isDemoProject && item.disabledForDemo ? 'disabled tooltip-disabled-nav-link' : 'tooltip')}
            data-tooltip={item.tooltip}
            disabled={isDemoProject && item.disabledForDemo}
            data-tooltip-for-disabled-nav-link={disabledKeysTooltipString}
          >
            <MenuButton
              disabled={isDemoProject && item.disabledForDemo}
              icon={item.icon} alt='icon'
            >
              {item.label}
            </MenuButton>
          </NavLink>
        ))}
    </>
  );
};

export default SingleProjectSidebar;

const ToggleSwitch = ({ isToggled, onToggle, isDisabled }) => {
  return (
    <label className='toggle-switch'>
      <input type='checkbox' className='checkbox' checked={isToggled} onChange={onToggle}/>
      <span className={isDisabled ? 'load' : 'slider'}/>
    </label>
  );
};

export default ToggleSwitch;

import { useContext, useEffect } from 'react';
import { ReactComponent as AlertNotification } from 'assets/images/svg/notifications/alert-notification.svg';
import { ReactComponent as ErrorNotification } from 'assets/images/svg/notifications/error-notification.svg';
import { ReactComponent as InfoNotification } from 'assets/images/svg/notifications/info-notification.svg';
import { ReactComponent as SuccessNotification } from 'assets/images/svg/notifications/success-notification.svg';
import { ReactComponent as CloseIcon } from 'assets/images/svg/cross.svg';
import { notificationsConfig, NOTIFICATIONS_ENUM } from './notificationsConfig';
import { NotificationContext } from 'context/notification/NotificationContext';

const Notification = () => {
  const { notificationData, setNotificationData, notificationType } = useContext(NotificationContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setNotificationData(null);
    }, 7000);

    return () => clearTimeout(timer);
  }, [notificationData]);

  if (notificationData)
    return (
      <div className='notification'>
        {notificationType === NOTIFICATIONS_ENUM.ALERT && <AlertNotification/>}
        {notificationType === NOTIFICATIONS_ENUM.ERROR && <ErrorNotification/>}
        {notificationType === NOTIFICATIONS_ENUM.INFO && <InfoNotification/>}
        {notificationType === NOTIFICATIONS_ENUM.SUCCESS && <SuccessNotification/>}
        <div className='notification-text'>
          <p className='notification-title'>
            {notificationsConfig[notificationData][notificationType].title}
          </p>
          <p className='notification-description'>
            {notificationsConfig[notificationData][notificationType].description}
          </p>
          <div className='close' onClick={() => setNotificationData(null)}>
            <CloseIcon />
          </div>
        </div>
      </div>
    );
};

export default Notification;

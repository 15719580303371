import { useEffect, useState } from 'react';

const ApiKeysCounter = ({ onClick }) => {
  const [counter, setCounter] = useState(180);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <span className='copy-visible copy-box' onClick={onClick}>
      {counter}s TO COPY
    </span>
  );
};

export default ApiKeysCounter;

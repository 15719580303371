import { PERIOD_LABELS } from 'utils/widgets-helper';
import { ReactComponent as Dash } from 'assets/images/svg/dash.svg';
import AnalyticsSettings from 'components/popups/AnalyticsSettings';
import { useRef, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { getStaticPath } from 'services/api/axios';
import { useContext } from 'react';
import { WidgetContext } from 'context/widget/WidgetContext';
import { useOutsideClick } from '../../hooks';
import { clsx } from 'clsx';

const ChangePeriodSelect = ({ withoutPeriod, name }) => {
  const [settingsOpen, setSettingOpen] = useState(false);
  const { id } = useParams();
  const ref = useRef(null);
  const { widgets, setWidgets, setCurrentTimeRange } = useContext(WidgetContext);
  const widget = widgets.find(widget => widget.name === name);
 
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSettingOpen(false);
    }
  };

  useOutsideClick(handleClickOutside);

  const replaceWidget = (widget) => {
    const index = widgets.findIndex((obj) => obj.name === widget.name);

    if (index !== -1) {
      const updatedWidgets = [...widgets];
      updatedWidgets[index] = widget;
      setWidgets(updatedWidgets);
    }
  };

  const handlePeriod = async (e) => {
    const url = getStaticPath('STATISTICS_URL','widget');
    const params = { project_id: id };
    const { period } = e.target.dataset;
    const data = {
      name: name,
      timeframe: e.target.dataset.period,
      visible: 'true'
    };
    setCurrentTimeRange(PERIOD_LABELS[period]);

    try {
      const res = await axios.post(url, data, { params });
      replaceWidget(res.data);
    }
    catch (error) {
      toast.error('Something went wrong');
    }
  };

  return (
    <div className='box-options'>
      <div ref={ref} onClick={() => setSettingOpen(!settingsOpen)} className='settings-icon'>
        {!withoutPeriod && <div className={clsx('box-time-range', settingsOpen && 'active')}>
          <p className='box-time-range-text'>{PERIOD_LABELS[widget?.timeframe]}</p>
          <Dash className='menu-dash'/>
        </div>}
        {settingsOpen && (
          <AnalyticsSettings name={name} withoutPeriod={withoutPeriod} handlePeriod={handlePeriod}/>
        )}
      </div>
    </div>
  );
};
export default ChangePeriodSelect;

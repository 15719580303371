import { ReactComponent as Dash } from 'assets/images/svg/dash.svg';
import { clsx } from 'clsx';

const PaginationContainer = ({ usersLength, usersCount, page, setPage, perPage = 50 }) => {
  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePreviousPage = () => {
    setPage(page - 1);
  };

  return (
    <div className='pagination-container'>
      <div className={clsx('pagination-page-container', page !== 1 && 'pagination-active')}>
        <button className='dash-button-container' onClick={handlePreviousPage} disabled={page === 1}>
          <Dash />
        </button>
        <span className='pagination-text'>
              Previous page
        </span>
      </div>
      <div className='pagination-divider'>
      </div>
      <div className={clsx('pagination-page-container', (usersLength === perPage && usersCount !== perPage) && 'pagination-active')}>
        <span className='pagination-text'>
              Next page
        </span>
        <button className='dash-button-container' onClick={handleNextPage} disabled={usersLength !== perPage || usersCount === perPage}>
          <Dash className='dash-right'/>
        </button>
      </div>
    </div>
  );
};

export default PaginationContainer;

import handleApiError from '../ErrorHandleUtil';
import axios from 'axios';
import { getStaticPath } from '../api/axios';

export const getTemporaryJwt = async (projectId) => {
  try {
    const response = await axios.get(
      getStaticPath('APIAUTH_URL', `project/${projectId}/developer`),
      {
        params: {}
      }
    );
    return response.data.token;
  } catch (error) {
    handleApiError('Getting temporary user JWT', error);
  }
};

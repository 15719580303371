import { changeProjectNameSchema } from 'components/AuthForms/validationSchema';
import axios from 'axios';
import { getStaticPath } from 'services/api/axios';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import DeleteProjectModal from 'components/modals/delete-modals/DeleteProjectModal';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as BoltGreen } from 'assets/images/svg/boltGreenIcon.svg';
import { ReactComponent as Trash } from 'assets/images/svg/trash.svg';
import useNotification from 'components/notification/UseNotification';
import Select from '../../components/select/Select';
import { valueExists } from '../../utils/common';
import { SUPPORTED_CURRENCIES } from '../../utils/project-currencies';

const ProjectSettings = () => {
  const {
    project,
    getProject,
  } = useOutletContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showNotification = useNotification();
  const [newName, setNewName] = useState('');
  const [nameErrors, setNameErrors] = useState();
  const [currency, setCurrency] = useState();

  useEffect(() => {
    if (!project) getProject();
  }, []);

  useEffect(() => {
    if (project?.currency) {
      setCurrency(
        SUPPORTED_CURRENCIES.find(currencyInfo => currencyInfo.ticker === project.currency)
      );
    }
  }, [project]);

  const handleSubmit = async () => {
    let maybeUpdatedName = newName.trimStart();
    if (!valueExists(maybeUpdatedName)) {
      maybeUpdatedName = project.name;
    }
    try {
      await changeProjectNameSchema.validate({ newName: maybeUpdatedName });
    } catch (err) {
      setNameErrors(err.errors);
      return;
    }
    try {
      await axios.patch(
        getStaticPath('PROJECT_URL', `${project.id}`),
        {
          name: maybeUpdatedName,
          currency: currency.ticker
        }
      );
      getProject(project.id);
      showNotification('projectUpdate', 'success');
    }
    catch (error) {
      showNotification('projectUpdate', 'error');
    }
  };

  return (
    <div className='project-settings-container'>
      {isModalOpen &&
      <DeleteProjectModal projectName={project.name} handleClose={() => setIsModalOpen(false)}/>}
      <div className='single-setting-container'>
        <p className='project-settings-title'>
          Project name
        </p>
        <p className='project-name'>
          {project.name}
        </p>
        <div className='main-form'>
          <input
            type='name'
            name='newName'
            onChange={e => setNewName(e.target.value)}
            value={newName}
            placeholder='New project name'
            className='main-form-input'
          />
        </div>
        {nameErrors && <p className='input-alert'>{nameErrors}</p>}
      </div>
      {currency && <div className='single-setting-container'>
        <p className='project-settings-title'>
          Project currency
        </p>
        <Select
          options={SUPPORTED_CURRENCIES.map(currencyInfo => {
            return {
              label: currencyInfo.label
            };
          })}
          value={currency.label}
          onOptionClick={(clickedOption) => setCurrency(
            SUPPORTED_CURRENCIES.find(option => option.label === clickedOption.label)
          )}
        />
      </div>}
      <div className='single-setting-container'>
        <MainButton
          type='submit'
          variant='button-purple'
          label='SAVE CHANGES'
          icon={<BoltGreen/>}
          onClick={handleSubmit}
        />
      </div>
      <div className='divider'></div>
      <div className='delete-project-container'>
        <p className='project-settings-title'>
          Delete project
        </p>
        <p className='project-delete-info'>
          Please be advised that deleting this project will result in permanent loss of data.
        </p>
        <MainButton
          variant='button-error'
          onClick={() => setIsModalOpen(true)}
          label='DELETE PROJECT'
          icon={<Trash/>}
        />
      </div>
    </div>
  );
};

export default ProjectSettings;

export const lineActiveDot = {
  stroke: 'white',
  strokeWidth: 5,
  r: 10,
  fill: '#7346F3',
  className: 'line-chart-active-dot',
  strokeLinecap: 'round'
};

export const chartDefaultMargin = {
  top: 5,
  right: 30,
  left: 20,
  bottom: 5,
};

export const mapValue = ({
  min,
  max,
  outputMin,
  outputMax,
  input
}) => {

  return (
    Math.round(outputMin + ((outputMax - outputMin) / (max - min)) * (input - min))
  );
};

export const getMaxValueFromData = (data, key) => Math.max(...data.map(el => el[key]));
export const getPercentageValue = (value, maxValue) => (value / maxValue * 100).toFixed(2) + '%';
export const getChartParsedData = (data, key, startIndex = 0, endIndex = 10) => (
  data.sort((a, b) => b[key] - a[key] ).slice(startIndex, endIndex)
    .map((el, index) => {
      const r = mapValue({
        min: 0,
        max: data.length,
        outputMin: 105,
        outputMax: 111,
        input: index
      });
      const g = mapValue({
        min: 0,
        max: data.length,
        outputMin: 70,
        outputMax: 255,
        input: index
      });
      const b = mapValue({
        min: 0,
        max: data.length,
        outputMin: 243,
        outputMax: 150,
        input: index
      });
      return {
        ...el,
        color: `rgb(${r}, ${g}, ${b})`
      };
    })
);
export const getBillingChartParsedData = (data, limit) => (
  data.map((el, index) => {
    const r = mapValue({
      min: 0,
      max: data.length,
      outputMin: 105,
      outputMax: 111,
      input: index
    });
    const g = mapValue({
      min: 0,
      max: data.length,
      outputMin: 70,
      outputMax: 255,
      input: index
    });
    const b = mapValue({
      min: 0,
      max: data.length,
      outputMin: 243,
      outputMax: 150,
      input: index
    });
    return {
      ...el,
      fill: `rgb(${r}, ${g}, ${b})`,
      limit: limit
    };
  })
);

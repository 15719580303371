const ManagementBox = ({ iconSrc, items }) => {
  return (
    <div className='management-box-content'>
      <div className='icon-container'>
        <img
          src={iconSrc}
          alt='plan-icon'
        />
      </div>
      <div className='management-box-table'>
        {items.map((item, index) => <div className='management-box-element' key={index}>
          <p className='management-box-element-label'>{item.label}</p>
          <p className='management-box-element-value'>{item.value}</p>
        </div>)}
      </div>
    </div>
  );
};

export default ManagementBox;

import { ReactComponent as WidgetIcon } from 'assets/images/svg/widgets/widget.svg';

const PlanLimitsBanerWidgets = ({ icon, widgetName }) => {
  return (
    <div className='baner-widget'>
      <div className='name-icon-container'>
        <div className='icon'>
          {icon}
        </div>
        <span className='widget-name'>
          {widgetName}
        </span>
      </div>
      <div>
        <WidgetIcon className='widget-icon'/>
      </div>
    </div>
  );
};

export default PlanLimitsBanerWidgets;

import styles from './style.module.scss';

const RiskScoreOverviewTableRow = ({ title, value }) => {
  return (
    <div className={styles.tableRow}>
      <span className={styles.description}>{title}</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
};

export default RiskScoreOverviewTableRow;

import styles from './single-projects-layout.module.scss';
import Sidebar from 'components/sidebar/Sidebar';
import { Outlet, useLocation, useParams, generatePath } from 'react-router-dom';
import SingleProjectSidebar from 'components/sidebar/SingleProjectSidebar.jsx';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { endpoints, getStaticPath } from 'services/api/axios.js';
import Header from 'components/header/Header.jsx';
import { createHeaderFromPathname } from 'utils/create-header-from-pathname.js';
import Loader from 'components/loaders/loader/Loader.jsx';
import routes from '../../routes/routes';
import handleApiError from '../../services/ErrorHandleUtil';
import { UserContext } from '../../context/user/UserContext';
import { hiddenProjects } from '../../utils/hidden-projects';
import MainButton from '../../components/buttons/MainButton';
import { ReactComponent as FileIcon } from '../../assets/images/svg/file.svg';
import html2canvas from 'html2canvas';
import { toast } from 'react-hot-toast';

const SingleProjectLayout = () => {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState({});
  const [customerName, setCustomerName] = useState(null);
  const { pathname } = useLocation();
  const pathParams = useParams();
  const { getConfig } = useContext(UserContext);
  const [otherProjectExists, setOtherProjectExists] = useState(true);
  const canGoHome = getConfig('my_projects') && (getConfig('dashboard') || otherProjectExists);
  const [reportDownloading, setReportDownloading] = useState(false);

  const downloadClientReport = () => {
    setReportDownloading(true);
    html2canvas(document.getElementById('customer-details-div'), {
      allowTaint: false,
      useCORS: false,
    }).then(canvas => {
      const imageUrl = canvas.toDataURL('image/png', 1);
      let link = document.createElement('a');
      link.download = `client-report-${customerName}.png`;
      link.href = imageUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(e =>
      toast.error(`Error while taking a screenshot: ${e?.message}`)
    ).finally(() => setReportDownloading(false));
  };

  const getProject = (projectID) => {
    setLoading(true);
    axios.get(getStaticPath('PROJECT_URL', `${projectID}`)).then(
      (data) => {
        setProject(data.data);
      }
    ).catch((reason) => {
      handleApiError('getting project', reason);
    }).finally(() => {
      setLoading(false);
    });
  };

  const getName = (projectId, userId) => {
    axios.get(`${endpoints.USERLIST_URL}/${projectId}/user/${userId}`).then((data) => {
      setCustomerName(data?.data?.name);
    }).catch((reason) => {
      handleApiError('getting user', reason);
    });
  };

  useEffect(() => {
    getProject(pathParams.id);

    if (pathParams.user_id !== undefined) {
      getName(pathParams.id, pathParams.user_id);
    } else {
      setCustomerName(null);
    }
  }, [pathParams]);

  useEffect(() => {
    axios.get(getStaticPath('PROJECT_URL')).then(data => {
      const allProjects = data?.data ?? [];
      const filtered = allProjects.filter(project => !hiddenProjects.includes(project.name));
      if (getConfig('show_demo_project')) {
        setOtherProjectExists(filtered.length > 1);
      } else {
        const demoFiltered = filtered.filter(project => !project.demo);
        setOtherProjectExists(demoFiltered.length > 1);
      }
    });
  }, []);

  return (
    <div className='page-container'>
      <Sidebar canGoHome={canGoHome}>
        <SingleProjectSidebar
          isDemoProject={project.demo}
          hideProject={project.name === 'AML Empty'}
          canGoHome={canGoHome}
        />
      </Sidebar>
      {loading ? <Loader/> : (
        <div className='page-content-container'>
          {pathname === generatePath(routes.home.customerDetails, {
            id: pathParams?.id ?? '',
            user_id: pathParams?.user_id ?? ''
          })
            ? <div className={styles.flexHeader}>
              <Header
                headerTitle={createHeaderFromPathname(pathname, project.name, customerName)}
                withBackButton
                backRouteIfMissingHistory={generatePath(routes.home.clients, { id: pathParams.id })}
              />
              <MainButton
                variant='button-purple-light'
                label='EXPORT CLIENT REPORT'
                onClick={downloadClientReport}
                icon={<FileIcon/>}
                extraClasses={styles.exportButton}
                disabled={reportDownloading}
              />
            </div>
            : <Header
              headerTitle={createHeaderFromPathname(pathname, project.name, customerName)}
              withBackButton={canGoHome}
              backRoute={routes.home.dashboard}
            />
          }
          <div className='page-content'>
            <Outlet context={{
              project,
              getProject,
              loading
            }}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleProjectLayout;

import { FaCheck } from 'react-icons/fa';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as SettingsDots } from 'assets/images/svg/settings-dots.svg';
import { useState } from 'react';
import { ReactComponent as LockOpened } from 'assets/images/svg/lock-opened.svg';
import { ReactComponent as Bookmark } from 'assets/images/svg/bookmark.svg';
import { formatDate } from 'utils/date-formatter';
import { useParams } from 'react-router-dom';
import ClickOutside from '../../components/click-outside/ClickOutside';
import { clsx } from 'clsx';

const ApiKeysTableRow = ({ token, copiedApiToken, handleCopyKey, handleDeleteKey }) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const { id } = useParams();

  return (
    <div className='api-keys-table-row public-keys-table-row'>
      <span className='key-name'>
        <div className='key-icon'>
          <LockOpened />
        </div>
        <div className='input-wrapper'>
          {token.name}
        </div>
      </span>
      <div className='value-container'>
        <p className='value'>
          {token.token}
        </p>
      </div>
      <span className='timer-container'>
        <CopyToClipboard
          text={token.token}
          onCopy={() => handleCopyKey(token?.id)}
        >
          <div className='copy-container'>
            <div className='copy-box copy-visible'>
              Copy to clipboard
            </div>
            <div className='copy-icon-container'>
              {copiedApiToken === token.id
                ? <FaCheck className='api-keys-table-icon-copied'/>
                : <div className='copy-icon'><Bookmark/></div>
              }
            </div>
          </div>
        </CopyToClipboard>
      </span>
      <span className='last-used-at'>
        {token.lastused_at
          ? formatDate(token.lastused_at)
          : (
            <span>No information</span>
          )}
      </span>
      <span className='created-at'>
        {token.created_at
          ? formatDate(token.created_at)
          : (
            <span>No information</span>
          )}
      </span>
      <span className='expiration'>No Expiration</span>
      <span className='delete' onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
        <div className='dots-icon'>
          <SettingsDots/>
        </div>
        <ClickOutside onClickOutside={() => setIsDeleteOpen(false)}>
          <div className={clsx('delete-popup', isDeleteOpen && 'delete-open')}>
            <span onClick={() => handleDeleteKey(id, token?.id)}>
            Delete key
            </span>
          </div>
        </ClickOutside>
      </span>
    </div>
  );
};

export default ApiKeysTableRow;

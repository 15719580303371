import Loader from 'components/loaders/loader/Loader';
import AnalyticsUpload from 'components/analytics-views/AnalyticsUpload';
import { NoData } from 'components/no-data-container/NoData';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as UsersIcon } from 'assets/images/svg/users.svg';
import UsersTableRow from './UsersTableRow';
import { UserContext } from '../../context/user/UserContext';
import { useContext } from 'react';

const UsersTableContent = ({ isLoading, parsed, isContractImported, buttonNoDataOnClick, searchParams }) => {
  const { providersInfo } = useContext(UserContext);

  if (isLoading) {
    return (
      <Loader/>
    );
  }

  if (parsed.length === 0 && searchParams !== '' && isContractImported) {
    return (
      <NoData/>
    );
  }


  if (parsed.length === 0 && isContractImported ) {
    return (
      <AnalyticsUpload importTitle={'Contract address was already imported'}/>
    );
  }

  if (parsed.length === 0) {
    return (
      <NoData>
        <MainButton
          variant='button-no-data'
          label='IMPORT CLIENTS'
          icon={<UsersIcon/>}
          onClick={buttonNoDataOnClick}
        />
      </NoData>
    );
  }

  return (
    parsed.map((user, index) =>
      (
        <UsersTableRow
          user={user}
          key={`${user.name}-${index}`}
          providers={providersInfo}
        />
      )
    )
  );
};

export default UsersTableContent;



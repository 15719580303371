import { useContext } from 'react';
import { NotificationContext } from 'context/notification/NotificationContext';

const useNotification = () => {
  const { setNotificationData, setNotificationType } = useContext(NotificationContext);

  return (data, type) => {
    setNotificationType(type);
    setNotificationData(data);
  };
};

export default useNotification;

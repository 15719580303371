import { ReactComponent as MobileNotificationIcon } from 'assets/images/svg/mobile/mobile-notification.svg';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as BoltGreen } from 'assets/images/svg/boltGreenIcon.svg';
import { useNavigate } from 'react-router-dom';
import appRoutes from 'routes/routes';

const SmallDeviceNotification = () => {
  const navigate = useNavigate();
  const { home } = appRoutes;

  return (
    <div className='small-device-notification-container'>
      <div className='notification-icon'>
        <MobileNotificationIcon/>
      </div>
      <div className='notification-text-container'>
        <p className='screen-too-small'>Your screen is too small</p>
        <p className='screen-too-small-description'>Please access Portal from a computer or switch to a device with a larger screen resolution.</p>
      </div>
      <MainButton
        variant='button-purple'
        label='BACK TO HOME PAGE'
        icon={<BoltGreen/>}
        onClick={() => navigate(home.dashboard)}
      />
    </div>
  );
};

export default SmallDeviceNotification;

import ChartWrapper from 'components/charts/chart-wrapper/ChartWrapper';
import CustomLineChart from 'components/charts/line-chart/LineChart';
import { HorizontalBarChartComponent } from 'components/charts/custom-bar-chart-component/HorizontalBarChartComponent';
import { LocationsWidgetVerticalChart } from 'components/charts/custom-bar-chart-component/LocationsWidgetVerticalChart';
import { useContext, useMemo } from 'react';
import RiskScore from 'components/risk-score/RiskScore.jsx';
import { WidgetContext } from 'context/widget/WidgetContext';
import { WIDGETS_ENUM } from 'utils/widgets-helper.js';
import RiskScoreChart from 'components/risk-score/risk-score-chart/RiskScoreChart';
import { VerticalChart }from 'components/charts/vertical-chart/VerticalChart';
import { ReactComponent as UserIcon } from 'assets/images/svg/users.svg';
import RiskScoreGaussWidget from './single-widgets/RiskScoreGaussWidget';
import { useOutletContext } from 'react-router-dom';

const AnalyticsChartsSection = () => {
  const { parsedWidgets } = useContext(WidgetContext);
  const { project } = useOutletContext();

  const topProviderWidget = parsedWidgets.find(widget => widget.name === WIDGETS_ENUM.TOP_PROVIDERS);
  const topLocationWidget = parsedWidgets.find(widget => widget.name === WIDGETS_ENUM.TOP_LOCATIONS);
  const userActivityWidget = parsedWidgets.find(widget => widget.name === WIDGETS_ENUM.USER_ACTIVITY);
  const riskScoreWidget = parsedWidgets.find(widget => widget.name === WIDGETS_ENUM.RISK_SCORE);
  const topTokensWidget = parsedWidgets.find(widget => widget.name === WIDGETS_ENUM.TOP_TOKENS);
  const topUsersWidget = parsedWidgets.find(widget => widget.name === WIDGETS_ENUM.TOP_USERS);
  const riskScoreGaussWidget = parsedWidgets.find(widget => widget.name === WIDGETS_ENUM.RISK_SCORE_GAUSS);

  const currency = useMemo(() => {
    if (project) {
      return project.currency;
    }
  }, [project]);

  return (
    <div className='analytics-charts-section'>
      {topProviderWidget.visible && (
        <ChartWrapper
          title={'The most used chains in your project'}
          period={topProviderWidget.timeframe}
          name={WIDGETS_ENUM.TOP_PROVIDERS}
          tooltipContent='Top 10 providers of your clients'
          withoutPeriod
        >
          <HorizontalBarChartComponent widget={topProviderWidget}/>
        </ChartWrapper>
      )}
      {topLocationWidget.visible && (
        <ChartWrapper
          title={'Top 10 client locations'}
          period={topLocationWidget.timeframe}
          name={WIDGETS_ENUM.TOP_LOCATIONS}
          tooltipContent='Clients location based on the IP address'
        >
          <LocationsWidgetVerticalChart widget={topLocationWidget}/>
        </ChartWrapper>
      )}
      {userActivityWidget.visible && (
        <ChartWrapper
          title='Client activity'
          period={userActivityWidget.timeframe}
          name={WIDGETS_ENUM.USER_ACTIVITY}
          tooltipContent='Number of clients transactions'
        >
          <CustomLineChart
            widget={userActivityWidget}
            tooltip
            rangeKey='date'
            countKey='count'
          />
        </ChartWrapper>
      )}
      {riskScoreGaussWidget.visible && (
        <RiskScoreGaussWidget/>
      )}
      {topUsersWidget.visible && (
        <ChartWrapper
          title='The wealthiest clients'
          name={WIDGETS_ENUM.TOP_USERS}
          period={topUsersWidget.timeframe}
          tooltipContent='Top clients'
          withoutPeriod
        >
          <VerticalChart
            widget={topUsersWidget}
            labelKey={'name'}
            countKey={'balance'}
            withoutIcon
            withCopyTooltip
            unit={currency}
          >
            <div className='country-icon'>
              <UserIcon/>
            </div>
          </VerticalChart>
        </ChartWrapper>
      )}
      {riskScoreWidget.visible && (
        <ChartWrapper
          title='Average risk score of your clients'
          period={riskScoreWidget.timeframe}
          name={WIDGETS_ENUM.RISK_SCORE}
          tooltipContent='Average risk score of your client base'
          withoutPeriod
        >
          <div className='risk-score-chart'>
            <div className='score-wrapper'>
              <div className='score'>
                <span className='score-big'>{riskScoreWidget.data.toFixed(1)}</span>/100
              </div>
              <RiskScore riskScore={riskScoreWidget.data} longLabel/>
            </div>
            <div className='risk-chart-wrapper'>
              <RiskScoreChart riskScore={riskScoreWidget.data}/>
            </div>
          </div>
        </ChartWrapper>
      )}
      {topTokensWidget.visible && (
        <div className='top-tokens-chart'>
          <ChartWrapper
            title='The most active tokens of your clients'
            name={WIDGETS_ENUM.TOP_TOKENS}
            period={topTokensWidget.timeframe}
            tooltipContent='Top tokens'
            withoutPeriod
          >
            <VerticalChart
              widget={topTokensWidget}
              labelKey={'currency'}
              countKey={'count'}
              withCopyTooltip
            />
          </ChartWrapper>
        </div>
      )}
    </div>
  );
};

export default AnalyticsChartsSection;

import MainModal from 'components/modals/MainModal';
import { ReactComponent as UsersIcon } from 'assets/images/svg/users.svg';
import { ReactComponent as EditIcon } from 'assets/images/svg/edit.svg';
import DeleteUserModalContent from './modal-content/DeleteUserModalContent';
import EditOrInviteUserModalContent from './modal-content/EditOrInviteUserModalContent';
import { axiosErrorHandler, getStaticPath } from '../../services/api/axios';
import { toast } from 'react-hot-toast';
import axios from 'axios';


export const MODAL_TYPES = {
  inviteUser: 'INVITE_USER',
  editUser: 'EDIT_USER',
  deleteUser: 'DELETE_USER',
};

const UsersModalForm = ({ handleModalClose, modalType, userName = '', userEmail = '', userRole = '', userId }) => {
  const handleInviteSubmit = async (userData) => {
    try {
      await axios.post(getStaticPath('ADMIN_URL'), userData);
      toast.success('User has been invited');
      handleModalClose();
    } catch (error) {
      toast.error(axiosErrorHandler(error));
    }
  };

  const handleEditSubmit = async (userData) => {
    try {
      await axios.put(getStaticPath('ADMIN_URL', userId), userData);
      toast.success('User has been updated');
      handleModalClose();
    } catch (error) {
      toast.error(axiosErrorHandler(error));
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      await axios.delete(getStaticPath('ADMIN_URL', userId));
    } catch (error) {
      toast.error(axiosErrorHandler(error));
    }
  };

  return (
    <MainModal handleClose={handleModalClose}>
      {modalType === MODAL_TYPES.deleteUser &&
        <DeleteUserModalContent deletedUserName={userName} handleSubmit={handleDeleteSubmit} />
      }
      {modalType === MODAL_TYPES.editUser &&
        <EditOrInviteUserModalContent
          headerIcon={<EditIcon />}
          title='Edit user'
          isEdit
          name={userName}
          email={userEmail}
          role={userRole}
          handleSubmit={handleEditSubmit}
        />
      }
      {modalType === MODAL_TYPES.inviteUser &&
        <EditOrInviteUserModalContent
          headerIcon={<UsersIcon />}
          title='Invite user'
          isEdit={false}
          handleSubmit={handleInviteSubmit}
        />
      }
    </MainModal>
  );
};

export default UsersModalForm;

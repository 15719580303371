import { useOutletContext } from 'react-router-dom';
import BannerContentful from 'pages/dashboard/dashboard-banner/BannerContentful';

const DashboardBanner = ({ isLimitReached }) => {
  const { bannerData } = useOutletContext();

  return (
    <BannerContentful bannerData={bannerData} withPlanLimits={isLimitReached}/>
  );
};

export default DashboardBanner;

import createConfig from 'react-runtime-config';

// This is a fallback. See how Config.jsx works in this directory. If a value is not available from BE, it is searched here.
export const { getConfig, useConfig, useAdminConfig } = createConfig({
  namespace: 'MY_APP_CONFIG',
  localOverride: false,
  storage: null,
  schema: {
    sentry_dsn: {
      type: 'string',
      description: 'Sentry DSN',
      default: ''
    },
    sentry_env: {
      type: 'string',
      description: 'Sentry env',
      default: 'development'
    },
    show_demo_project: {
      type: 'boolean',
      description: 'Show demo project',
      default: true
    },
    users_management: {
      type: 'boolean',
      description: 'Show users management',
      default: false
    },
    is_aml_demo: {
      type: 'boolean',
      description: 'Is AML demo',
      default: false
    },
    is_cs: {
      type: 'boolean',
      description: 'Is CS',
      default: false
    },
    api_url: {
      type: 'string',
      description: 'Blockmate API URL',
      default: ''
    },
    show_alternative_login: {
      type: 'boolean',
      description: 'Show GitHub and Google login options',
      default: true
    },
    explorer: {
      type: 'boolean',
      description: 'Address explorer',
      default: false
    },
    aml_quick_check: {
      type: 'boolean',
      description: 'AML Quick Check',
      default: false,
    },
    deposits: {
      type: 'boolean',
      description: 'Deposits',
      default: false,
    },
    deposit_payouts: {
      type: 'boolean',
      description: 'Deposit payouts',
      default: false,
    }
  }
});

import AnalyticsTopSection from 'pages/single-project-analytics/AnalyticsTopSection';
import AnalyticsChartsSection from 'pages/single-project-analytics/AnalyticsChartsSection';
import { useContext, useEffect } from 'react';
import { WidgetContext } from 'context/widget/WidgetContext';
import Loader from 'components/loaders/loader/Loader';
import { useOutletContext, useParams } from 'react-router-dom';
import AnalyticsNoWidgets from 'components/analytics-views/AnalyticsNoWidgets';
import AnalyticsBanner from 'pages/single-project-analytics/AnalyticsBanner';
import AnalyticsWelcome from 'components/analytics-views/AnalyticsWelcome';
import { UserContext } from '../../context/user/UserContext';

const Analytics = () => {
  const { loading, getWidgets, widgets } = useContext(WidgetContext);
  const { id } = useParams();
  const { project } = useOutletContext();
  const { getConfig } = useContext(UserContext);

  useEffect(() => {
    getWidgets(id, project.currency);
  }, [id, project]);

  if (loading) {
    return <Loader/>;
  }

  if (widgets.length === 0) {
    return <AnalyticsNoWidgets/>;
  }

  if (widgets.every((widget) => (widget.data === 0 || widget.data?.length === 0 || widget.data === null))) {
    return <AnalyticsWelcome/>;
  }

  return (
    <div className='analytics-wrapper'>
      <AnalyticsTopSection/>
      {!getConfig('is_aml_demo') && <AnalyticsBanner/>}
      <AnalyticsChartsSection/>
    </div>
  );
};

export default Analytics;

import styles from './module-card.module.scss';

const ModuleCard = ({ text, icon, handleClick }) => {
  return <div className={styles.container} onClick={handleClick}>
    <div className={styles.icon}>
      {icon}
    </div>
    <div className={styles.text}>
      {text}
    </div>
  </div>;
};

export default ModuleCard;

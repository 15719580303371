import { useContext, useEffect } from 'react';
import { WidgetContext } from 'context/widget/WidgetContext';

const AnalyticsBanner = () => {
  const {
    analyticsBannerData,
    getAnalyticsBanner
  } = useContext(WidgetContext);

  useEffect(() => {
    getAnalyticsBanner();
  }, []);

  return (
    <>
      {!!analyticsBannerData && (
        <div
          className='analytics-banner-container'
          style={{
            background: `url(${analyticsBannerData.bannerImage?.url}`, backgroundSize: 'cover'
          }}>
          <p className='analytics-banner-label'>{analyticsBannerData.horizontalBannerHeadlineLabel}</p>
          <p className='analytics-banner-main-text'>
            {analyticsBannerData.horizontalBannerHeadline}
          </p>
          <div className='banner-tag star-tag'>
            <img src={analyticsBannerData.iconStar?.url} className='banner-time-icon' alt='banner-time-icon'/>
            <p className='analytics-banner-tag-text' >{analyticsBannerData.horizontalBannerLabelText}</p>
          </div>
          <a href={analyticsBannerData.horizontalBannerLinkUrl} target='_blank' rel='noreferrer' className='analytics-banner-link'>
            {analyticsBannerData.horizontalBannerLinkText}
          </a>
        </div>
      )}
    </>
  );
};

export default AnalyticsBanner;

import React, { useId } from 'react';

const Checkbox = ({ label, checked, onChange }) => {
  const id = useId();

  return (
    <div className='checkbox'>
      <label className='checkbox-wrapper' htmlFor={id}>
        <input className='checkbox-input' type='checkbox' checked={checked} onChange={onChange} id={id}/>
        <span className='checkbox-checkmark'>{checked && '✓'}</span>
        <span className='checkbox-label'>{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;

import { useState } from 'react';
import PlanSelectorHeading from './PlanSelectorHeading';
import PlanSelectorCards from './PlanSelectorCards';

const PlanSelector = ({ plans, defaultFrequency = 'monthly', showFrequencyToggle = false }) => {

  const [frequency, setFrequency] = useState(defaultFrequency);

  return (
    <div className='plan-selector-container'>
      {showFrequencyToggle &&
        <PlanSelectorHeading plans={plans ?? []} showFrequencyToggle={showFrequencyToggle} onFrequencyChange={setFrequency} />
      }
      <PlanSelectorCards plans={plans ?? []} frequency={frequency} />
    </div>
  );
};

export default PlanSelector;

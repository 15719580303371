export const sourceUrls = {
  boltGreenSrc:
    'https://global-uploads.webflow.com/622b5de5a65e4f3a118dd0f1/622b63bc30605b3b9327b8e9_blockmate-icon-2.svg',
  boltBlackSrc:
    'https://global-uploads.webflow.com/622b5de5a65e4f3a118dd0f1/622c8dcdecd5490a96a8f3b0_blockmate-bolt.svg',
  boltPurpleSrc:
    'https://global-uploads.webflow.com/622b5de5a65e4f3a118dd0f1/622b7f249d250f1f476d6a3c_blockmate-icon-3.svg',
  hexagonSrc:
    'https://global-uploads.webflow.com/622b5de5a65e4f3a118dd0f1/622c8dcce8f1fad4834c1c3b_blockmate-polygon.svg',
  blockmateBcSrc:
    'https://global-uploads.webflow.com/622b5de5a65e4f3a118dd0f1/622c8dcdf52da25c0918df80_blockmate-brand-name.svg',
  futureReadySrc:
    'https://global-uploads.webflow.com/622b5de5a65e4f3a118dd0f1/622b63b657e9d057c669cc6c_blockmate-logo-claim-1.svg',
  dottedTriangle:
    'https://global-uploads.webflow.com/622b5de5a65e4f3a118dd0f1/62445089f6ce9baed0117da7_polygon-triangle-1-p-500.png',
  whiteLogoIcon: 'https://docs.blockmate.io/img/blockmate-logo.svg',
};

import appRoutes from '../routes/routes';

export const ROLES = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  VIEWER_AML: 'VIEWER_AML',
  VIEWER_EXPLORER: 'VIEWER_EXPLORER',
  VIEWER_QUICK_CHECK: 'VIEWER_QUICK_CHECK',
  VIEWER_FULL: 'VIEWER_FULL',
};

export const DEFAULT_ROLE = ROLES.ADMIN;

export const featureFlagsByRole = {
  [ROLES.OWNER]: {
    dashboard: true,
    users_management: true,
    explorer: true,
    aml_quick_check: true,
    analytics: true,
    project_users: true,
    project_settings: true,
    company_settings: true,
    my_projects: true,
    add_new_project: true,
    default_project_tab: appRoutes.home.analytics,
  },
  [ROLES.ADMIN]: {
    dashboard: true,
    users_management: true,
    explorer: true,
    aml_quick_check: true,
    analytics: true,
    project_users: true,
    project_settings: true,
    company_settings: true,
    my_projects: true,
    add_new_project: true,
    default_project_tab: appRoutes.home.analytics,
  },
  [ROLES.VIEWER_FULL]: {
    dashboard: true,
    users_management: false,
    explorer: true,
    aml_quick_check: true,
    analytics: true,
    project_users: true,
    project_settings: false,
    company_settings: false,
    my_projects: true,
    add_new_project: false,
    default_project_tab: appRoutes.home.analytics,
  },
  [ROLES.VIEWER_AML]: {
    dashboard: false,
    users_management: false,
    explorer: false,
    aml_quick_check: false,
    analytics: true,
    project_users: true,
    project_settings: false,
    company_settings: false,
    my_projects: true,
    add_new_project: false,
    default_project_tab: appRoutes.home.analytics,
  },
  [ROLES.VIEWER_EXPLORER]: {
    dashboard: false,
    users_management: false,
    explorer: true,
    aml_quick_check: false,
    analytics: false,
    project_users: false,
    project_settings: false,
    company_settings: false,
    my_projects: false,
    add_new_project: false,
    default_project_tab: appRoutes.home.explorer,
  },
  [ROLES.VIEWER_QUICK_CHECK]: {
    dashboard: false,
    users_management: false,
    explorer: false,
    aml_quick_check: true,
    analytics: false,
    project_users: false,
    project_settings: false,
    company_settings: false,
    my_projects: false,
    add_new_project: false,
    default_project_tab: appRoutes.home.explorer,
  },
};

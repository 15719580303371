import React from 'react';
import UserSocialsIcon from './UserSocialsIcon';

const UserSocials = ({ socials }) => {

  if (!socials) return null;

  const parsedSocials = Object.assign({}, ...Object.values(socials));

  const socialsArray = Object.entries(parsedSocials).map(([social,user])=>({ user,
    social }));

  return (
    <>
      {socialsArray.map((el, index) => (
        <UserSocialsIcon el={el} key={index}/>
      ))}
    </>
  );
};

export default UserSocials;

import { useState, useEffect, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import useNotification from 'components/notification/UseNotification';
import { clsx } from 'clsx';

const CopyToClipboardTooltip = ({ children, tooltipData, wrapperClassName, copyValue = '', tooltipDataValue, openOnHover = false, allowUnderline = true }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const ref = useRef(null);
  const showNotification = useNotification();

  const handleTooltipOpen = () => {
    if (tooltipData === '' || !tooltipData) return null;
    else {
      setIsTooltipOpen(true);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsTooltipOpen(false);
    }
  };

  const handleClick = () => {
    if (!openOnHover) {
      handleTooltipOpen();
    }
  };

  const handleHover = ({ enteredNow = true }) => {
    if (openOnHover) {
      if (enteredNow) {
        handleTooltipOpen();
      } else {
        setIsTooltipOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <span
      className={clsx('copy-to-clipboard-tooltip-wrapper', wrapperClassName && wrapperClassName, isTooltipOpen && allowUnderline && 'active')}
      onClick={handleClick}
      onMouseEnter={() => handleHover({ enteredNow: true })}
      onMouseLeave={() => handleHover({ enteredNow: false })}
    >
      {children}
      {isTooltipOpen && (
        <div ref={ref} className='copy-to-clipboard-tooltip'>
          <CopyToClipboard text={copyValue} onCopy={() => showNotification('copyData', 'success')}>
            <div className='copy-to-clipboard'>
              <span className='value'>
                {tooltipDataValue}
              </span>
              <span className='tooltip-action'>
                {tooltipData}
              </span>
            </div>
          </CopyToClipboard>
        </div>
      )}
    </span>
  );
};

export default CopyToClipboardTooltip;

import { ReactComponent as InfoIcon } from 'assets/images/svg/info-icon.svg';
import MainButton from 'components/buttons/MainButton';

const SidebarAlert = ({ handleAlertClick }) => {

  return (
    <div className='sidebar-alert'>
      <InfoIcon className='alert-icon'/>
      <div className='sidebar-alert-text'>
        <p className='title'>Usage Limits</p>
        <p className='text'>You&apos;ve reached plan&apos;s usage limits</p>
      </div>
      <MainButton
        variant='button-error'
        label='UPGRADE PLAN'
        onClick={handleAlertClick}
      />
    </div>
  );
};

export default SidebarAlert;
